// client/src/components/Auth/firestoreHelpers/getUsersInTenant.js

import axiosAuth from "../../../api/axiosInstanceConfig/axiosAuth"

const getUsersInTenant = async (tenantId) => {
	try {
		const response = await axiosAuth.get(`/api/getUsersInTenant`, {
			params: { tenantId }
		})
		return response.data
	} catch (error) {
		console.error("Error fetching users in tenant:", error)
		throw error
	}
}

export default getUsersInTenant
