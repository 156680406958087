import { ColorModeScript, ChakraProvider } from "@chakra-ui/react"
import React from "react"
import ReactDOM from "react-dom/client"
// import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { BrowserRouter } from "react-router-dom"
// import Layout from "./pages/Layout"

import "./index.css"
import App from "./App"
import theme_mineralis from "./theme.js"
// import { polyglot } from "./utils/i18n"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ColorModeScript
				initialColorMode={theme_mineralis.config.initialColorMode}
			/>
			<ChakraProvider theme={theme_mineralis}>
				<ColorModeScript
					initialColorMode={theme_mineralis.config.initialColorMode}
				/>
				<App />
			</ChakraProvider>
		</BrowserRouter>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
