import React, { forwardRef } from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	IconButton,
	Tooltip,
	Flex,
	Box,
	Input,
	Text,
	Alert
} from "@chakra-ui/react"
import { MdDelete } from "react-icons/md"
import { useTranslate } from "react-polyglot"

const AddDeviceToTenantActionModal = forwardRef((props, ref) => {
	const t = useTranslate()
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Tooltip
				hasArrow={true}
				bg="tooltip-bg"
				placement="top-start"
				pl={4}
				arrowSize={12}
				openDelay={1000}
				closeDelay={700}
				textAlign={"center"}
				label={props.tooltipLabel}
				fontSize="md"
				aria-label="add device to tenant button"
			>
				<IconButton
					backgroundColor="button-secondary-hover-bg"
					// backgroundColor="evisgreen"
					color="white"
					height="22px"
					aria-label="add a new device of tenant"
					icon={props.icon}
					onClick={onOpen}
				/>
			</Tooltip>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				blockScrollOnMount={true}
				size={"sm"}
				returnFocusOnClose={false}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader
						pt={2}
						pb={0}
						mb={0}
					>
						{props.title}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						mt={2}
						mb={-1}
						fontSize={"lg"}
					>
						<Box alignItems="center">
							<Input
								id="addDeviceToTenantInput"
								ref={ref}
								placeholder={t("AddDeviceToTenantActionModal.placeholder", {
									_: "4-stellige Distributor ID eingeben"
								})}
								// value={props.newTenantId}
								onChange={props.handleTenantIdChange}
								maxLength={4}
								width="100%"
								variant="outline"
								mr="2"
							/>
							{props.newTenantName === "not found" ? (
								<Alert
									mt={2}
									status="warning"
									variant="subtle"
									flexDirection="column"
									alignItems="start"
									// justifyContent="center"
									// textAlign="center"
								>
									{props.newTenantId}
								</Alert>
							) : (
								<Text
									id="addDeviceToTenantTenantName"
									ml={2}
									mt={2}
									onClick={() => {
										props.onConfirm(
											props.newTenantId,
											props.deviceInfo.serialNumber
										)
										props.onCancel()
									}}
								>
									{props.newTenantId + "  " + props.newTenantName}
								</Text>
							)}
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="solid"
							backgroundColor="#A63C32"
							color="white"
							mr={3}
							onClick={() => {
								props.setShowInput(false)
								document.getElementById("addDeviceToTenantInput").value = ""

								document.getElementById(
									"addDeviceToTenantConfirmButton"
								).disabled = true
								onClose()
							}}
						>
							{t("AddDeviceToTenantActionModal.cancelAction", {
								_: "Zurück"
							})}
						</Button>
						<Button
							id="addDeviceToTenantConfirmButton"
							onClick={() => {
								props.onConfirm()
								props.setShowInput(false)
								document.getElementById("addDeviceToTenantInput").value = ""
								document.getElementById(
									"addDeviceToTenantTenantName"
								).style.display = "none"
								props.setNewTenantId("")
								props.setNewTenantName("")
								onClose()
							}}
							isDisabled={
								!props.newTenantName ||
								props.newTenantName === "- nicht vorhanden"
							}
						>
							{t("AddDeviceToTenantActionModal.confirmAction", {
								_: "Hinzufügen"
							})}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
})

export default AddDeviceToTenantActionModal
