// client/src/components/Auth/firestoreHelpers/getUsersInTenant.js

import axios from "axios"

const getTenantNameByTenantId = async (tenantId) => {
	try {
		const response = await axios.post(`/api/getTenantNameByTenantId`, {
			tenantId
		})
		return response.data
	} catch (error) {
		console.error("Error fetching users in tenant:", error)
		return "nicht vorhanden"
		// throw error
	}
}

export default getTenantNameByTenantId
