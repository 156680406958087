import React from "react"
import UserProfile from "../../Auth/UserProfile/UserProfile.jsx"

const UserProfileView = () => {
	return (
		<>
			<UserProfile />
		</>
	)
}

export default UserProfileView
