//! COMPONENT IS IN USE FOR INTERNAL ACCOUNT CREATION BY DEVELOPERS
import React, { useRef, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
	Link as ChakraLink,
	Button,
	Card,
	CardHeader,
	CardBody,
	Input,
	Heading,
	Text,
	FormControl,
	FormLabel,
	Alert,
	Divider,
	Flex
} from "@chakra-ui/react"
import { useAuth } from "../../../contexts/AuthContext"

const SignUp = () => {
	const emailRef = useRef()
	const passwordRef = useRef()
	const passwordConfirmRef = useRef()

	const { signup, currentUser } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	async function handleSubmit(e) {
		e.preventDefault()

		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Passwords do not match")
		}
		try {
			setError("")
			setLoading(true)
			await signup(emailRef.current.value, passwordRef.current.value)
			navigate("/", { replace: true })
		} catch {
			setError("Failed to create an account")
		}
		setLoading(false)
	}

	return (
		<Card>
			<CardHeader pb={0}>
				<Heading size={"lg"}>Sign Up TenantAdmin</Heading>
				<span fontSize="6px">* use for internal account creation</span>
				<br />
				<span fontSize="6px">* add TenantId and role assignment</span>
			</CardHeader>
			<CardBody>
				{error && (
					<Alert
						mb={4}
						status="error"
						color={"white"}
						backgroundColor={"#A63C32"}
					>
						{error}
					</Alert>
				)}
				<form onSubmit={handleSubmit}>
					<FormControl id="email">
						<FormLabel mb={0}>Email</FormLabel>
						<Input
							type="email"
							ref={emailRef}
							required
						/>
					</FormControl>
					<FormControl id="password">
						<FormLabel
							mb={0}
							mt={3}
						>
							Password
						</FormLabel>
						<Input
							type="password"
							ref={passwordRef}
							required
						/>
					</FormControl>
					<FormControl id="password-confirm">
						<FormLabel
							mb={0}
							mt={3}
						>
							Confirm Password
						</FormLabel>
						<Input
							type="password"
							ref={passwordConfirmRef}
							required
						/>
					</FormControl>
					<Button
						type="submit"
						disabled={loading}
						mt={4}
					>
						Sign Up
					</Button>
				</form>
				<Divider my={4} />
				<Flex>
					<Text>Already have an account? </Text>
					<ChakraLink
						as={ReactRouterLink}
						to="/login"
						color="blue.500"
						pl={2}
					>
						Log In
					</ChakraLink>
				</Flex>
			</CardBody>
		</Card>
	)
}

export default SignUp
