import React from "react"
import { Box, ButtonGroup, Button, Tooltip } from "@chakra-ui/react"
import { useTranslate } from "react-polyglot"
import useLocalizationStore from "../../../api/zustandStores/localizationStore/localizationStore.js"
import DecoratorTriangleButtonResizable from "../../GUI/DecoratorTriangleButtonResizable.jsx"

export default function SetLanguage(props) {
	const t = useTranslate()

	const { locale, setLocale } = useLocalizationStore()

	//# LANGUAGE SETTINGS
	const changeLanguage = (language) => {
		//# set localStorage
		localStorage.setItem("locale", language)
		//# set zustand state
		setLocale(language)
	}

	return (
		<>
			<Box mt={1}>
				<Tooltip
					bg="tooltip-bg"
					aria-label="tooltip to use shortcut to toggle language"
					hasArrow
					placement="bottom-end"
					openDelay={1200}
					closeDelay={200}
					label={
						<div py={"1rem"}>
							<span>ctrl</span> + <span>I</span>
						</div>
					}
				>
					<ButtonGroup
						fontSize={"lg"}
						size="lg"
						isAttached
					>
						<Button
							onClick={() => changeLanguage("de")}
							isActive={locale === "de"}
							value={"de"}
							height={"2rem"}
						>
							DE
						</Button>
						<Button
							onClick={() => changeLanguage("fr")}
							isActive={locale === "fr"}
							value={"fr"}
							height={"2rem"}
						>
							FR
						</Button>
						<Button
							onClick={() => changeLanguage("en")}
							isActive={locale === "en"}
							value={"en"}
							height={"2rem"}
						>
							EN
						</Button>
						<DecoratorTriangleButtonResizable
							backgroundColor="btn-primary-active-bg"
							borderColor="btn-primary-border"
							decoratorTriangleHeight={"2rem"}
							decoratorTriangleWidth={"2.2rem"}
						/>
					</ButtonGroup>
				</Tooltip>
			</Box>
		</>
	)
}
