// client/src/components/Auth/LogIn.jsx
import React, { useRef, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
	Link as ChakraLink,
	Button,
	Card,
	CardHeader,
	CardBody,
	Input,
	Heading,
	Box,
	FormControl,
	FormLabel,
	Alert,
	Divider,
	InputGroup,
	InputRightElement
} from "@chakra-ui/react"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa"
import { useAuth } from "../../../contexts/AuthContext"
import { useTranslate } from "react-polyglot"
import getCurrentUserData from "../firestoreHelpers/getCurrentUserData.js"
import useCurrentUserStore from "../../../api/zustandStores/userStore/currentUserStore.js"
import SignInAnonymouslyButton from "./SignInAnonymouslyButton.jsx"
const LogIn = () => {
	const { login } = useAuth()
	const { currentUserData, setCurrentUserData } = useCurrentUserStore()

	const emailRef = useRef()
	const passwordRef = useRef()

	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const t = useTranslate()

	const [show, setShow] = useState(false)
	const handleClick = () => setShow(!show)

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setError("")
			setLoading(true)
			await login(emailRef.current.value, passwordRef.current.value)
			const user = await getCurrentUserData(emailRef.current.value)
			setCurrentUserData(user)
			navigate("/", { replace: true })
		} catch {
			setError(t("Toasts.toastLogInFailed"), {
				_: "Anmeldung fehlgeschlagen"
			})
		} finally {
			setLoading(false)
		}
	}

	return (
		<Card width={{ base: "280px", md: "300px", lg: "330px" }}>
			<CardHeader pb={0}>
				<Heading size={"lg"}>{t("LogIn.logIn", { _: "Einloggen" })}</Heading>
			</CardHeader>
			<CardBody pb={0}>
				{error && (
					<Alert
						mb={4}
						status="error"
						color={"white"}
						backgroundColor={"#A63C32"}
					>
						{error}
					</Alert>
				)}
				<form onSubmit={handleSubmit}>
					<FormControl id="email">
						<FormLabel mb={0}>Email</FormLabel>
						<Input
							variant={"secondary"}
							borderColor={"btn-primary-hover-bg"}
							type="email"
							autoComplete="email" //TODO: ? should this show previous email?
							ref={emailRef}
							required
							backgroundColor={""}
						/>
					</FormControl>

					<FormControl id="password">
						<FormLabel
							mb={0}
							mt={3}
						>
							{t("LogIn.password", {
								_: "Passwort"
							})}
						</FormLabel>
						<InputGroup
							display={"flex"}
							height={"2rem"}
							alignContent={"center"}
							// {...props}
						>
							<Input
								variant={"secondary"}
								// type="password"
								type={show ? "text" : "password"}
								autoComplete="current-password"
								ref={passwordRef}
								required
							/>
							<InputRightElement
								width="4.5rem"
								h="2.5rem"
								pr={"0"}
								mr={"-5px"}
							>
								<Button
									onClick={handleClick}
									boxSizing="border-box"
									width="4rem"
									height={"2.5rem"}
									border={"1.5px solid"}
									borderColor={"btn-primary-hover-bg"}
									background={`btn-primary-bg`}
									_hover={{ bg: "btn-primary-hover-bg" }}
									_active={{ bg: "btn-primary-active-bg" }}
								>
									{show ? (
										<FaRegEyeSlash
											w={8}
											h={8}
											fontSize={24}
										/>
									) : (
										<FaRegEye
											w={8}
											h={8}
											fontSize={24}
										/>
									)}
								</Button>
							</InputRightElement>
						</InputGroup>
					</FormControl>

					<Button
						type="submit"
						disabled={loading}
						mt={10}
						height={"40px"}
						width={"100%"}
						border={"1.5px solid"}
						borderColor={"btn-primary-hover-bg"}
					>
						{t("LogIn.logIn", { _: "Anmelden" })}
					</Button>
				</form>
				{/* //# LINK TO RESET PASSWORD */}
				<Box mt={2}>
					<ChakraLink
						as={ReactRouterLink}
						to="/forgot-password"
						// color="blue.500"
						fontSize={"sm"}
					>
						{t("LogIn.forgotPassword", { _: "Passwort vergessen" })}
					</ChakraLink>
				</Box>

				{/* <Divider my={4} /> */}
				{/* //# LINK TO SIGN UP */}
				{/* <Flex>
					<ChakraLink
						as={ReactRouterLink}
						to="/signup"
						color="blue.500"
						// pl={2}
						fontSize={"sm"}
					>
						Sign Up *
					</ChakraLink>
				</Flex> */}
				<Divider my={4} />
				<SignInAnonymouslyButton />
			</CardBody>
		</Card>
	)
}

export default LogIn
