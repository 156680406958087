import React from "react"
import { Box } from "@chakra-ui/react"

import AccountSettingsListItem from "../AccountSettingsListItem.jsx"
import { useAuth } from "../../../contexts/AuthContext.js"
import { useState, useEffect } from "react"

// import "./AccountSettingsCard.css"

const UserProfile = () => {
	const { userClaims } = useAuth()
	const [isVisible, setIsVisible] = useState(false)

	// const isGuestUser = userClaims?.isGuest
	const isGuestUser =
		userClaims?.isGuest || userClaims?.provider_id === "anonymous"

	useEffect(() => {
		setIsVisible(true)
	}, [])

	return (
		<Box
			// marginTop={"16vh"}
			pt={"141px"} //^ FOR MINERALIS HEADER
			marginLeft={"6rem"}
			marginBottom={"5rem"}
			// transition="opacity 1s ease-in-out"
			// opacity={isVisible ? 1 : 0}
		>
			{!isGuestUser && (
				<AccountSettingsListItem
					settingsType="organisation"
					maxW={`max-content`}
				/>
			)}
			{!isGuestUser && (
				<AccountSettingsListItem
					settingsType="updatePassword"
					maxW={`max-content`}
				/>
			)}
			<AccountSettingsListItem
				settingsType="setLanguage"
				maxW={`max-content`}
			/>
			<AccountSettingsListItem
				settingsType="setTheme"
				maxW={`max-content`}
			/>
		</Box>
	)
}

export default UserProfile
