import React from "react"
import { useTranslate } from "react-polyglot"
import { Flex, Link, Text } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import ContactDrawer from "../components/Dashboard/ContactDrawer"

export default function RightsDisclaimer() {
	const t = useTranslate()
	const location = useLocation()

	return (
		<Flex
			position="absolute"
			bottom={"0"}
			left={"0"}
			as="samp"
			fontSize="sm"
			mb={0}
			ml={3}
			wrap="wrap"
		>
			<br />
			<Flex>
				<Text>&copy;{new Date().getFullYear()}</Text>
				<Link
					ml="1"
					href="https://www.evis.ch/agb/"
					isExternal
					whiteSpace="nowrap"
				>
					<Text
						as="ins"
						whiteSpace="nowrap"
					>
						EVIS AG
					</Text>
				</Link>
				<Text
					ml="1"
					mr="1"
				>
					-
				</Text>
			</Flex>
			<Flex mr={2}>
				<Text whiteSpace="nowrap">
					{t("Layout.rightsDisclaimer", {
						_: "Alle Rechte vorbehalten "
					})}
				</Text>
			</Flex>

			<Flex mr={2}>
				<Text whiteSpace="nowrap">
					<b> Version 04.11.2024</b>
				</Text>
			</Flex>
			{location.pathname === "/" || location.pathname === "/login" ? (
				<ContactDrawer />
			) : null}
		</Flex>
	)
}
