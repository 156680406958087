import React from "react"

import { Box } from "@chakra-ui/react"

const DecoratorTriangleInputSelectResizable = (props) => {
	return (
		<Box
			marginInlineStart={0}
			clipPath="polygon(100% 0%,0% 100%,100% 100%)"
			// transition={"all 1.5s ease-in-out"}
			w={props.decoratorTriangleWidth}
			h={props.decoratorTriangleHeight}
			bg={props.backgroundColor}
			borderColor={props.borderColor}
			ml={props.decoratorTriangleMl}
		></Box>
	)
}

export default DecoratorTriangleInputSelectResizable
