import { React, useEffect } from "react"
import { Flex, Image } from "@chakra-ui/react"
import logoMineralis from "../../GUI/images/gui/header/logo_mineralis.png"

const LogoMineralisLogin = (props) => {
	useEffect(() => {
		if (!localStorage.getItem("logoMineralis")) {
			localStorage.setItem("logoMineralis", logoMineralis)
		}
	}, [])

	return (
		<Flex
			{...props}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Image
				className="raisedImage"
				src={localStorage.getItem("logoMineralis") || logoMineralis}
				minWidth={{ base: "290px", md: "290px", lg: "450px" }}
				width={{ base: "250px", md: "250px", lg: "470px" }}
				height="auto"
				alt="logo"
				marginY={{ base: "0.3rem", md: "0.3rem", lg: "0.3rem" }}
				cursor={"default"}
			></Image>
		</Flex>
	)
}

export default LogoMineralisLogin
