// import React from "react"

// import "./DotSpinner.css"

// const DotSpinner = (props) => {
// 	return (
// 		<div className="dotcontainer">
// 			<div className="dot"></div>
// 			<div className="dot"></div>
// 			<div className="dot"></div>
// 			<div className="dot"></div>
// 			<div className="dot"></div>
// 			<div className="dot"></div>
// 			<div className="dot"></div>
// 			<div className="dot"></div>
// 		</div>
// 	)
// }

// export default DotSpinner

import React from "react"
import "./DotSpinner.css"

const DotSpinner = ({ color = "rgb(0, 127, 101)", size = "40px" }) => {
	return (
		<div
			className="dotcontainer"
			style={{ "--uib-color": color, "--uib-size": size }}
		>
			{[...Array(8)].map((_, index) => (
				<div
					key={index}
					className="dot"
				/>
			))}
		</div>
	)
}

export default DotSpinner
