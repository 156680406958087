import { Checkbox, extendTheme, Table, Th } from "@chakra-ui/react"
// import { cardTheme } from "./themeAddons/cardTheme"

// TODO: define fonts
const fonts = {
	body: `"DIN_bold", sans-serif`,
	heading: `"DIN_condensed_bold", sans-serif`,
	button: `"DIN_condensed_bold", sans-serif`,
	code: `"source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
      "monospace";`
}

// TODO: define tokens
const tokens = {
	colors: {
		light: {
			"bg-default": "#f8f7f7", // Off White
			"field-default-text": "#4d4e4d", // Dark Grey
			"field-secondary-default-text": "#4d4e4d", // Dark Grey
			"fg-muted": "#4d4e4d",
			"fg-disabled": "#a9aaa9",
			"fg-accent": "#1d846f",
			inputbordernotpristine: "#1d846f",
			evisgreen: "#1d846f",
			darkgrey: "#4d4e4d", // Dark Grey
			"btn-bg": "#A63C32", // Copper Red
			"badge-underline": "#A63C32", // Copper Red
			"btn-hover-bg": "#1d846f",
			"btn-active-bg": "#1d846f",
			"btn-border": "#1d846f",
			"btn-hover-border": "#1d846f",
			"btn-active-border": "#1d846f",
			"btn-primary-bg": "#EBE1D2", // Sandstone
			"btn-primary-hover-bg": "#c9c3b8", // Sandstone lighten
			"btn-primary-active-bg": "#c9c3b8", // Sandstone lighter
			"btn-localization-active-bg": "#f7edda", // Sandstone lighter
			"btn-primary-selected-bg": "#1d846f",
			"btn-primary-border": "#f8f7f7", // Off White
			"btn-primary-hover-border": "#1d846f",
			"btn-primary-active-border": "#f8f7f7", // Off White
			"btn-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-inset-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-primary-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-primary-inset-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-text": "#ffffff", // White
			"btn-text-disabled": "#ffffff",
			"btn-text-hover": "#ffffff",
			// "btn-text-active": "#5f605f", // Dark Grey lighten
			"btn-text-selected": "#ffffff",
			"btn-primary-text": "#4d4e4d", // Dark Grey
			"btn-primary-text-disabled": "#ffffff",
			"btn-primary-text-hover": "#ffffff",
			"btn-primary-text-active": "#aeaca9", // Dark Grey lighten
			"btn-primary-selected-text": "#ffffff",
			"btn-disconnected-bg": "#A63C32", // Copper Red
			"btn-disconnected-border": "#f8f7f7", // Off White
			"btn-disconnected-hover-bg": "#dbdada", // Sandstone darken
			"btn-connected-bg": "#1d846f", // Evis Green
			"btn-connected-border": "#f8f7f7", // Off White
			"field-addon-bg": "#aaa5a0", // Granite
			"field-addon-text": "#f8f7f7", // Off White
			"field-bg": "#ffffff", // Pure White
			"field-primary-bg": "#EBE1D2", // Sandstone
			"field-primary-text": "#4d4e4d", // Dark Grey
			// "field-edited-bg": "#1d846f", // Evis Green
			"field-edited-bg": "#1d846f", // Matte Green
			"field-edited-text": "#f8f7f7", // Off White
			"field-error-bg": "#A63C32", // Copper Red
			"field-placeholder-text": "#c9c3b8",
			"field-secondary-placeholder-text": "#f7edda",
			"field-numberstepper-bg": "#c9c3b8", //
			"field-numberstepper-border": "#f8f7f7", //  Off White
			"tooltip-bg": "#1d846f", // Dark Grey
			"tooltip-text": "#ffffff", // White
			"select-icon": "#2d3748", // Off White
			"divider-color": "#2d3748", // Off White
			"header-gradient-hide-100": "rgba(248,247,247, 1)",
			"header-gradient-hide-0": "rgba(248,247,247, 0.0)",
			"card-bg-hover": "white",
			"button-secondary-bg": "#dcd5c9",
			"button-secondary-text": "#ffffff",
			"button-secondary-hover-bg": "#aaa5a0",
			"button-secondary-active-bg": "#c9c3b8",
			"text-highlight-header": "#aaa5a0"
		},
		dark: {
			"bg-default": "#2d3748", // Theme Grey
			// "bg-default": "#1a202c", // Theme Grey
			"field-default-text": "#f0f0f0", // Light Grey
			"field-secondary-default-text": "#4d4e4d", // Dark Grey
			"fg-muted": "#4d4e4d",
			"fg-disabled": "#a9aaa9",
			"fg-accent": "#1d846f",
			inputbordernotpristine: "#1d846f",
			evisgreen: "#1d846f",
			darkgrey: "#4d4e4d", // Dark Grey
			"btn-bg": "#1d846f", //
			"badge-underline": "#EBE1D2", // Sandstone
			"btn-hover-bg": "#1d846f",
			"btn-active-bg": "#1d846f",
			"btn-border": "#1d846f",
			"btn-hover-border": "#1d846f",
			"btn-active-border": "#1d846f",
			"btn-primary-bg": "#515660", // Sandstone
			// "btn-primary-hover-bg": "#c9c3b8", // Sandstone lighten
			"btn-primary-hover-bg": "#aaa5a0", // Sandstone lighten
			"btn-primary-active-bg": "#aaa5a0", // Granite
			"btn-localization-active-bg": "#f7edda", // Sandstone lighter
			"btn-primary-selected-bg": "#1d846f",
			"btn-primary-border": "#2d3748",
			"btn-primary-hover-border": "#1d846f",
			"btn-primary-active-border": "#2d3748",
			"btn-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-inset-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-primary-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-primary-inset-shadow": "rgba(0, 0, 0, 0.15)",
			"btn-text": "#ffffff",
			"btn-text-disabled": "#ffffff",
			"btn-text-hover": "#ffffff",
			"btn-text-active": "#ffffff",
			"btn-text-selected": "#ffffff",
			"btn-primary-text": "#ffffff", // white
			"btn-primary-text-disabled": "#ffffff",
			"btn-primary-text-hover": "#4d4e4d",
			"btn-primary-text-active": "#4d4e4d",
			"btn-primary-selected-text": "#ffffff",
			"btn-disconnected-bg": "#A63C32", // Copper Red
			"btn-disconnected-border": "#1a202c", // Off black
			"btn-disconnected-hover-bg": "#dbdada", // Sandstone darken
			"btn-connected-bg": "#1d846f", // Evis Green
			"btn-connected-border": "#2d3748", // Theme Grey
			"field-addon-bg": "#aaa5a0", // Granite
			"field-addon-text": "#f8f7f7", // Off White
			"field-bg": "#4d4e4d", // Pure black
			"field-primary-bg": "#515660", // Sandstone
			// "field-primary-bg": "#aaa5a0", // Sandstone
			"field-edited-bg": "#1d846f", // Evis Green
			"field-edited-text": "#f8f7f7", // Off White
			"field-error-bg": "#A63C32", // Copper Red
			// "field-placeholder-text": "#c9c3b8", // Sandstone darken
			"field-placeholder-text": "#8a8780", // Sandstone darken
			"field-secondary-placeholder-text": "#f7edda",
			"field-numberstepper-bg": "#c9c3b8", // Sandstone darken
			"field-numberstepper-border": "#2d3748", //  Off White
			"tooltip-bg": "#f8f7f7", // Sandstone
			"tooltip-text": "#4d4e4d", //
			"select-icon": "#f8f7f7", // Off White
			"divider-color": "#f8f7f7", // Off White
			"header-gradient-hide-100": "rgba(45,55,72, 1)",
			"header-gradient-hide-0": "rgba(45,55,72, 0)",
			"card-bg-hover": "#505c71",
			"button-secondary-bg": "#dcd5c9",
			"button-secondary-text": "#ffffff",
			"button-secondary-hover-bg": "#c9c3b8",
			"button-secondary-active-bg": "#aaa5a0",
			"text-highlight-header": "#EBE1D2"
		}
	}
}

// TODO: define semantic tokens
const semanticTokens = {
	colors: {
		"bg-default": {
			default: tokens.colors.light["bg-default"],
			_dark: tokens.colors.dark["bg-default"]
		},
		"field-default-text": {
			default: tokens.colors.light["field-default-text"],
			_dark: tokens.colors.dark["field-default-text"]
		},
		"field-secondary-default-text": {
			default: tokens.colors.light["field-secondary-default-text"],
			_dark: tokens.colors.dark["field-secondary-default-text"]
		},
		"fg-muted": {
			default: tokens.colors.light["fg-muted"],
			_dark: tokens.colors.dark["fg-muted"]
		},
		"fg-disabled": {
			default: tokens.colors.light["fg-disabled"],
			_dark: tokens.colors.dark["fg-disabled"]
		},
		"fg-accent": {
			default: tokens.colors.light["fg-accent"],
			_dark: tokens.colors.dark["fg-accent"]
		},
		"btn-bg": {
			default: tokens.colors.light["btn-bg"],
			_dark: tokens.colors.dark["btn-bg"]
		},
		"btn-hover-bg": {
			default: tokens.colors.light["btn-hover-bg"],
			_dark: tokens.colors.dark["btn-hover-bg"]
		},
		"btn-active-bg": {
			default: tokens.colors.light["btn-active-bg"],
			_dark: tokens.colors.dark["btn-active-bg"]
		},
		"btn-border": {
			default: tokens.colors.light["btn-border"],
			_dark: tokens.colors.dark["btn-border"]
		},
		"btn-hover-border": {
			default: tokens.colors.light["btn-hover-border"],
			_dark: tokens.colors.dark["btn-hover-border"]
		},
		"btn-active-border": {
			default: tokens.colors.light["btn-active-border"],
			_dark: tokens.colors.dark["btn-active-border"]
		},
		"btn-primary-bg": {
			default: tokens.colors.light["btn-primary-bg"],
			_dark: tokens.colors.dark["btn-primary-bg"]
		},
		"btn-primary-hover-bg": {
			default: tokens.colors.light["btn-primary-hover-bg"],
			_dark: tokens.colors.dark["btn-primary-hover-bg"]
		},
		"btn-primary-active-bg": {
			default: tokens.colors.light["btn-primary-active-bg"],
			_dark: tokens.colors.dark["btn-primary-active-bg"]
		},
		"btn-localization-active-bg": {
			default: tokens.colors.light["btn-localization-active-bg"],
			_dark: tokens.colors.dark["btn-localization-active-bg"]
		},
		"btn-primary-selected-bg": {
			default: tokens.colors.light["btn-primary-selected-bg"],
			_dark: tokens.colors.dark["btn-primary-selected-bg"]
		},
		"btn-primary-border": {
			default: tokens.colors.light["btn-primary-border"],
			_dark: tokens.colors.dark["btn-primary-border"]
		},
		"btn-primary-hover-border": {
			default: tokens.colors.light["btn-primary-hover-border"],
			_dark: tokens.colors.dark["btn-primary-hover-border"]
		},
		"btn-primary-active-border": {
			default: tokens.colors.light["btn-primary-active-border"],
			_dark: tokens.colors.dark["btn-primary-active-border"]
		},
		"btn-shadow": {
			default: tokens.colors.light["btn-shadow"],
			_dark: tokens.colors.dark["btn-shadow"]
		},
		"btn-inset-shadow": {
			default: tokens.colors.light["btn-inset-shadow"],
			_dark: tokens.colors.dark["btn-inset-shadow"]
		},
		"btn-primary-shadow": {
			default: tokens.colors.light["btn-primary-shadow"],
			_dark: tokens.colors.dark["btn-primary-shadow"]
		},
		"btn-primary-inset-shadow": {
			default: tokens.colors.light["btn-primary-inset-shadow"],
			_dark: tokens.colors.dark["btn-primary-inset-shadow"]
		},
		"btn-text": {
			default: tokens.colors.light["btn-text"],
			_dark: tokens.colors.dark["btn-text"]
		},
		"btn-text-disabled": {
			default: tokens.colors.light["btn-text-disabled"],
			_dark: tokens.colors.dark["btn-text-disabled"]
		},
		"btn-text-hover": {
			default: tokens.colors.light["btn-text-hover"],
			_dark: tokens.colors.dark["btn-text-hover"]
		},
		"btn-text-active": {
			default: tokens.colors.light["btn-text-active"],
			_dark: tokens.colors.dark["btn-text-active"]
		},
		"btn-text-selected": {
			default: tokens.colors.light["btn-text-selected"],
			_dark: tokens.colors.dark["btn-text-selected"]
		},
		"btn-primary-text": {
			default: tokens.colors.light["btn-primary-text"],
			_dark: tokens.colors.dark["btn-primary-text"]
		},
		"btn-primary-text-disabled": {
			default: tokens.colors.light["btn-primary-text-disabled"],
			_dark: tokens.colors.dark["btn-primary-text-disabled"]
		},
		"btn-primary-text-hover": {
			default: tokens.colors.light["btn-primary-text-hover"],
			_dark: tokens.colors.dark["btn-primary-text-hover"]
		},
		"btn-primary-text-active": {
			default: tokens.colors.light["btn-primary-text-active"],
			_dark: tokens.colors.dark["btn-primary-text-active"]
		},
		"btn-primary-selected-text": {
			default: tokens.colors.light["btn-primary-selected-text"],
			_dark: tokens.colors.dark["btn-primary-selected-text"]
		},
		"btn-disconnected-bg": {
			default: tokens.colors.light["btn-disconnected-bg"],
			_dark: tokens.colors.dark["btn-disconnected-bg"]
		},
		"btn-connected-bg": {
			default: tokens.colors.light["btn-connected-bg"],
			_dark: tokens.colors.dark["btn-connected-bg"]
		},
		"btn-connected-border": {
			default: tokens.colors.light["btn-connected-border"],
			_dark: tokens.colors.dark["btn-connected-border"]
		},
		"btn-disconnected-border": {
			default: tokens.colors.light["btn-disconnected-border"],
			_dark: tokens.colors.dark["btn-disconnected-border"]
		},
		"btn-disconnected-hover-bg": {
			default: tokens.colors.light["btn-disconnected-hover-bg"],
			_dark: tokens.colors.dark["btn-disconnected-hover-bg"]
		},
		"btn-connected-hover-bg": {
			default: tokens.colors.light["btn-connected-hover-bg"],
			_dark: tokens.colors.dark["btn-connected-hover-bg"]
		},
		inputbordernotpristine: {
			default: tokens.colors.light["inputbordernotpristine"],
			_dark: tokens.colors.dark["inputbordernotpristine"]
		},
		evisgreen: {
			default: tokens.colors.light["evisgreen"],
			_dark: tokens.colors.dark["evisgreen"]
		},
		darkgrey: {
			default: tokens.colors.light["darkgrey"],
			_dark: tokens.colors.dark["darkgrey"]
		},
		"field-addon-bg": {
			default: tokens.colors.light["field-addon-bg"],
			_dark: tokens.colors.dark["field-addon-bg"]
		},
		"field-addon-text": {
			default: tokens.colors.light["field-addon-text"],
			_dark: tokens.colors.dark["field-addon-text"]
		},
		"badge-underline": {
			default: tokens.colors.light["badge-underline"],
			_dark: tokens.colors.dark["badge-underline"]
		},
		"field-bg": {
			default: tokens.colors.light["field-bg"],
			_dark: tokens.colors.dark["field-bg"]
		},
		"field-placeholder-text": {
			default: tokens.colors.light["field-placeholder-text"],
			_dark: tokens.colors.dark["field-placeholder-text"]
		},
		"field-secondary-placeholder-text": {
			default: tokens.colors.light["field-secondary-placeholder-text"],
			_dark: tokens.colors.dark["field-secondary-placeholder-text"]
		},
		"tooltip-bg": {
			default: tokens.colors.light["tooltip-bg"],
			_dark: tokens.colors.dark["tooltip-bg"]
		},
		"tooltip-text": {
			default: tokens.colors.light["tooltip-text"],
			_dark: tokens.colors.dark["tooltip-text"]
		},
		"field-primary-bg": {
			default: tokens.colors.light["field-primary-bg"],
			_dark: tokens.colors.dark["field-primary-bg"]
		},
		"field-edited-bg": {
			default: tokens.colors.light["field-edited-bg"],
			_dark: tokens.colors.dark["field-edited-bg"]
		},
		"field-error-bg": {
			default: tokens.colors.light["field-error-bg"],
			_dark: tokens.colors.dark["field-error-bg"]
		},
		"field-edited-text": {
			default: tokens.colors.light["field-edited-text"],
			_dark: tokens.colors.dark["field-edited-text"]
		},
		"field-numberstepper-bg": {
			default: tokens.colors.light["field-numberstepper-bg"],
			_dark: tokens.colors.dark["field-numberstepper-bg"]
		},
		"field-numberstepper-border": {
			default: tokens.colors.light["field-numberstepper-border"],
			_dark: tokens.colors.dark["field-numberstepper-border"]
		},
		"#aeaca9": {
			default: tokens.colors.light["#aeaca9"],
			_dark: tokens.colors.dark["#aeaca9"]
		},
		"select-icon": {
			default: tokens.colors.light["select-icon"],
			_dark: tokens.colors.dark["select-icon"]
		},
		"divider-color": {
			default: tokens.colors.light["divider-color"],
			_dark: tokens.colors.dark["divider-color"]
		},
		"header-gradient-hide-0": {
			default: tokens.colors.light["header-gradient-hide-0"],
			_dark: tokens.colors.dark["header-gradient-hide-0"]
		},
		"header-gradient-hide-100": {
			default: tokens.colors.light["header-gradient-hide-100"],
			_dark: tokens.colors.dark["header-gradient-hide-100"]
		},
		"card-bg-hover": {
			default: tokens.colors.light["card-bg-hover"],
			_dark: tokens.colors.dark["card-bg-hover"]
		},
		"button-secondary-bg": {
			default: tokens.colors.light["button-secondary-bg"],
			_dark: tokens.colors.dark["button-secondary-bg"]
		},
		"button-secondary-text": {
			default: tokens.colors.light["button-secondary-text"],
			_dark: tokens.colors.dark["button-secondary-text"]
		},
		"button-secondary-hover-bg": {
			default: tokens.colors.light["button-secondary-hover-bg"],
			_dark: tokens.colors.dark["button-secondary-hover-bg"]
		},
		"button-secondary-active-bg": {
			default: tokens.colors.light["button-secondary-active-bg"],
			_dark: tokens.colors.dark["button-secondary-active-bg"]
		},
		"text-highlight-header": {
			default: tokens.colors.light["text-highlight-header"],
			_dark: tokens.colors.dark["text-highlight-header"]
		}
	},
	shadows: {
		"btn-shadow": {
			default: `${tokens.colors.light["btn-shadow"]}, ${tokens.colors.light["btn-inset-shadow"]}, ${tokens.colors.light["btn-primary-shadow"]}, ${tokens.colors.light["btn-primary-inset-shadow"]}`,
			_dark: `${tokens.colors.dark["btn-shadow"]}, ${tokens.colors.dark["btn-inset-shadow"]}, ${tokens.colors.dark["btn-primary-shadow"]}, ${tokens.colors.dark["btn-primary-inset-shadow"]}`
		},
		"btn-inset-shadow": {
			default: `${tokens.colors.light["btn-inset-shadow"]}, ${tokens.colors.light["btn-primary-inset-shadow"]}`,
			_dark: `${tokens.colors.dark["btn-inset-shadow"]}, ${tokens.colors.dark["btn-primary-inset-shadow"]}`
		}
	}
}

// TODO: define global styles'
const styles = {
	global: {
		":root": {
			"--bg-default": "var(--chakra-colors-bg-default)"
		},
		body: {
			background: "bg-default"
		},
		// fonts: {
		//   body: `"DIN_bold", sans-serif`,
		//   heading: `"DIN_condensed_bold", sans-serif`
		//   // button: DIN_condensed_bold,
		// },
		"*": {
			//! replace with specific selectors to make toastify work
			textTransform: "uppercase"
		},
		Button: {
			textTransform: "uppercase",
			fontStretch: "condensed"
		},
		Select: {
			textTransform: "uppercase"
		},
		Input: {
			field: {
				casing: "none"
			},
			borderColor: "inputbordernotpristine"
		},
		Text: {
			baseStyle: {
				// casing: "uppercase"
			},
			variants: {
				primary: {
					// textTransform: "uppercase",
					color: "fg-default",
					fontSize: "md",
					letterSpacing: "tighter",
					fontWeight: "black"
				}
			},
			defaultProps: {
				// if no variant or size is passed in props, use these
				variant: "primary"
			}
		},
		Table: {
			th: {
				letterSpacing: "normal !important"
			}
		}
	}
}
// TODO: override components like Button, Input, etc.
// COMPONENTS
const components = {
	Button: {
		baseStyle: {
			borderRadius: "0",
			// color: "field-default-text",
			// bg: "btn-bg",

			_focus: {
				ring: "0px"
			}
		},
		variants: {
			primary: {
				backgroundColor: "btn-primary-bg",
				borderColor: "btn-primary-border",
				borderWidth: "1px",

				color: "btn-primary-text",
				_hover: {
					backgroundColor: "btn-primary-hover-bg"
				},
				_active: {
					backgroundColor: "btn-primary-active-bg",
					borderColor: "btn-primary-active-border",
					color: "btn-primary-text-active"
				}
			},
			statusDisconnected: {
				backgroundColor: "btn-disconnected-bg",
				borderColor: "btn-disconnected-border",
				borderWidth: "1px",
				color: "btn-text",
				marginRight: "0",
				borderBottom: "none",
				// paddingRight: "0",
				_hover: {
					backgroundColor: "btn-disconnected--hover-bg",
					color: "btn-primary-text-hover"
				},
				_active: {
					// backgroundColor: "btn-primary-active-bg",
					// borderColor: "btn-primary-active-border",
					// color: "btn-primary-text-active"
				}
			},
			statusConnected: {
				backgroundColor: "btn-connected-bg",
				borderColor: "btn-connected-border",
				borderWidth: "1px",
				// shadow: "btn-primary-shadow",
				color: "btn-text",
				_hover: {
					backgroundColor: "btn-connected--hover-bg",
					// borderColor: "btn-primary-hover-border",
					color: "btn-primary-text-hover"
				},
				_active: {
					// backgroundColor: "btn-primary-active-bg",
					// borderColor: "btn-primary-active-border",
					// color: "btn-primary-text-active"
				}
			},
			localization: {
				backgroundColor: "bg-default",
				// borderColor: "none",
				// borderWidth: "none",
				color: "btn-primary-text",
				marginRight: "3",

				_hover: {
					backgroundColor: "btn-localization-active-bg"
				},
				_active: {
					backgroundColor: "btn-localization-active-bg",
					// backgroundColor: "blue",
					// borderColor: "none",
					color: "btn-primary-text-active"
				}
			}
		},
		sizes: {
			md: {
				h: "5",
				p: "3",
				fontSize: "md",
				letterSpacing: "tighter",
				fontWeight: "black"
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary",
			size: "md"
			// borderRasdius: "0"
		}
	},
	Input: {
		baseStyle: {
			// color: "field-default-text",
			// bg: "bg-default",
			// _focus: {
			//   ring: "0px"
			// }
			// h: "5"
			field: {
				textTransform: "none"
			}
		},
		variants: {
			primary: {
				field: {
					casing: "lowercase",
					fontSize: "md",
					borderRadius: "0",
					color: "field-default-text",
					// bg: "field-bg", //! fallback field-bg
					bg: "field-primary-bg",
					_placeholder: {
						color: "field-secondary-placeholder-text" // Change this to your desired color
					}
				},
				addon: {
					casing: "uppercase",
					borderRadius: "0",
					color: "field-addon-text"
					// border: "1px solid grey.300",
					// borderColor: "gray.300",
					// background: "field-addon-bg" //! had to be set in SingleParameter.jsx, bc it's auto-overridden with compiled css class
				},
				element: {
					// borderRadius: "0",
					// color: "field-default-text",
					// bg: "bg-default"
				}
			},
			secondary: {
				field: {
					casing: "lowercase",
					fontSize: "md",
					border: "1.5px solid",
					borderRadius: "0",
					borderColor: "btn-primary-hover-bg",
					color: "field-secondary-default-text",
					// bg: "field-bg", //! fallback field-bg
					bg: "white",
					_placeholder: {
						color: "field-placeholder-text" // Change this to your desired color
					}
				},
				addon: {
					casing: "uppercase",
					borderRadius: "0",
					color: "field-addon-text"
					// border: "1px solid grey.300",
					// borderColor: "gray.300",
					// background: "field-addon-bg" //! had to be set in SingleParameter.jsx, bc it's auto-overridden with compiled css class
				},
				element: {
					// borderRadius: "0",
					// color: "field-default-text",
					// bg: "bg-default"
				}
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary"
		}
	},
	Select: {
		baseStyle: {
			// color: "field-default-text",
			borderRadius: "0",

			field: {
				border: "none",
				// borderRadius: "0",
				// bg: "field-bg",
				// color: "field-default-text",
				// borderColor: "btn-border",
				// _active: {
				//   borderColor: "btn-border-active"
				// },
				// borderWidth: "1px"
				// textAlign: "right",
				// paddingRight: "33px",
				// textFloat: "right",
				_focus: {
					ring: "0px"
				}
			},
			icon: {
				// boxSize: "60px",
				width: "40px",
				height: "40px",
				// color: "select-icon",
				zIndex: "4",
				mr: "-18px",
				mt: "9px"
				// transform: "rotate(90deg)"
			}
		},
		variants: {
			primary: {
				field: {
					border: "none",
					borderRadius: "0",
					// bg: "field-bg", //! fallback field-bg
					bg: "field-primary-bg",
					// borderColor: "btn-primary-border",
					borderWidth: "1px"
					// shadow: "btn-primary-shadow",
					// color: "field-default-text"
				}
			}
		},
		sizes: {
			md: {
				h: "5",
				p: "3",
				fontSize: "md",
				letterSpacing: "tighter",
				fontWeight: "black"
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary",
			size: "md"
		}
	},
	NumberInput: {
		baseStyle: {},
		variants: {
			primary: {
				field: {
					zIndex: "0",
					border: "none",
					borderRadius: "0",
					color: "field-default-text",
					bg: "field-primary-bg",
					_placeholder: {
						color: "field-placeholder-text" // Change this to your desired color
					}
					// skew: "45deg"

					// mr: "20px"
				},
				stepperGroup: {
					// zIndex: "3",
					// borderLeft: "3px solid",
					// outline: "2px solid",
					// outlineColor: "field-numberstepper-bg",
					// borderColor: "bg-default",
					borderRadius: "0",
					color: "field-default-text",
					// bg: "transparent",
					bg: "transparent"
					// transform: "rotate(-45deg)"
				},
				stepper: {
					border: "none",
					// zIndex: "3",
					// borderRadius: "0",
					color: "field-default-text",
					bg: "bg-primary",
					fontSize: "12px"
				},
				addon: {
					borderRadius: "0",
					color: "field-default-text",
					bg: "bg-default"
				}
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary"
		}
	},
	Badge: {
		baseStyle: {
			borderRadius: "0"
		},
		variants: {
			primary: {
				// paddingLeft: "0",
				color: "badge-underline",
				bg: "none",
				borderColor: "badge-underline",
				borderTop: "none",
				borderLeft: "none",
				borderRight: "none",
				borderBottom: "2px solid"
				// width: "100%",
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary"
		}
	},
	Tooltip: {
		baseStyle: {},
		variants: {
			primary: {
				bg: "tooltip-bg",
				color: "tooltip-text",
				borderRadius: "0"
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary"
		}
	},
	Card: {
		baseStyle: {},
		variants: {
			primary: {
				container: {
					borderRadius: "0",
					// bg: "default-bg ",
					backgroundColor: "default-bg",
					color: "field-default-text"
				}
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary"
		}
	},
	Icon: {
		baseStyle: {},
		variants: {
			primary: {
				color: "icon-color"
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			// variant: "primary"
		}
	},
	Checkbox: {
		baseStyle: {
			control: {
				// borderRadius: "0",
				// bg: "field-bg",
				// borderColor: "btn-border",
				// _active: {
				//   borderColor: "btn-border-active"
				// },
				// borderWidth: "1px",
				_focus: {
					ring: "0px"
					// ringColor: "inputbordernotpristine"
				}
			}
		},
		variants: {
			primary: {
				control: {
					// bg: "field-bg", //! fallback field-bg
					bg: "field-primary-bg",
					borderColor: "button-secondary-hover-bg",
					// borderColor: "button-secondary-active-bg",
					borderWidth: "1.5px",
					shadow: "btn-primary-shadow",
					color: "field-default-text"
				}
			}
		},
		defaultProps: {
			// if no variant or size is passed in props, use these
			variant: "primary"
		}
	}
}

// THEME
const theme_mineralis = extendTheme({
	// TODO: add styles, semantic tokens and component
	styles,
	semanticTokens,
	components,
	fonts
})

export default theme_mineralis
