import React, { forwardRef } from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Icon,
	Tooltip
} from "@chakra-ui/react"
import { DeleteIcon } from "@chakra-ui/icons"
import { useTranslate } from "react-polyglot"

const ConfirmActionModal = forwardRef((props, ref) => {
	const t = useTranslate()
	const { user, adminCounts, confirmType } = props

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Tooltip
				hasArrow={true}
				bg="tooltip-bg"
				placement="right"
				pl={4}
				arrowSize={12}
				openDelay={1000}
				closeDelay={700}
				textAlign={"center"}
				label={props.tooltipLabel}
				fontSize="md"
				aria-label="delete user button"
			>
				<Button
					size="xs" 
					mr={0} 
					borderRadius="5px" 
					border="none" 
					ref={ref}
					disabled={(() => {
						switch (confirmType) {
							case "deleteTenantUser":
								return (user.role === "evisAdmin" && adminCounts.evisAdmin <= 1) ||
									(user.role === "tenantAdmin" && adminCounts.tenantAdmin <= 1);
							case "deleteTenant":
								return (user.role === "tenantAdmin" ||
									user.role === "evisAdmin" ||
									user.tenantName === "EVIS" ||
									user.tenantName === "EVIS MASTER");
							case "deleteProject":
								return false;
							default:
								return false;
						}
					})()}
					cursor={(() => {
						switch (confirmType) {
							case "deleteTenantUser":
								return (user.role === "evisAdmin" && adminCounts.evisAdmin <= 1) ||
									(user.role === "tenantAdmin" && adminCounts.tenantAdmin <= 1);
							case "deleteTenant":
								return (user.role === "tenantAdmin" ||
									user.role === "evisAdmin" ||
									user.tenantName === "EVIS" ||
									user.tenantName === "EVIS MASTER");
							case "deleteProject":
								return false;
							default:
								return false;
						}
					})()}
					onClick={() => {
						onOpen();
						if (props.confirmType === "deleteProject") {
							props.setConfirmDeleteProject(props.projectNumber);
						}
					}}
				>
					<DeleteIcon fontSize="14px"/>
				</Button>
			</Tooltip>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				blockScrollOnMount={true}
				size={"sm"}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader
						pt={2}
						pb={0}
						mb={0}
					>
						{(() => {
							switch (props.confirmType) {
								case "deleteTenantUser":
									return t("ConfirmActionModal.questionTenantUser", {
										_: "Benutzer entfernen?"
									});
								case "deleteTenant":
									return t("ConfirmActionModal.questionTenant", {
										_: "Distributor entfernen?"
									});
								case "deleteProject":
									return t("ConfirmActionModal.questionProject", {
										_: "Projekt entfernen?"
									});
								default:
									return "";
							}
						})()}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						mt={2}
						mb={-1}
						fontSize={"lg"}
					>
						{props.message}
					</ModalBody>
					<ModalFooter>
						<Button
							variant="solid"
							backgroundColor="#A63C32"
							color="white"
							mr={3}
							onClick={onClose}
						>
							{t("ConfirmActionModal.cancelAction", {
								_: "Zurück"
							})}
						</Button>
						<Button onClick={props.handleClick}>
							{t("ConfirmActionModal.confirmAction", {
								_: "Entfernen"
							})}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
})

export default ConfirmActionModal
