import React, {
	useContext,
	useEffect,
	useCallback,
	useState,
	useRef
} from "react"
import { Flex, Select, Text } from "@chakra-ui/react"
import { keyframes } from "@emotion/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import DecoratorTriangleInputSelectDashboardButtons from "../../GUI/DecoratorTriangleInputSelectDashboardButtons.jsx"
import myModule from "../../../utils/FileTransfer/TlvModules/tlvAccessModuleFile.mjs"
import { putFile } from "../../../utils/FileTransfer/TlvAccessProtocol/TlvFiletransfer.js"
import { sendDeleteCmd } from "../../../utils/FileTransfer/TlvAccessProtocol/TlvAccessCmd.js"
import { useQuery } from "@tanstack/react-query"
import { getFirmwareReleasesForDeviceFirmwareNumber } from "../../../api/fetchHooks/getFirmwareReleasesForDeviceFirmwareNumber.js"
import { getFirmwareFile } from "../../../api/fetchHooks/getFirmwareFile.js"
import { useAtom } from "jotai"
import progressAtom from "../../../api/jotaiAtoms/progressAtom.js"
import deviceInfoAtom from "../../../api/jotaiAtoms/deviceInfoAtom.js"
import { PortContext } from "../../../contexts/WebSerialInstanceContext.js"
import { toast, ToastContainer } from "react-toastify"
import ToastProgressBar from "../../GUI/ToastProgressBar.jsx"
//# LOCALIZATION
import { useTranslate } from "react-polyglot"
import isBlockingInteractionAtom from "../../../api/jotaiAtoms/isBlockingInteraction.js"
import checkDeviceOfTenant from "../../../api/fetchHooks/checkOrSetDeviceOfTenantToDB.js"
import { useAuth } from "../../../contexts/AuthContext.js"
import checkOrSetDeviceOfTenantToDB from "../../../api/fetchHooks/checkOrSetDeviceOfTenantToDB.js"

const MineralisOsSelector = (props) => {
	const { userClaims } = useAuth()
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)
	const [versionEndpoint, setVersionEndpoint] = useState(null) //TODO: make this a wildcard to display all firmware versions when no device is connected
	const [maxFirmwareVersion, setMaxFirmwareVersion] = useState(null)
	const [port, setPort] = useContext(PortContext)
	const [isBlockingInteraction, setIsBlockingInteraction] = useAtom(
		isBlockingInteractionAtom
	)

	const t = useTranslate()
	const pulse = keyframes`
  0%, 20%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`

	//# TOAST PROGRESS BAR FOR FIRMWARE UPLOAD STATUS
	//^ setProgress is callback-function from putFile in TlvFiletransfer.js to update the progress state - onProgress function in putFile gives back the current progress value
	//^ progress is the state to update the progress bar
	//^toast is initialized below, then updated with updsate() function and rerendered with useEffect
	const [progress, setProgress] = useAtom(progressAtom)
	const toastId = useRef(null)
	const [currentFileName, setCurrentFileName] = useState(null)
	const [activeToasts, setActiveToasts] = useState({})

	const update = () => {
		if (progress < 100) {
			toast.update(toastId.current, {
				type: "info",
				theme: "light",
				limit: 1,

				render: (
					<ToastProgressBar
						progress={progress}
						fileName={currentFileName}
					/>
				)
			})
		} else {
			toast.update(toastId.current, {
				render: (
					<Text>
						{currentFileName}
						{t("Toasts.toastFirmwareSendSuccess", {
							_: " übertragen"
						})}
					</Text>
				),
				autoClose: 3000,
				type: "success",
				theme: "colored",
				isLoading: false,
				hideProgressBar: true,
				limit: 1
			})
			toast.clearWaitingQueue()
		}
	}

	useEffect(() => {
		update()
	}, [progress])
	//^ END TOAST PROGRESS BAR - toast initialized below

	//# THIS I NEED THIS TO UPDATE AND RERENDER THE SELECT DROPDOWN
	useEffect(() => {
		//^ rerender MineralisOS selector when deviceInfo changes
	}, [deviceInfo])

	//# useEffect to fetch the selected firmware files
	useEffect(() => {
		const fetchData = async () => {
			if (
				versionEndpoint !== null &&
				versionEndpoint !== undefined &&
				deviceInfo.firmwareNumber !== null &&
				deviceInfo.firmwareNumber !== undefined
			) {
				const firmwareFiles = await getFirmwareFile(
					versionEndpoint,
					deviceInfo.firmwareNumber.toString()
				)
				if (firmwareFiles === undefined) {
					getFirmwareFile(
						versionEndpoint,
						deviceInfo.firmwareNumber.toString() || wildcard
					)
					return
				} else {
					handleFirmwareFileSelected(firmwareFiles[0].Files)
				}
			}
		}
		fetchData()
	}, [versionEndpoint, setVersionEndpoint])

	//# useQuery to get the firmware version from MongoDB and display it in the dropdown
	const wildcard = new RegExp(".*")

	// const [maxFirmwareVersion, setMaxFirmwareVersion] = useState(null)

	// useEffect(() => {
	// 	const fetchMaxFirmwareVersion = async () => {
	// 		const response = await checkOrSetDeviceOfTenantToDB(
	// 			deviceInfo?.serialNumber,
	// 			userClaims,
	// 			deviceInfo
	// 		)
	// 		setMaxFirmwareVersion(response.maxFirmwareVersion)
	// 	}
	// }, [deviceInfo, userClaims])

	//TODO: fetchMaxFirmwareVersion NEEDS REFACTORING TO CHECK MIN AND MAX FULL VERSION WITH DIFFERENT FUNCTION AND ENDPOINT (re-using checkOrSetDeviceOfTenantToDB causes issues)
	// useEffect(() => {
	// 	const fetchMaxFirmwareVersion = async () => {
	// 		try {
	// 			const response = await checkOrSetDeviceOfTenantToDB(
	// 				deviceInfo?.serialNumber,
	// 				userClaims,
	// 				deviceInfo
	// 			)
	// 			setMaxFirmwareVersion(response.maxFirmwareVersion)
	// 			console.log("maxFirmwareVersion", response.maxFirmwareVersion)
	// 		} catch (error) {
	// 			console.error("Error fetching maxFirmwareVersion:", error)
	// 		}
	// 	}

	// 	fetchMaxFirmwareVersion()
	// }, [deviceInfo, userClaims])

	// update when maxFirmwareVersion or minFirmwareVersion changes
	useEffect(() => {
		// console.log("maxFirmwareVersion", props.maxFirmwareVersion)
		// console.log("minFirmwareVersion", props.minFirmwareVersion)
	}, [props.maxFirmwareVersion, props.minFirmwareVersion])

	// Replace the existing useQuery with this updated version
	const { data: mineralisOsReleasesForDeviceFirmwareNumber } = useQuery({
		queryKey: [
			"mineralisOsReleasesForDeviceFirmwareNumber",
			deviceInfo?.firmwareNumber,
			props.maxFirmwareVersion,
			props.minFirmwareVersion
		],
		queryFn: async () => {
			return getFirmwareReleasesForDeviceFirmwareNumber(
				deviceInfo?.firmwareNumber?.toString() || wildcard,
				props.maxFirmwareVersion,
				props.minFirmwareVersion
			)
		},
		staleTime: process.env.NODE_ENV === "production" ? 1000 * 3 : 1000 * 3,
		enabled: deviceInfo !== null
	})

	useEffect(() => {
		//^ rerender MineralisOS selector when firmwareReleasesForDeviceFirmwareNumber changes
	}, [mineralisOsReleasesForDeviceFirmwareNumber])

	//# useQuery to get the firmware file for the selected version
	const versionEndpointQueryKey = "firmwareFiles"

	const {
		data: firmwareFiles,
		isLoading,
		isError,
		isSuccess,
		error,
		refetch,
		remove,
		status,
		isFetching
	} = useQuery({
		queryKey: [versionEndpointQueryKey, deviceInfo?.firmwareNumber],
		queryFn: async () =>
			await getFirmwareFile(
				versionEndpoint,
				deviceInfo.firmwareNumber.toString() || wildcard
			),
		staleTime: process.env.NODE_ENV === "production" ? 1000 * 60 * 5 : 1000 * 3,
		enabled: versionEndpoint !== null
	})

	const handleFirmwareFileSelected = async (selectedFiles) => {
		setIsBlockingInteraction(true)
		for (const currentFile of selectedFiles) {
			try {
				await new Promise((resolve, reject) => {
					setCurrentFileName(currentFile.Name)

					const base64String = currentFile.Filestream

					const decodeBase64 = (base64String) => {
						try {
							return new Uint8Array(
								atob(base64String)
									.split("")
									.map((c) => c.charCodeAt(0))
							)
						} catch (err) {
							console.error("Failed to decode Base64 string:", err)
							return null
						}
					}
					const binaryData = decodeBase64(base64String)

					myModule()
						.then(async (instance) => {
							// eslint-disable-next-line no-unused-vars
							const answer = await sendDeleteCmd(
								instance,
								port,
								"update/" + currentFile.Name
							)

							//^ Initialize the toast with a pending status
							toastId.current = toast.loading(
								t("Toasts.toastFirmwareSendPreparing", {
									_: "Firmware wird vorbereitet"
								}),
								{
									type: "info",
									theme: "light",
									limit: 1,
									closeOnClick: true
								}
							)

							//^ progress needs to be reaset to 0 for each file here bc small files will only trigger 100 right away
							//^ setting 0 here forces a rerender, bc the progress bar is only updated when progress changes (and it is already 100 from the last file)
							setProgress(0)
							// eslint-disable-next-line no-unused-vars
							const response = await putFile(
								instance,
								port,
								"update/" + currentFile.Name,
								true,
								new Uint8Array(binaryData),
								setProgress
							)
							resolve()
						})
						.catch((error) => {
							console.log(error)
							toast.error(
								t("Toasts.toastFirmwareSendError", {
									_: "Firmwareupdate fehlgeschlagen"
								})
							)
							reject()
						})
				})
			} catch (error) {
				console.log(error)
				setIsBlockingInteraction(false)
				toast.error("Es ist ein Fehler aufgetreten.")
			} finally {
				setIsBlockingInteraction(false)
			}
		}
	}

	const handleFirmwareVersionChange = useCallback(async (e) => {
		// console.log(e.target.value)
		//^ get the value of the selected dropdown option and update the state "versionEndpoint" with it
		setVersionEndpoint(e.target.value)

		await firmwareFiles
	}, [])

	if (isLoading)
		return (
			<div style={{ marginTop: "0px", marginLeft: "0px" }}>
				<Select
					// size={"sm"}

					width={"145px"}
					h={"24px"}
					cursor={"pointer"}
					fontSize={"0.92rem"}
					icon={
						<ChevronDownIcon
							display="flex"
							mb={"2px"}
							ml={"5px"}
						/>
					}
					placeholder={t("MineralisOsSelector.selectButtonPlaceholder", {
						_: "Mineralis OS"
					})}
				></Select>
				<DecoratorTriangleInputSelectDashboardButtons
					backgroundColor="btn-primary-hover-bg"
					borderColor="btn-primary-border"
				/>
			</div>
		)
	if (isError) return <div>Error fetching data</div>

	return (
		<div>
			<Flex>
				<Select
					width={"145px"}
					h={"24px"}
					cursor={"pointer"}
					fontSize={"0.92rem"}
					icon={
						<ChevronDownIcon
							display="flex"
							mb={"2px"}
							ml={"5px"}
						/>
					}
					pr={0}
					mr={0}
					// mt={"1px"}
					placeholder={t("MineralisOsSelector.selectButtonPlaceholder", {
						_: "Mineralis OS"
					})}
					value={deviceInfo?.firmwareNumber?.toString()}
					onChange={(e) => handleFirmwareVersionChange(e)}
				>
					{mineralisOsReleasesForDeviceFirmwareNumber !== undefined &&
					mineralisOsReleasesForDeviceFirmwareNumber.length !== 0 ? (
						mineralisOsReleasesForDeviceFirmwareNumber?.map(
							(firmwareRelease) => (
								<option
									pr={0}
									mr={0}
									key={firmwareRelease._id}
									value={firmwareRelease.Version}
								>
									FW {firmwareRelease.Version}
								</option>
							)
						)
					) : (
						<option
							pr={0}
							mr={0}
						>
							{versionEndpoint === null ? (
								<Text animation={`${pulse} 1.5s infinite ease-in-out`}>
									Loading
								</Text>
							) : (
								t("MineralisOsSelector.selectButtonNoFW", {
									_: "Keine Firmware verfügbar"
								})
							)}
						</option>
					)}
				</Select>
				<DecoratorTriangleInputSelectDashboardButtons
					backgroundColor="btn-primary-hover-bg"
					borderColor="btn-primary-border"
				/>
			</Flex>
		</div>
	)
}

export default MineralisOsSelector
