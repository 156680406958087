import React, { useEffect, useState } from "react"
import { Box, Text } from "@chakra-ui/react"
import { useTranslate } from "react-polyglot"
import getTenantNameByTenantId from "../firestoreHelpers/getTenantNameByTenantId.js"
import { useAuth } from "../../../contexts/AuthContext.js"

export default function TenantAffiliation(props) {
	const { userClaims } = useAuth()
	const t = useTranslate()
	const [tenantName, setTenantName] = useState("")

	useEffect(() => {
		const fetchTenantName = async () => {
			try {
				const name = await getTenantNameByTenantId(
					props.currentUserData.tenantId
				)
				setTenantName(name)
			} catch (error) {
				console.error("Error fetching tenant name:", error)
			}
		}

		fetchTenantName()
	}, [props.currentUserData.tenantId])

	return (
		<>
			<Box mt={1}>
				<Text
					fontSize="lg"
					fontWeight="bold"
				>
					<span
						style={{
							textTransform: "lowercase"
						}}
					>
						{props.currentUserData.email}
					</span>
					<span style={{ paddingLeft: "8px", paddingRight: "8px" }}>|</span>
					<span pl={8}>{props.currentUserData.tenantId}</span>
					<span>{props.currentUserData.tenant}</span>
					<span style={{ paddingLeft: "4px", paddingRight: "4px" }}>-</span>
					<span>{tenantName}</span>
				</Text>
			</Box>
		</>
	)
}
