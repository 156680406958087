import React from "react"
import { Box, Tooltip } from "@chakra-ui/react"
import handleRemoveTenantUser from "../../firestoreHelpers/handleRemoveTenantUser.js"
import { useAuth } from "../../../../contexts/AuthContext.js"
import { useAtom } from "jotai"
import rerenderTriggerTenantUserListAtom from "../../../../api/jotaiAtoms/rerenderTriggerTenantUserListAtom.js"
import { useTranslate } from "react-polyglot"
import { toast } from "react-toastify"
import ConfirmActionModal from "../../../GUI/ConfirmActionModal.jsx"

const UserListActions = (props) => {
	const t = useTranslate()

	const [rerenderTriggerTenantUserList, setRerenderTriggerTenantUserList] =
		useAtom(rerenderTriggerTenantUserListAtom)

	const { user, adminCounts } = props
	const handleClick = async () => {
		handleRemoveTenantUser(user, useAuth)

		//^ Toast message after deleting tenant
		toast.success(
			t("Toasts.toastUserListActionsUserDeleted", { _: "Benutzer gelöscht" })
		)

		//^ Trigger re-render of TenantUsersList
		setRerenderTriggerTenantUserList((prev) => !prev)
	}

	return (
		<Box>
			<ConfirmActionModal
				confirmType="deleteTenantUser"
				handleClick={handleClick}
				user={user}
				adminCounts={adminCounts}
				message={`${user.displayName}`}
				tooltipLabel={
					user.role === "tenantAdmin" ||
					user.role === "evisAdmin" ||
					user.role === "evisMaster"
						? t("UserListActions.deleteAdminNotAllowed", {
								_: "Admin kann nicht gelöscht werden"
							})
						: t("UserListActions.deleteUser", { _: "Benutzer löschen" })
				}
			/>
		</Box>
	)
}

export default UserListActions
