import React, { useState, useEffect } from "react"
import { Box } from "@chakra-ui/react"
import AccountSettingsListItem from "../AccountSettingsListItem.jsx"
import { useAuth } from "../../../contexts/AuthContext.js"

const UserManagement = () => {
	const { userClaims } = useAuth()
	const [isVisible, setIsVisible] = useState(false)
	const isEvisAdmin = userClaims?.role === "evisAdmin"
	const isTenantAdmin = userClaims?.role === "tenantAdmin"

	useEffect(() => {
		setIsVisible(true)
	}, [])

	return (
		<Box
			// marginTop={"16vh"}
			pt={"141px"} //^ FOR MINERALIS HEADER
			// marginLeft={"6rem"}
			marginLeft={{ base: "6rem", md: "3rem", lg: "6rem" }}
			marginBottom={"5rem"}
			// minHeight="100vh"
			overflow={"hidden"}
			height={"max-content"}
			// transition="opacity 1s ease-in-out"
			// opacity={isVisible ? 1 : 0}
		>
			{isEvisAdmin && (
				<AccountSettingsListItem
					settingsType="addTenant"
					maxW={`max-content`}
				/>
			)}
			{(isEvisAdmin || isTenantAdmin) && (
				<AccountSettingsListItem
					settingsType="addTenantUser"
					maxW={`max-content`}
				/>
			)}
			{isEvisAdmin && (
				<AccountSettingsListItem
					settingsType="resetPasswordRemote"
					maxW={`max-content`}
				/>
			)}
		</Box>
	)
}

export default UserManagement
