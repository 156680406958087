import React from "react"

import { Box } from "@chakra-ui/react"

const DecoratorTriangleButtonResizable = (props) => {
	return (
		<Box
			height={props.decoratorTriangleHeight}
			width={props.decoratorTriangleWidth}
			marginInlineStart={0}
			bg={props.backgroundColor}
			clipPath="polygon(107% 0, 0 0, 0 107%)"
			backgroundColor={props.backgroundColor}
			border="1px solid"
			borderColor={props.borderColor}
			ml={props.decoratorTriangleMl}
		/>
	)
}

export default DecoratorTriangleButtonResizable
