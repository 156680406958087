// client/src/components/Auth/SignUp.jsx
import React, { useEffect, useRef, useState } from "react"
import {
	Link as ChakraLink,
	Button,
	Input,
	Box,
	FormControl,
	FormLabel,
	Flex,
	Select,
	Tooltip,
	InputLeftAddon,
	InputGroup
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { LuUsers } from "react-icons/lu"
import { useAuth } from "../../../contexts/AuthContext.js"
import useCurrentUserStore from "../../../api/zustandStores/userStore/currentUserStore.js"
import DecoratorTriangleButtonResizable from "../../GUI/DecoratorTriangleButtonResizable.jsx"
import DecoratorTriangleInputSelect from "../../GUI/DecoratorTriangleInputSelect.jsx"

import { useTranslate } from "react-polyglot"
import { toast } from "react-toastify"
import signUpTenantUser from "../firestoreHelpers/signUpTenantUser.js"
import TenantUsersList from "./TenantUserList/TenantUsersList.jsx"
import { useAtom } from "jotai"
import rerenderTriggerTenantListAtom from "../../../api/jotaiAtoms/rerenderTriggerTenantListAtom.js"
import rerenderTriggerTenantUserListAtom from "../../../api/jotaiAtoms/rerenderTriggerTenantUserListAtom.js"
import getTenants from "../firestoreHelpers/getTenants.js"
import tenantIdForTenantUserListAtom from "../../../api/jotaiAtoms/tenantIdForTenantUserListAtom.js"

const AddTenantUser = () => {
	const { userClaims } = useAuth()
	const { currentUserData, setCurrentUserData } = useCurrentUserStore()
	const [rerenderTriggerTenantUserList, setRerenderTriggerTenantUserList] =
		useAtom(rerenderTriggerTenantUserListAtom)

	useEffect(() => {}, [rerenderTriggerTenantUserList])

	const [rerenderTriggerTenantList, setRerenderTriggerTenantList] = useAtom(
		rerenderTriggerTenantListAtom
	)

	useEffect(() => {}, [rerenderTriggerTenantUserList])

	const [tenantIdForTenantUserList, setTenantIdForTenantUserList] = useAtom(
		tenantIdForTenantUserListAtom
	)

	const emailRef = useRef()
	const tenantUserNameRef = useRef()
	const passwordRef = useRef()
	const passwordConfirmRef = useRef()
	const tenantUserRoleRef = useRef()

	const { signup, resetPassword } = useAuth()
	const [loading, setLoading] = useState(false)

	const t = useTranslate()

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("000000")
	const [displayName, setDisplayName] = useState("")

	const [tenantId, setTenantId] = useState(currentUserData.tenantId)

	//info: ROLE IS CONDITIONAL (if tenantId === 0000 then role = "evisUser") - unused
	// const determineInitialRole = () => {
	// 	return tenantId === "0000" || tenantId === "0003"
	// 		? "evisUser"
	// 		: "tenantUser"
	// }
	// const [role, setRole] = useState(determineInitialRole)

	// //^ update role based on tenantName
	// useEffect(() => {
	// 	setRole(determineInitialRole)
	// }, [tenantId])

	//^ static initial user role
	const [role, setRole] = useState("user")

	//^ role for conditional rendering
	const isEvisAdmin = userClaims?.role === "evisAdmin"

	//^ get existing list of tenants from firestore db
	const [tenantsListFromDB, setTenantsListFromDB] = useState([])

	//^ get existing list of tenants from firestore db
	useEffect(() => {
		const tenants = getTenants()
		tenants.then((tenants) => setTenantsListFromDB(tenants))
	}, [
		tenantIdForTenantUserList,
		setTenantIdForTenantUserList,
		rerenderTriggerTenantList
	])

	//^ handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault()
		let tenantIdForAddingUser

		//^ tenantIdForTenantUserList is set (is not an empty string), then set the tenantId to that value for this handleSubmit
		if (tenantIdForTenantUserList) {
			tenantIdForAddingUser = tenantIdForTenantUserList
		} else {
			//^ if tenantIdForTenantUserList is an empty string, then set the tenantId to the current user's tenantId
			tenantIdForAddingUser = currentUserData.tenantId
		}

		try {
			//info: if user already exists, the user will be re-enabled, otherwise a new user will be created
			//info: ther is no further toast if a user already exists, because this is handled in the signUpTenantUser function
			// console.log("tenantIdForAddingUser", tenantIdForAddingUser)
			//^ Sign up the user with authentication
			const response = await signUpTenantUser(
				tenantIdForAddingUser,
				email,
				password,
				displayName,
				role
			)
			if (response.message === "User re-enabled") {
				toast.info(t("Toasts.toastAddTenantUserReEnabled"), {
					_: "Email bereits registriert (Benutzer wieder aktiviert)"
				})
			} else {
				toast.success(t("Toasts.toastAddTenantUserSuccess"), {
					_: "Benutzer hinzugefügt"
				})
			}

			//^ send a reset password email
			await resetPassword(email)

			//^ Trigger re-render of TenantUsersList
			setRerenderTriggerTenantUserList((prev) => {
				return !prev
			})
			//^ Reset form fields
			setEmail("")
			setPassword("000000")
			setDisplayName("")
			//unused: setRole(determineInitialRole())
			setRole("user")

			//^ Reset input refs
			emailRef.current.value = ""
			passwordRef.current.value = "000000"
			passwordConfirmRef.current.value = "000000"
			tenantUserNameRef.current.value = ""
			//unused: tenantUserRoleRef.current.value = determineInitialRole()
			tenantUserRoleRef.current.value = "user"
		} catch (error) {
			console.error(
				"Error in signUpTenantUser:",
				error.response ? error.response.data : error
			)
			toast.error(t("Toasts.toastAddTenantUserError"), {
				_: "Benutzer konnte nicht hinzugefügt werden"
			})
		}
		setLoading(false)
	}

	return (
		<>
			<Box
				alignContent={"center"}
				alignItems={"center"}
				mb={0}
				maxW={"100%"}
			>
				{/* //# DROPDOWN to choose tenantGroup for evisAdmin only */}
				{isEvisAdmin && (
					<Flex
						width={"max-content"}
						alignItems="center"
					>
						<InputGroup>
							<Tooltip
								label={t("DevicesTable.deviceTableTenantSelectionTooltip", {
									_: "Distributor auswählen"
								})}
								hasArrow={true}
								bg="tooltip-bg"
								aria-label="tooltip showing current configuration format"
								placement="top-start"
								openDelay={300}
								closeDelay={200}
							>
								<InputLeftAddon
									background={"field-addon-bg"}
									height="24px"
									width="max-content"
									mt={"1px"}
									borderColor={
										localStorage.getItem("chakra-ui-color-mode") === "light"
											? "#cbd5e0"
											: "#cbd5e0"
									}
								>
									<LuUsers
										size={16}
										height="26px"
									/>
								</InputLeftAddon>
							</Tooltip>
							<Select
								onChange={(e) => setTenantIdForTenantUserList(e.target.value)}
								width={"max-content"}
								h={"26px"}
								textAlign={"start"}
								border={"1px solid"}
								borderColor={"bg-default"}
								// ml={"-16px"}
								mr={"-10px"}
								pr={1}
								cursor={"pointer"}
								fontSize={"1rem"}
								icon={
									<ChevronDownIcon
										display="flex"
										mb={"2px"}
										ml={"-6px"}
										mt={"1px"}
									/>
								}
								initialValue={currentUserData.tenantId}
							>
								{tenantsListFromDB.map((tenant) => (
									<option
										key={tenant.tenantId}
										value={tenant.tenantId}
									>
										{tenant.tenantId} - {tenant.tenantName}
									</option>
								))}
							</Select>
							<DecoratorTriangleInputSelect
								backgroundColor="btn-primary-hover-bg"
								borderColor="btn-primary-border"
							/>
						</InputGroup>
					</Flex>
				)}

				{/* //# List showing all existing users */}
				<TenantUsersList
					tenantIdForTenantUserList={tenantIdForTenantUserList}
					maxW={{ base: "100%", md: "100%" }}
				/>

				{/* //# FORM TO ADD NEW TENANT USER */}
				<form onSubmit={handleSubmit}>
					<Flex
						maxW={{ base: "100%", md: "600px", lg: "100%" }}
						flexDirection={{ base: "column", md: "row" }}
						flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
						gap={5}
						alignItems={{ base: "start", md: "end" }}
						rowGap={{ base: 3, md: 3 }}
					>
						<FormControl
							id="displayNameTenantUser"
							name="displayNameTenantUser"
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenantUser.tenantUserName", {
									_: "Name"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setDisplayName(e.target.value)}
								value={displayName}
								placeholder={t(
									"InputPlaceholders.placeholderTenantUserDisplayName",
									{
										_: "Max Mustermann"
									}
								)}
								variant={"secondary"}
								type="text"
								textTransform={"uppercase"}
								autoComplete="off"
								ref={tenantUserNameRef}
								required
								width={{ base: "100%", md: "250px" }}
							/>
						</FormControl>

						<FormControl
							id="emailTenantUser"
							name="emailTenantUser"
							width={"250px"}
						>
							<FormLabel mb={0}>Email</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								placeholder={t("InputPlaceholders.placeholderTenantUserEmail", {
									_: "MaxMustermann@email.ch"
								})}
								variant={"secondary"}
								type="email"
								autoComplete="off"
								ref={emailRef}
								required
								width={{ base: "100%", md: "250px" }}
							/>
						</FormControl>

						<FormControl
							display="none"
							isDisabled
							id="addTenantUserTenantId"
							name="addTenantUserTenantId"
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenantUser.tenantId", {
									_: "Distributor Id"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setTenantId(e.target.value)}
								variant={"secondary"}
								type="number"
								autoComplete="off"
								ref={emailRef}
								// required //TODO: ACTIVATE WHEN FUNCTIONAL
								//^ case null is initial state of tenantIdForTenantUserList
								//^ case "" when select is in default
								value={
									tenantIdForTenantUserList === null ||
									tenantIdForTenantUserList === ""
										? currentUserData.tenantId
										: tenantIdForTenantUserList
								}
								width={{ base: "100%", md: "250px" }}
							/>
						</FormControl>

						<FormControl
							display="none"
							id="tenantUserRole"
							name="tenantUserRole"
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenantUser.tenantUserRole", {
									_: "tenantUserRole"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								type="text"
								placeholder="Role"
								value={role}
								onChange={(e) => setRole(e.target.value)}
								required
								variant={"secondary"}
								ref={tenantUserRoleRef}
								autoComplete="off"
								// defaultValue={"tenantUser"} //! KEEP THIS FOR INITIAL STATE RENDERING
								width={{ base: "100%", md: "250px" }}
							/>
						</FormControl>
						<FormControl
							id="passwordTenantUser"
							name="passwordTenantUser"
							display={"none"}
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenantUser.password", {
									_: "Passwort"
								})}
							</FormLabel>
							<Input
								// onChange={(e) => setPassword(e.target.value)}
								size={"sm"}
								variant={"secondary"}
								type="password"
								ref={passwordRef}
								required
								// defaultValue={"000000"}
								autoComplete="off"
								isDisabled
								width={{ base: "100%", md: "250px" }}
							/>
						</FormControl>
						<FormControl
							id="passwordConfirmTenantUser"
							name="passwordConfirmTenantUser"
							display={"none"}
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenantUser.confirmPassword", {
									_: "Passwort bestätigen"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								variant={"secondary"}
								type="password"
								ref={passwordConfirmRef}
								required
								defaultValue={"000000"}
								autoComplete="off"
								isDisabled
								width={{ base: "100%", md: "250px" }}
							/>
						</FormControl>

						<Flex mt={{ base: 2, md: 2, lg: 0 }}>
							<Button
								type="submit"
								disabled={loading}
								height={"2rem"}
								width={"max-content"}
								px={4}
							>
								{t("AddTenantUser.addTenant", {
									_: "Benutzer Hinzufügen"
								})}
							</Button>
							<DecoratorTriangleButtonResizable
								backgroundColor="btn-primary-hover-bg"
								borderColor="btn-primary-border"
								decoratorTriangleHeight={"2rem"}
								decoratorTriangleWidth={"2.2rem"}
							/>
						</Flex>
					</Flex>
				</form>
			</Box>
		</>
	)
}

export default AddTenantUser
