import React, {
	useEffect,
	useContext,
	useState,
	Suspense,
	useRef,
	lazy
} from "react"
import { ErrorBoundary } from "react-error-boundary"
import WebSerialButtonsErrorFallback from "../../ErrorFallbacks/WebSerialButtonsErrorFallback"

import { PortContext } from "../../../contexts/WebSerialInstanceContext.js"
import progressAtom from "../../../api/jotaiAtoms/progressAtom.js"
import deviceInfoAtom from "../../../api/jotaiAtoms/deviceInfoAtom.js"
import filesListArrayAtom from "../../../api/jotaiAtoms/filesListArrayAtom.js"
import myModule from "../../../utils/FileTransfer/TlvModules/tlvAccessModuleFile.mjs"
import {
	getFile,
	putFile
} from "../../../utils/FileTransfer/TlvAccessProtocol/TlvFiletransfer.js"
import {
	sendDeleteCmd,
	sendSystemDeviceInfoCmd,
	sendDeviceReset,
	sendLsCmd,
	sendChangeDirCmd
} from "../../../utils/FileTransfer/TlvAccessProtocol/TlvAccessCmd.js"

import {
	Flex,
	Button,
	ButtonGroup,
	Box,
	Text,
	Alert,
	AlertIcon,
	AlertTitle,
	Fade
} from "@chakra-ui/react"
import DecoratorTriangleInputSelectResizable from "../../GUI/DecoratorTriangleInputSelectResizable"
import { keyframes } from "@emotion/react"
import { toast } from "react-toastify"
import { compare } from "compare-versions"
import ToastProgressBar from "../../GUI/ToastProgressBar.jsx"
import DecoratorTriangleButtons from "../../GUI/DecoratorTriangleButtons.jsx"
import FilePickerFirmwareUpdate from "./FilePickerFirmwareUpdate.jsx"
import handleMineralisOsFileSelected from "../DashboardHelpers/handleMineralisOsFileSelected.js"
import MineralisOsSelector from "./MineralisOsSelector.jsx"
import LegicOsSelector from "./LegicOsSelector.jsx"
import { AddIcon } from "@chakra-ui/icons"
import AddDeviceToTenantActionModal from "../../GUI/AddDeviceToTenantActionModal.jsx"
// import LogAndTransactionsDrawer from "../Transactions/LogAndTransactionsDrawer.jsx"
//# HOOKS
import { disconnectDevice } from "../../hooks/deviceActions.js"
import { useQuery } from "@tanstack/react-query"
import getTenantNameByTenantId from "../../../components/Auth/firestoreHelpers/getTenantNameByTenantId.js"
import addNewTenantToDeviceOfTenant from "../../../api/fetchHooks/addNewTenantToDeviceOfTenant.js"
//# CONTEXT AND NOW REACTQUERY STORES
import { useConnectionStore } from "../../../api/zustandStores/WebSerialStores/isConnectedStore.js"
import { getParameterData } from "../../../api/fetchHooks/getParameterData.js"
// import { getPcbParameterData } from "../../../api/fetchHooks/getPcbParameterData.js"
// import { getFixedvalueData } from "../../../api/fetchHooks/getFixedvalueData.js"
// import { getFixedvalueDataWithValidFromAndToPcb } from "../../../api/fetchHooks/getFixedvalueDataWithValidFromAndToPcb.js"

//# LOCALIZATION
import { useTranslate } from "react-polyglot"

//# AUTH
import { useAuth } from "../../../contexts/AuthContext"
import useCurrentUserStore from "../../../api/zustandStores/userStore/currentUserStore.js"
import checkOrSetDeviceOfTenantToDB from "../../../api/fetchHooks/checkOrSetDeviceOfTenantToDB.js"

//# WEB SERIAL CONNECTION & STATUS
import { openSerialPort } from "../../../utils/openSerialPort.js"
import {
	handleSerialConnect,
	handleSerialDisconnect
} from "../../hooks/deviceActions.js"

import { files } from "jszip"
import { useAtom } from "jotai"
import isBlockingInteractionAtom from "../../../api/jotaiAtoms/isBlockingInteraction.js"
import getDeviceData from "../../../api/fetchHooks/getDeviceData.js"

//# LAZY LOADING COMPONENTS
const LogAndTransactionsDrawer = lazy(
	() => import("../Transactions/LogAndTransactionsDrawer")
)

//# COMPONENT
const DashboardButtons = (props) => {
	const [port, setPort] = useContext(PortContext)
	const { isConnected, setIsConnected } = useConnectionStore()
	const [isPluggedIn, setIsPluggedIn] = useState(false)
	const [readableStream] = useState(null)
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)
	const [
		allLogOrTransactionFileNamesArray,
		setAllLogOrTransactionFileNamesArray
	] = useAtom(filesListArrayAtom)

	const [isBlockingInteraction, setIsBlockingInteraction] = useAtom(
		isBlockingInteractionAtom
	)

	//# AUTH
	const { userClaims, loadingAuth } = useAuth()
	const isEvisMasterTenant = userClaims?.tenantId === "0000"

	const [deviceOfTenant, setDeviceOfTenants] = useState([])
	// const [maxFirmwareVersion, setMaxFirmwareVersion] = useState(null)
	const [maxFirmwareVersion, setMaxFirmwareVersion] = useState(deviceInfo?.firmwareVersionMajor + ".99.0")
	// const [minFirmwareVersion, setMinFirmwareVersion] = useState(null)
	const [minFirmwareVersion, setMinFirmwareVersion] = useState(deviceInfo?.firmwareVersionMajor + ".0.0")
	const [loading, setLoading] = useState(true)

	//# FETCH DEVICES OF TENANT ON DEVICE CONNECT
	//^ Fetch devices of tenant when component mounts
	//^ the first part is only to show the devices of the tenant in the Dashboard
	//^ the second part is to set the min and maxFirmwareVersion for the device FW update
	//info: Fetch devices of tenant when component mounts, then pass deviceOfTenant, maxFirmwareVersion, and minFirmwareVersion to the MineralisOsSelector component to be available right away
	useEffect(() => {
	

		if (loadingAuth || !userClaims?.tenantId ) {
			// console.warn("Tenant ID is not available yet.")
			// return
		}

		if (deviceInfo && deviceInfo.serialNumber && userClaims.tenantId && loadingAuth === false) {
			getDeviceData(deviceInfo.serialNumber, userClaims, deviceInfo)
				.then((response) => {
					if (response && response.notFound) {
						//^ if device entry exists but no deviceOfTenant entry exists
						checkOrSetDeviceOfTenantToDB(
							deviceInfo.serialNumber,
							userClaims,
							deviceInfo
						)
						// setDeviceOfTenants([])
						// setMaxFirmwareVersion(null)
						// setMinFirmwareVersion(null)
					} else {
						//^ if device entry exists and deviceOfTenant entry exists
						if (response && response?.deviceOfTenant && response?.deviceOfTenant.length !== 0) {
							setDeviceOfTenants(response?.deviceOfTenant)
						} else {
							setDeviceOfTenants([])
						}
						setMaxFirmwareVersion(response?.maxFirmwareVersion || deviceInfo?.firmwareVersionMajor + ".99.0")
						setMinFirmwareVersion(response?.minFirmwareVersion || deviceInfo?.firmwareVersionMajor + ".0.0")
					}
				})
				.catch((error) => {
					console.error("Error fetching devices of tenant:", error)
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			setLoading(false)
		}
	}, [isEvisMasterTenant, deviceInfo, userClaims, userClaims.tenantId])

	//^ update component when maxFirmwareVersion or minFirmwareVersion changes to use in MineralisOsSelector
	useEffect(() => {
	}, [maxFirmwareVersion, minFirmwareVersion])

	const pulse = keyframes`
					0%, 20%, 100% { opacity: 1; }
					50% { opacity: 0.5; }
					`

	//# TRANSLATIONS
	const t = useTranslate()
	//# TOAST PROGRESS BAR FOR FIRMWARE UPLOAD STATUS
	//^ setProgress is callback-function from putFile in TlvFiletransfer.js to update the progress state - onProgress function in putFile gives back the current progress value
	//^ progress is the state to update the progress bar
	//^toast is initialized below, then updated with updsate() function and rerendered with useEffect
	const [progress, setProgress] = useAtom(progressAtom)

	const toastId = useRef(null)
	const toastIdMineralisOsFromDesktop = useRef(null)
	const [currentFileName, setCurrentFileName] = useState(null)

	//# TOAST PROGRESS BAR - toast initialized below (MineralisOs from Desktop)
	const update = () => {
		if (progress < 100) {
			toast.update(toastId.current, {
				type: "info",
				theme: "light",
				limit: 1,

				render: (
					<ToastProgressBar
						progress={progress}
						fileName={currentFileName}
					/>
				)
			})
		} else {
			toast.update(toastId.current, {
				render: (
					<Text>
						{currentFileName}
						{t("Toasts.toastFirmwareSendSuccess", {
							_: " übertragen"
						})}
					</Text>
				),
				autoClose: 3000,
				type: "success",
				theme: "colored",
				isLoading: false,
				hideProgressBar: true,
				limit: 1
			})
			toast.clearWaitingQueue()
		}
	}
	useEffect(() => {
		update()
	}, [progress])
	//^ END TOAST PROGRESS BAR - toast initialized below

	//# GET FW VERSION
	const currentFirmwareVersion = (
		deviceInfo?.firmwareVersionMajor +
		"." +
		deviceInfo?.firmwareVersionMinor +
		"." +
		deviceInfo?.firmwareVersionBeta
	).toString()

	//# rerender when readableStream changes
	useEffect(() => {
		//^ Update the connection status based on the existence of the readable stream.
		readableStream !== null ? setIsConnected(true) : setIsConnected(false)
	}, [readableStream, setIsConnected])

	//# rerender when WebSerial connection data changes
	const checkPortReadable = async (retryCount = 0) => {
		if (port && port.readable) {
			return true
		} else if (retryCount < 9) {
			await new Promise((resolve) => setTimeout(resolve, 300))
			//^ Retry
			checkPortReadable(retryCount + 1)
		} else {
			//^ Port is not open yet
		}
	}

	//# WEB SERIAL CONNECTION RECONNECT
	useEffect(() => {
		//^ port gets delivered from PortContext when coming from established connection from Dashboard.jsx
		const awaitedPortConnection = async () => {
			return await checkPortReadable()
		}

		awaitedPortConnection().then((result) => {
			if (result === true) {
				//^ Port is open already, and ready to use
				if (port.readable.locked === false) {
					//^ stream locked false
					//^ use reader
				} else {
					const reader = port.readable.getReader()
					//^ stream locked true
					reader.releaseLock()
					port.readable
						.getReader()
						.read()
						.then(({ value, done }) => {})
				}
				const deviceInfoAnswer = deviceInfo
				setIsConnected(true)

				const reconnectFunction = async (instance) => {
					myModule()
						.then(async () => {
							const deviceInfoAnswer = deviceInfo
							if(!deviceInfoAnswer) {
								setIsConnected(false)
							} else {
								setDeviceInfo(deviceInfoAnswer)
								setIsConnected(true)
							}
						})
						.then(() => {
							if (port.readable !== null && port.readable.locked === false) {
								setIsConnected(true) //! THIS SET isConnected(true) when navigating from other site
							} else {
								setIsConnected(false)
							}
						})
						.then(() => {
							myModule().then((instance) => {})
						})
				}

				reconnectFunction()
			} else {
				//^ If the port is NOT available.
			}
		})
	}, [
		port,
		// deviceInfo //^ NEEDED FOR isConnected(true) when navigating from other site
		// setDeviceInfo //^ NEEDED FOR isConnected(true) when navigating from other site
		props.defaultIndex
	])

	//# GET DATA FROM REACT QUERY
	//# FETCH PARAMETER DATA
	const {
		data: parameterData,
		dataUpdatedAt,
		error,
		errorUpdatedAt,
		failureCount,
		failureReason,
		fetchStatus,
		isError,
		isFetched,
		isFetchedAfterMount,
		isFetching,
		isInitialLoading,
		isLoading,
		isLoadingError,
		isPaused,
		isPending,
		isPlaceholderData,
		isRefetchError,
		isRefetching,
		isStale,
		isSuccess,
		refetch,
		status
	} = useQuery({
		queryKey: ["parameterData"],
		queryFn: async () => getParameterData(),
		staleTime: process.env.NODE_ENV === "production" ? 1000 * 60 * 5 : 1000 * 3
	})

	//# ON HARDWARE CONNECT
	navigator.serial.onconnect = (event) => {
		handleSerialConnect(
			event,
			setIsConnected,
			setIsPluggedIn,
			openSerialPort,
			toast,
			t
		)
	}

	//# ON HARDWARE DISCONNECT
	navigator.serial.ondisconnect = (event) => {
		handleSerialDisconnect(
			port,
			setIsConnected,
			setIsPluggedIn,
			openSerialPort,
			toast,
			t
		)
	}

	//# FIRMWARE UPDATE FROM DESKTOP
	const handleMineralisOsFileSelected = async (selectedFile) => {
		//^ if device is not connected, show error toast
		if (port === undefined) {
			toast.error(
				t("Toasts.toastReaderConnectionError", {
					_: "Gerät nicht verbunden."
				})
			)
			return
		}
		// eslint-disable-next-line no-unused-vars
		const selectedFile2 = selectedFile
			.arrayBuffer()
			.then((buffer, instance) => {
				try {
					const fileToSendUintArray = new Uint8Array(buffer)

					myModule()
						.then(async (instance) => {
							// eslint-disable-next-line no-unused-vars
							const answer = await sendDeleteCmd(
								instance,
								port,
								"update/" + selectedFile.name
							)

							setCurrentFileName(selectedFile.name)

							//^ Initialize the toast with a pending status
							toastId.current = toast.loading(
								selectedFile.name +
									t("Toasts.toastFirmwareSendPreparing", {
										_: "Firmware wird vorbereitet"
									}),
								{
									type: "info",
									theme: "light",
									limit: 1,
									closeOnClick: true
								}
							)

							//^ progress needs to be reaset to 0 for each file here bc small files will only trigger 100 right away
							//^ setting 0 here forces a rerender, bc the progress bar is only updated when progress changes (and it is already 100 from the last file)
							setProgress(0)
							// eslint-disable-next-line no-unused-vars
							const fileData = putFile(
								instance,
								port,
								"update/" + selectedFile.name,
								true,
								fileToSendUintArray,
								setProgress
							)
						})
						.then(() => {
							//^ if the promise resolved successfully, and the file has been sent console log the message
						})
				} catch (error) {
					console.log(error)
					toast.error(
						t("Toasts.toastFirmwareSendError", {
							_: "Firmwareupdate fehlgeschlagen"
						})
					)
				}
			})
	}

	//# TRANSACTIONS
	const handleFileUploadAndEncode = async (instance, selectedFile, i) => {
		//^ if device is not connected, show error toast
		if (port === undefined) {
			toast.error(
				t("Toasts.toastReaderConnectionError", {
					_: "Gerät nicht verbunden."
				})
			)
			return
		}
		const selectedFileName = selectedFile.split("\x00")[0]
		const selectedFileNameCleaned = selectedFileName.replace("-", "")

		//^ cd into the transactions folder
		async function changeDirectoryFunction() {
			try {
				const answer = await sendChangeDirCmd(instance, port, "/log/public")
			} catch (error) {
				console.error(error)
			}
		}
		changeDirectoryFunction()

		//^ get the file content for each file
		const fileData = await toast.promise(
			getFile(instance, port, selectedFileNameCleaned),
			{
				pending: t("Toasts.toastLogFilePending", {
					_: "Log Datei wird heruntergeladen."
				}),
				success: t("Toasts.toastReadLogFileSuccess", {
					_: "Log Datei erfolgreich heruntergeladen."
				}),
				error: t("Toasts.toastReadLogFileError", {
					_: "Log Datei konnte nicht heruntergeladen werden."
				})
			}
		)
		toast.clearWaitingQueue()
		// console.log("FILE SENT SUCCESSFULLY")
		const fileToDownload = new Blob([fileData], {
			type: "text/plain"
		})
		const linkToLogFile = document.createElement("a")
		linkToLogFile.href = window.URL.createObjectURL(fileToDownload) //^ needs to be revoked later or it will block or leak memory
		linkToLogFile.download = "logfile.txt"
		linkToLogFile.click()
	}

	//# GET ALL FILE Names FROM THE DEVICE RECURSIVELY WITH sendLsCmd
	let filesList = []
	async function getAllFiles(instance, port, currentOffset = 0) {
		const answer = await sendLsCmd(instance, port, currentOffset)
		if (answer === undefined) {
			console.log("ERROR GETTING FILES FROM DEVICE")
			return
		}
		filesList.push(...answer.list)
		if (answer.list.length === 20) {
			currentOffset += 20
			await getAllFiles(instance, port, currentOffset)
		} else {
			return filesList
		}
		return filesList
	}

	//# START OF TENANT DEVICE ASSIGNMENT AND MANAGEMENT
	//# GET TENANT NAME BY TENANT ID and add to device of tenant array
	//# Add new tenant to device of tenant state
	const [showInput, setShowInput] = useState(false)
	const [newTenantId, setNewTenantId] = useState("")
	const [newTenantName, setNewTenantName] = useState("")

	//^ tenantId lookup when typing 4 digits distID into field
	const handleTenantIdChange = (e) => {
		const value = e.target.value.replace(/\D/g, "").slice(0, 4)
		setNewTenantId(value)

		if (value.length === 4) {
			getTenantNameByTenantId(value.toString()).then((tenantName) => {
				setNewTenantName("- " + tenantName)
			})
		} else {
			setNewTenantName("")
		}
	}

	//^ Add device to tenant as evisMastertenant & evisAdmin from deviceInfo panel
	const handleAddDeviceToTenant = () => {
		if (deviceOfTenant.includes(newTenantId)) {
			// Tenant has already claimed the device
			toast.warning(
				t("Toasts.toastTenantAlreadyClaimed", {
					_: "Distributor bereits zugewiesen"
				})
			)
		} else {
			// New tenant, add to the device
			addNewTenantToDeviceOfTenant(newTenantId, deviceInfo.serialNumber)
			toast.info(
				t("Toasts.toastAddedDeviceToTenant", {
					_: "Gerät zu Disti hinzugefügt"
				})
			)
			setDeviceOfTenants((prevTenants) => [...prevTenants, newTenantId])
		}
	}

	if (isLoading || loadingAuth)
		return (
			<div style={{ marginTop: "18px", marginLeft: "10px" }}>
				{localStorage.getItem("locale") === "en" ? (
					<Text animation={`${pulse} 1.5s infinite`}>Loading</Text>
				) : localStorage.getItem("locale") === "fr" ? (
					<Text animation={`${pulse} 1.5s infinite`}>Chargent</Text>
				) : (
					<Text animation={`${pulse} 1.5s infinite`}>Loading</Text>
				)}
			</div>
		)
	if (isError) return <div>Error fetching data</div>

	//# RENDER
	return (
		// #TabNavigation and Connection Status
		<Box>
			{/* //# BUTTONS */}
			<Flex
				alignItems="start"
				gap="4"
				width={{ base: "100%", md: "100%", lg: "1200px" }}
				mt={"4"}
				mb="-2"
				pr={{ base: "0", md: "4" }}
				flexWrap="wrap"
			>
				<ErrorBoundary FallbackComponent={WebSerialButtonsErrorFallback}>
					<ButtonGroup>
						{isConnected ? (
							<>
								<Flex display={{ base: "flex", md: "flex" }}>
									<Button
										variant="statusConnected"
										id="disconnectButton"
										aria-label="disconnect serial device"
										minW="auto"
										onClick={() =>
											disconnectDevice(
												port,
												setIsConnected,
												openSerialPort
											).then(() =>
												toast.info(
													t("Toasts.toastReaderDisconnected", {
														_: "Gerät getrennt."
													})
												)
											)
										}
										//!DEBUG - what is it for?
										onMouseUp={() =>
											props.defaultIndex !== 1 ? setDeviceInfo(null) : null
										}
									>
										{/*//# Gerät verbunden */}
										{t("DashboardButtons.readerConnected", {
											_: "Gerät verbunden"
										})}
									</Button>
									<DecoratorTriangleButtons
										pl="4"
										ml="4"
										backgroundColor="btn-connected-bg"
										borderColor="btn-connected-border"
									/>
								</Flex>
							</>
						) : (
							<>
								<Flex display={{ base: "flex", md: "flex" }}>
									<Button
										variant="statusDisconnected"
										id="connectButton"
										aria-label="connect serial device"
										minW="auto"
										_hover={{color: "btn-disconnected-hover-bg"}}
										onClick={() =>
											openSerialPort(
												setPort,
												setIsConnected,
												setDeviceInfo,
												t,
												userClaims,
												loadingAuth
											)
										}
									>
										{/*//# Gerät verbinden */}
										{t("DashboardButtons.connectReader", {
											_: "Gerät verbinden"
										})}
									</Button>
									<DecoratorTriangleButtons
										pl="4"
										ml="4"
										backgroundColor="btn-disconnected-bg"
										borderColor="btn-disconnected-border"
									/>
								</Flex>
							</>
						)}
					</ButtonGroup>
				</ErrorBoundary>
				{/* //# OPEN THE SERIAL PORT */}
				{/* //^ if connected, show the buttons (log or transactions, restrt mineralisOS, LegicOS) */}
				{isConnected && deviceInfo ? (
					<>
						<Flex
							alignItems="start"
							gap="4"
							width={{ base: "auto", md: "auto", lg: "auto" }}
							mb="-2"
							pb={"0"}
							pr={{ base: "0", md: "4" }}
							flexWrap="wrap"
						>
							{/* //^ check if Firmware is >= 0.5.0, render Button readTLV if true, render Button readXML if false  */}
							{/* {deviceInfo && */}
							{compare(
								deviceInfo?.firmwareVersionMajor +
									"." +
									deviceInfo?.firmwareVersionMinor +
									"." +
									deviceInfo?.firmwareVersionBeta,
								"3.0.0", //TODO: change to 3.0.0
								"<"
							) ? (
								<>
									{/* //# LOG DOWNLOAD */}
									<Flex>
										<Button
											aria-label="read log from device and download"
											minW="auto"
											onClick={async (xmlString, parameterData, instance) => {
												setIsBlockingInteraction(true)
												try {
													myModule()
														.then(async (instance) => {
															setCurrentFileName("Log ")
															//^ Initialize the toast with a pending status
															toastId.current = toast.loading(
																t("Toasts.toastLogDownloadPreparing", {
																	_: "Log Datei wird vorbereitet"
																}),
																{
																	type: "info",
																	theme: "light",
																	limit: 1,
																	closeOnClick: true
																}
															)
															//^ progress needs to be reaset to 0 for each file here bc small files will only trigger 100 right away
															//^ setting 0 here forces a rerender, bc the progress bar is only updated when progress changes (and it is already 100 from the last file)
															setProgress(0)
															const fileData = await getFile(
																instance,
																port,
																"log/logfile_1.txt",
																setProgress
															)
															// console.log("FILE SENT SUCCESSFULLY")
															const fileToDownload = new Blob([fileData], {
																type: "text/plain"
															})
															const linkToLogFile = document.createElement("a")
															linkToLogFile.href =
																window.URL.createObjectURL(fileToDownload) //^ needs to be revoked later or it will block or leak memory
															linkToLogFile.download = "logfile.txt"
															linkToLogFile.click()
														})
														.then(() => {
															//^ toast.success("LogFile bereit zum Download.")
															setIsBlockingInteraction(false)
														})
												} catch (error) {
													console.log(error)
												} finally {
												}
											}}
										>
											{/* //# Log Download */}
											{t("DashboardButtons.logDownload", {
												_: "Log download"
											})}
										</Button>
										<DecoratorTriangleButtons
											backgroundColor="btn-primary-hover-bg"
											borderColor="btn-primary-border"
										/>
									</Flex>
								</>
							) : (
								<>
									{/* //# LOG AND TRANSACRTIONS READ FROM DEVICE, RENDER LIST AND DOWNLOAD & DELETE */}
									<Flex>
										<LogAndTransactionsDrawer
											logType="log"
											aria-label="read all log or transaction files from device (depending on logType of Button)"
											minW="auto"
											backgroundColor="btn-primary-bg"
											borderColor="btn-primary-border"
											onClick={async (instance) => {
												try {
													setIsBlockingInteraction(true)
													myModule()
														.then(async (instance) => {
															//^ go to the transactions folder
															await sendChangeDirCmd(
																instance,
																port,
																// "/"
																"/log"
															)
															await getAllFiles(instance, port)
																.then(async (answer) => {
																	//^ get the list of files in the transactions folder
																	//^ THIS READS filenames ONLY at first for gui list render and selection
																	//^ filter out the hidden files and return to upper folder entries
																	const filesList = answer.filter(
																		(file) =>
																			typeof file === "string" &&
																			!file.startsWith("-.") && // This filters hidden files
																			!file.startsWith("-dummy")
																	)

																	return filesList
																})

																.then((filesList) => {
																	//^ set the allLogOrTransactionFileNamesArray to jotai atom and use it in the sideview drawer to render a list of files
																	setAllLogOrTransactionFileNamesArray(
																		filesList
																	)
																})
														})
														.then(() => {})
												} catch (error) {
													setIsBlockingInteraction(false)
													console.log(error)
												} finally {
													setIsBlockingInteraction(false)
												}
											}}
										>
											{/* Button is inside the component */}
										</LogAndTransactionsDrawer>

										{/* //# TRANSACTIONS READ FROM DEVICE, RENDER LIST AND DOWNLOAD & DELETE */}

										<LogAndTransactionsDrawer
											logType="transactions"
											aria-label="read all log or transaction files from device (depending on logType of Button)"
											minW="auto"
											backgroundColor="btn-primary-bg"
											borderColor="btn-primary-border"
											onClick={async (instance) => {
												try {
													setIsBlockingInteraction(true)

													myModule()
														.then(async (instance) => {
															//^ go to the transactions folder
															await sendChangeDirCmd(
																instance,
																port,
																"/log/public"
															)
															await getAllFiles(instance, port)
																.then(async (answer) => {
																	//^ get the list of files in the transactions folder
																	//^ THIS READS filenames ONLY at first for gui list render and selection
																	//^ filter out the hidden files and return to upper folder entries
																	const filesList = answer.filter(
																		(file) =>
																			typeof file === "string" &&
																			!file.startsWith("-.") && // This filters hidden files
																			!file.startsWith("-dummy") // This filters files starting with "dummy"
																	)
																	return filesList
																})

																.then((filesList) => {
																	//^ set the allLogOrTransactionFileNamesArray to jotai atom and use it in the sideview drawer to render a list of files
																	setAllLogOrTransactionFileNamesArray(
																		filesList
																	)
																})
														})
														.then(() => {})
												} catch (error) {
													setIsBlockingInteraction(false)

													console.log(error)
												} finally {
													setIsBlockingInteraction(false)
												}
											}}
										>
											{/* Button is inside the component */}
										</LogAndTransactionsDrawer>

										<DecoratorTriangleButtons
											backgroundColor="btn-primary-hover-bg"
											borderColor="btn-primary-border"
										/>
									</Flex>
								</>
							)}
							{/* //# DEVICE RESET BUTTON */}
							<Flex>
								<Button
									onClick={() => {
										myModule().then(async (instance) => {
											setDeviceInfo(null)
											let answer = await sendDeviceReset(instance, port, 1000)
											toast.info(
												t("Toasts.toastReaderRestart", {
													_: "Gerät wird neu gestartet"
												})
											)
											setTimeout(() => {
												setIsConnected(false)
											}, 1000)

											toast.clearWaitingQueue()
										})
									}}
								>
									{/* //# Gerät Neustart */}
									{t("DashboardButtons.restartReader", {
										_: "Gerät Neustart"
									})}
								</Button>
								<DecoratorTriangleButtons
									backgroundColor="btn-primary-hover-bg"
									borderColor="btn-primary-border"
								/>
							</Flex>
							{/* //# FIRMWARE UPDATE BUTTON - MINERALISOS */}
							<Flex>
								<Button
									maxWidth={"max-content"}
									px={0}
									aria-label="Firmware update from desktop"
									background={"button-secondary-active-bg"}
								>
									<FilePickerFirmwareUpdate
										onFileSelected={handleMineralisOsFileSelected}
									/>
								</Button>
								<ErrorBoundary
									fallback={
										<Alert
											maxHeight={"max-content"}
											py={1}
											status="error"
											colorScheme="red"
											maxWidth={"max-content"}
										>
											<AlertIcon />
											<AlertTitle>Mineralis OS Error</AlertTitle>
										</Alert>
									}
								>
									<Suspense fallback={<div>Loading</div>}>
										<MineralisOsSelector
											deviceOfTenant={deviceOfTenant}
											maxFirmwareVersion={maxFirmwareVersion}
											minFirmwareVersion={minFirmwareVersion}
										/>
									</Suspense>
								</ErrorBoundary>
							</Flex>

							{/* //# OS UPDATE BUTTON - LEGICOS */}
							{deviceInfo.hardwarePrintNr !== 6001 ? (
								<Flex>
									<ErrorBoundary
										fallback={
											<Alert
												maxHeight={"max-content"}
												py={1}
												status="error"
												colorScheme="red"
												maxWidth={"max-content"}
											>
												<AlertIcon />
												<AlertTitle>Legic OS Error</AlertTitle>
											</Alert>
										}
									>
										<Suspense fallback={<div>Loading</div>}>
											<LegicOsSelector />
										</Suspense>
									</ErrorBoundary>
								</Flex>
							) : null}
						</Flex>
					</>
				) : //^ if not connected, don't show buttons
				null}
				{/* //# DEVICE INFO */}
				{!isConnected ? (
					<Box
						paddingBottom={"3.6rem"}
						opacity={"0"}
					>
						HIDDEN SPACEHOLDER
					</Box>
				) : (
					isConnected &&
					deviceInfo &&
					deviceOfTenant && (
						<Fade
							in={isConnected}
							transition={{
								enter: { delay: 0.3, duration: 0.5 }
							}}
						>
							<Flex
								alignItems="start"
								gap="4"
								width={{
									base: "100%",
									md: "100%",
									lg: "800px",
									xl: "auto"
								}}
								mb="4"
								pr={{ base: "0", md: "" }}
								flexWrap="wrap"
							>
								<Text
									as="b"
									mt={"0"}
								>
									{/* //^ Firmware */}
									{t("DashboardButtons.deviceInfoFirmware", {
										_: "Firmware:"
									})}{" "}
									{deviceInfo.firmwareVersionMajor}.
									{deviceInfo.firmwareVersionMinor}.
									{deviceInfo.firmwareVersionBeta} (FW
									{deviceInfo.firmwareNumber})
								</Text>
								<Text> | </Text>
								<Text as="b">
									{/* //^ SerialNr:  */}
									{t("DashboardButtons.deviceInfoSerialNumber", {
										_: "SerienNr:"
									})}{" "}
									{deviceInfo.serialNumber}
								</Text>
								<Text> | </Text>
								<Text as="b">
									{/* //^ PrintNr.: */}
									{t("DashboardButtons.deviceInfoPrintNumber", {
										_: "PrintNr.:"
									})}{" "}
									{deviceInfo.hardwarePrintNr}.{deviceInfo.hardwarePrintVersion}
								</Text>
								<Text> | </Text>
								<Text as="b">
									{/* //^ Variante: */}
									{t("DashboardButtons.deviceInfoHardwareVariant", {
										_: "Variante:"
									})}{" "}
									{deviceInfo.hardwareAssemblyVariant}.
									{deviceInfo.hardwareAssemblyVersion}
								</Text>

								{isEvisMasterTenant ? (
									<>
										<Text> | </Text>
										<Text as="b">
											{t("DashboardButtons.deviceOfTenant", {
												_: "Gerät von Distributor:"
											})}{" "}
											{deviceOfTenant.map((device, index) => (
												<span key={index}>
													{device}
													{index < deviceOfTenant.length - 1 ? " & " : ""}
												</span>
											))}
										</Text>

										<AddDeviceToTenantActionModal
											title={t("AddDeviceToTenantActionModal.title", {
												_: "Gerät zu Disti hinzufügen"
											})}
											tooltipLabel={t(
												"DashboardButtons.addDeviceToTenantTooltip",
												{
													_: "Gerät zu Disti hinzufügen"
												}
											)}
											icon={
												<>
													<AddIcon
														paddingY="1px"
														paddingLeft="1px"
														marginLeft={2}
														marginRight={"-5px"}
													/>
													<DecoratorTriangleInputSelectResizable
														backgroundColor={"bg-default"}
														decoratorTriangleHeight={"22px"}
														decoratorTriangleWidth={"22px"}
														decoratorTriangleMl={"2"}
													/>
												</>
											}
											confirmType="addDeviceToTenant"
											newTenantId={newTenantId}
											newTenantName={newTenantName}
											setShowInput={setShowInput}
											setNewTenantName={setNewTenantName}
											setNewTenantId={setNewTenantId}
											handleTenantIdChange={handleTenantIdChange}
											onConfirm={handleAddDeviceToTenant}
										/>
									</>
								) : deviceOfTenant.length > 0 ? (
									<>
										<Text> | </Text>
										<Text as="b">
											{t("DashboardButtons.deviceOfTenant", {
												_: "Gerät von Distributor:"
											})}{" "}
											{deviceOfTenant.map((device, index) => (
												<span key={index}>
													{device}
													{index < deviceOfTenant.length - 1 ? " & " : ""}
												</span>
											))}
										</Text>
									</>
								) : null}
							</Flex>
						</Fade>
					)
				)}
			</Flex>
		</Box>
	)
}

export default DashboardButtons
