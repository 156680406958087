// client/src/components/Auth/LogIn.jsx
import React, { useRef, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
	Link as ChakraLink,
	Button,
	Card,
	CardHeader,
	CardBody,
	Input,
	Heading,
	Box,
	FormControl,
	FormLabel,
	Alert
} from "@chakra-ui/react"
import { useAuth } from "../../../contexts/AuthContext"
import { useTranslate } from "react-polyglot"

const ForgotPassword = () => {
	const t = useTranslate()
	const emailRef = useRef()

	const { resetPassword, currentUser } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState("")
	// const navigate = useNavigate()

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setMessage("")
			setError("")
			setLoading(true)
			await resetPassword(emailRef.current.value)
			setMessage("Check your email for further instructions")
		} catch {
			setError("Failed to reset password")
		}
		setLoading(false)
	}

	return (
		<Card>
			<CardHeader pb={0}>
				<Heading size={"lg"}>
					{t("ForgotPassword.passwordReset", { _: "Passwort vergessen" })}
				</Heading>
			</CardHeader>
			<CardBody>
				{message && (
					<Alert
						mb={4}
						status="success"
						color={"white"}
						backgroundColor={"evisgreen"}
						maxW={"max-content"}
					>
						{message}
					</Alert>
				)}
				{error && (
					<Alert
						mt={4}
						status="error"
						color={"white"}
						backgroundColor={"#A63C32"}
					>
						{error}
					</Alert>
				)}
				<form onSubmit={handleSubmit}>
					<FormControl id="email">
						<FormLabel mb={0}>Email</FormLabel>
						<Input
							variant="secondary"
							placeholder={t("InputPlaceholders.placeholderEmail", {
								_: "MaxMustermann@email.ch"
							})}
							type="email"
							ref={emailRef}
							required
						/>
					</FormControl>

					<Button
						type="submit"
						disabled={loading}
						mt={4}
						size={"lg"}
						width={"100%"}
						height={"42px"}
					>
						{t("ForgotPassword.passwordResetButton", {
							_: "Passwort zurücksetzen"
						})}
					</Button>
				</form>
				{/* //# LINK TO RESET PASSWORD */}
				<Box mt={2}>
					<ChakraLink
						as={ReactRouterLink}
						to="/login"
						color="blue.500"
						fontSize={"sm"}
					>
						{t("ForgotPassword.backToLogin", { _: "Zurück zum Login" })}
					</ChakraLink>
				</Box>
			</CardBody>
		</Card>
	)
}

export default ForgotPassword
