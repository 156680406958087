import React, { useContext, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import WebSerialButtonsErrorFallback from "../ErrorFallbacks/WebSerialButtonsErrorFallback"
import { openSerialPort } from "../../utils/openSerialPort.js"
import { useProjects } from '../../api/fetchHooks/useProjects'; // Import the useProjects hook

import myModule from "../../utils/FileTransfer/TlvModules/tlvAccessModuleFile.mjs"
import { sendSystemDeviceInfoCmd } from "../../utils/FileTransfer/TlvAccessProtocol/TlvAccessCmd.js"
import { PortContext } from "../../contexts/WebSerialInstanceContext.js"
import { useAtom } from "jotai"
import deviceInfoAtom from "../../api/jotaiAtoms/deviceInfoAtom.js"
import { useConnectionStore } from "./../../api/zustandStores/WebSerialStores/isConnectedStore.js"

import { Box, Center } from "@chakra-ui/react"
import DashboardButtons from "../Dashboard/Buttons/DashboardButtons"
import LogoWhite from "../GUI/images/logo/logoWhiteIconSVG.svg"

import TabNav from "../Navigation/TabNav/TabNav"
import { disconnectDevice } from "../hooks/deviceActions.js"
import { useTranslate } from "react-polyglot"
import isBlockingInteractionAtom from "../../api/jotaiAtoms/isBlockingInteraction.js"

import Overlay from "../GUI/Overlay.jsx"
import DevicesTable from "./DevicesTable/DevicesTable.jsx"
import ReusableBadgeHeading from "../GUI/ReusableBadgeHeading.jsx"
import { useAuth } from "../../contexts/AuthContext"
const Dashboard = () => {
	const t = useTranslate()

	//# set contextState for port and deviceInfo
	let [port, setPort] = useContext(PortContext)
	const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoAtom)
	const { isConnected, setIsConnected } = useConnectionStore()
	const [isBlockingInteraction, setIsBlockingInteraction] = useAtom(
		isBlockingInteractionAtom
	)
	const { userClaims } = useAuth()

	// Fetch projects data
	const tenantId = userClaims?.tenantId || "";
	// console.log("tenantId:", tenantId)
	// const { data: projectsData, isLoading: projectsLoading } = useProjects(tenantId);
	// console.log("projectsData:", projectsData)

	

	return (
		<>
			<Box>
				{isBlockingInteraction && <Overlay />}
				{/* //# TabNavigation and Connection Status */}
				<Box
					// position={"fixed"}
					mr={{ base: "1rem", md: "5.5rem" }}
					paddingLeft={{ base: "2rem", md: "1.7rem", lg: "3.5rem" }}
					marginLeft={{ base: "1rem", md: "2rem", lg: "2rem" }}
					marginTop={"5.6rem"}
					marginBottom={"0"}
					pt={"75px"} //^ FOR MINERALIS HEADER
				>
					<TabNav
						mt={"550px"}
						defaultIndex={0}
						isConnected={isConnected}
						setIsConnected={setIsConnected}
						disconnectDevice={disconnectDevice}
						port={port}
						// openSerialPort={openSerialPort}
					/>

					<ErrorBoundary FallbackComponent={WebSerialButtonsErrorFallback}>
						<Suspense fallback={<div>Loading</div>}>
							<DashboardButtons defaultIndex={0} />
						</Suspense>
					</ErrorBoundary>
				</Box>

				{/* //# DEVICES TABLE */}
				{/* {!userClaims || !userClaims.provider_id === "anonymous" || !userClaims.isGuest === true || !userClaims.role === "guest" ? ( */}
				{userClaims && (userClaims.role === "evisAdmin" || userClaims.role === "tenantAdmin" || userClaims.role === "user") ? (
					<Box
						ml={{ base: "2rem", md: "5.5rem", lg: "5.5rem" }}
						mt={"1.6em"}
					>
						<ReusableBadgeHeading
							position={"relative"}
							ml={"2rem"}
							pl={"2rem"}
							badgeTitle={t("Dashboard.devicesTableBadgeTitle", {
								_: "Geräteverwaltung"
							})}
							badgeItem={
								// projectsData && (
									<DevicesTable
										maxWidth="80%"
										// projectsData={projectsData} // Pass projectsData as a prop
										// projectsLoading={projectsLoading} // Pass loading state if needed
									/>
								// )
							}
						/>
					</Box>
				) : (
					<></>
				)}

				{/* <DevicesTable
					maxWidth="80%"
					ml={"2rem"}
				/> */}

				{/* //# EVIS BG IMAGE */}
				{/* <Center>
					<Box
						bgImage={LogoWhite}
						backgroundPosition="center"
						backgroundSize="cover"
						backgroundRepeat="no-repeat"
						mt={"38vh"}
						height={"400px"}
						width={"400px"}
						opacity={0.4}
						zIndex={-1}
					></Box>
				</Center> */}
			</Box>
		</>
	)
}

export default Dashboard
