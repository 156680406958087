import { initializeApp } from "firebase/app"
import {
	getAuth,
	EmailAuthProvider,
	GoogleAuthProvider,
	// signInAnonymously as SignInAnonymously
	signInAnonymously as firebaseSignInAnonymously
	// signInAnonymously
} from "firebase/auth"
import { getFirestore } from "firebase/firestore"

//# web app's Firebase configuration
//info: frontend envs are not injected by azure at build time (like server envs),
//info: they would need to be fetched from backend at runtime
const firebaseConfig = {
	// apiKey: process.env.REACT_APP_FIREBASE_API_KEY
	apiKey: "AIzaSyDfG7aVdeqVi0_8Ci5hAHHYKM-6qJbZ1qo",
	// authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	authDomain: "evis----firebase-apps.firebaseapp.com",
	// projectId: process.env.REACT_APP_PROJECTID,
	projectId: "evis----firebase-apps",
	// storageBucket: process.env.REACT_APP_STORAGEBUCKET,
	storageBucket: "evis----firebase-apps.appspot.com",
	// messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
	messagingSenderId: "1076026518304",
	// appId: process.env.REACT_APP_APPID,
	appId: "1:1076026518304:web:63aeefca2cd0e6aa964545",
	// measurementId: process.env.REACT_APP_MEASUREMENTID
	measurementId: "G-8N98DR243Y"
}

//# Initialize Firebase
const app = initializeApp(firebaseConfig)

//# Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
export const emailAuthProvider = EmailAuthProvider
export const googleAuthProvider = GoogleAuthProvider
// export const signInAnonymously = SignInAnonymously
export const signInAnonymously = firebaseSignInAnonymously

//# Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

export { app, db }
// export default app
