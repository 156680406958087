import React from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../../../contexts/AuthContext"
import DotSpinner from "../../GUI/DotSpinner"
export default function PrivateRoute({ element, requiredRole }) {
	const { userClaims, loadingAuth } = useAuth()
	
	//! wait for userClaims to load
	if (loadingAuth) {
		return <DotSpinner />
	}

	if (!userClaims) {
		return <Navigate to="/login" /> 

	}

	if (requiredRole) {
		const userRole = userClaims.role
		const isAllowed = Array.isArray(requiredRole)
			? requiredRole.includes(userRole)
			: userRole === requiredRole

		if (!isAllowed) {
			return <Navigate to="/" />
		}
	}

	return element
}
