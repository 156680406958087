import axios from "axios"

export const getFirmwareReleasesForDeviceFirmwareNumber = async (
	firmwareNumber,
	maxFirmwareVersion,
	minFirmwareVersion
) => {
	try {
		const url = "/firmwareReleasesForDeviceFirmwareNumber"
		const response = await axios.post(url, {
			firmwareNumber,
			maxFirmwareVersion,
			minFirmwareVersion
		})
		return response.data
	} catch (error) {
		console.log(error)
		return error
	}
}
