// import logo from "./logo.svg";
// import { ColorModeScript } from "@chakra-ui/react";

import { lazy, Suspense, useEffect, useState } from "react"
//# REACT QUERY
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { DevTools } from "jotai-devtools"
import { useLocation } from "react-router-dom"

import "react-toastify/dist/ReactToastify.min.css"
import "./App.css"
import "./index.css"
import "./toastify.css"

import {
	ChakraProvider,
	CSSReset,
	Container,
	Box,
	Flex,
	Text,
	useColorMode
} from "@chakra-ui/react"
// import { keyframes } from "@emotion/react"
import { keyframes } from "@emotion/react"

import { ToastContainer } from "react-toastify"
import theme_mineralis from "./theme.js"
import Fonts from "./components/FontsComponent/Fonts"
import "./components/FontsComponent/fonts/DIN_bold.ttf"
import "./components/FontsComponent/fonts/DIN_condensed_bold.ttf"
//# LOCALIZATION
import { I18n } from "react-polyglot"
import useLocalizationStore from "./api/zustandStores/localizationStore/localizationStore.js"

import ParameterStore from "./contexts/ParameterStore"
import DocFolderStore from "./contexts/DocFolderStore"
import FolderStore from "./contexts/FolderStore"
import ConditionStore from "./contexts/ConditionStore"
import DocParameterStore from "./contexts/DocParameterStore"
// import FixedvalueStore from "./contexts/FixedvalueStore"
import { AuthProvider } from "./contexts/AuthContext"
import PcbStore from "./contexts/PcbStore.js"
import PortProvider from "./contexts/PortProvider"
import WebSerialInstanceProvider from "./contexts/WebSerialInstanceProvider"
import DeviceInfoProvider from "./contexts/DeviceInfoProvider"
import ConnectionProvider from "./contexts/ConnectionProvider"
import IsValidProvider from "./contexts/IsValidProvider.js"
import myModule from "../src/utils/FileTransfer/TlvModules/tlvAccessModuleFile.mjs"

//# PAGES AND ROUTES
import { Routes, Route } from "react-router-dom"
import Layout from "./pages/Layout"
import PageDashboard from "./pages/PageDashboard"
import PageUserProfile from "./pages/PageUserProfile"
import PageUserManagement from "./pages/PageUserManagement"
import PageLogIn from "./pages/PageLogIn.jsx"
import PageForgotPassword from "./pages/PageForgotPassword.jsx"
import PageResetPasswordHandler from "./pages/PageResetPasswordHandler.jsx"
// import PageUpdatePassword from "./pages/PageUpdatePassword.jsx"
import PageSignUp from "./pages/PageSignUp.jsx"

import { ErrorBoundary } from "react-error-boundary"

import PageParameterEditor from "./pages/PageParameterEditor"
import DotSpinner from "./components/GUI/DotSpinner.jsx"
import PrivateRoute from "./components/Auth/Login/PrivateRoute.jsx"

//# DEFERRED IMPORTS
const PageItemAndPriceList = lazy(() => import("./pages/PageItemAndPriceList"))
const PageKeyManagement = lazy(() => import("./pages/PageKeyManagement"))
const Page404 = lazy(() => import("./pages/Page404"))
const ScrollToTop = lazy(() => import("./components/GUI/ScrollToTop"))
const ResetPasswordHandler = lazy(() => import("./components/Auth/UserManagement/ResetPassWordHandler"))
const pulse = keyframes`
  0%, 20%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`

//# Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			//# globally default to 2 minutes (override in individual queries)
			// staleTime: 1000 * 30 * 1
			// refetchOnWindowFocus: false,
			// refetchOnMount: false,
			// refetchOnReconnect: false,
			// refetchInterval: false,
			// enabled: true,
			// retry: 1,
			// retryDelay: 1000,
			// retryOnMount: false,
			// retryOnReconnect: false,
			// retryOnFocus: false,
			// refetchIntervalInBackground: false,
		}
	}
})

function App() {
	const location = useLocation()
	const { locale, setLocale } = useLocalizationStore()
	const { colorMode, toggleColorMode, setColorMode } = useColorMode()

	//# LANGUAGE SETTINGS
	const changeLanguage = (language) => {
		//# set localStorage
		localStorage.setItem("locale", language)
		//# set zustand state
		setLocale(language)
	}

	//# TOGGLE LANGUAGE SHORTCUT "Ctrl + I" GLOBAL EVENT LISTENER
	const handleKeyDown = (event) => {
		if (!event.ctrlKey) return // Only proceed if Ctrl key is pressed

		if (event.key.toLowerCase() === "i") {
			const languages = ["de", "fr", "en"]
			const currentIndex = languages.indexOf(locale)
			const nextIndex = (currentIndex + 1) % languages.length
			changeLanguage(languages[nextIndex])
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown)
		return () => {
			document.removeEventListener("keydown", handleKeyDown)
		}
	}, [locale, changeLanguage])

	//# TOGGLE THEME SHORTCUT "Ctrl + m" GLOBAL EVENT LISTENER
	const handleToggleTheme = (event) => {
		if (!event.ctrlKey) return // Only proceed if Ctrl key is pressed

		if (event.key.toLowerCase() === "m") {
			toggleColorMode()
			const newTheme = colorMode === "light" ? "dark" : "light"
			localStorage.setItem("theme", newTheme)
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", handleToggleTheme)
		return () => {
			document.removeEventListener("keydown", handleToggleTheme)
		}
	}, [colorMode, toggleColorMode])

	//# PAGE STYLE
	const pageStyle =
		location.pathname === "/" ? { height: "100vh", overflow: "hidden" } : {}

	//# TRANSLATIONS
	// const locale = useLocalizationStore().locale
	const [messages, setMessages] = useState({})
	const url =
		process.env.NODE_ENV === "production"
			? `/translations/${locale}.json`
			: `http://localhost:3001/translations/${locale}.json`

	//# TOGGLE TRANSLATIONS WITH KEYBOARD SHORTCUT CTRL + i
	useEffect(() => {
		async function fetchData() {
			const response = await fetch(url)
			if (!response.ok) {
				console.log("Error fetching translations")
				return
			}
			const data = await response.json()
			setMessages(data)
		}
		fetchData()
	}, [locale, url])

	const localeFromLocalStorage = localStorage.getItem("locale") || "de"

	//^ wait for messages before rendering
	if (messages === undefined) {
		return (
			<Text>
				{localStorage.getItem("locale") === "en" ? (
					<Text animation={`${pulse} 1.5s infinite ease-in-out`}>
						Loading data
					</Text>
				) : localStorage.getItem("locale") === "fr" ? (
					<Text animation={`${pulse} 1.5s infinite ease-in-out`}>
						Les données se chargent
					</Text>
				) : (
					<Text animation={`${pulse} 1.5s infinite ease-in-out`}>
						Daten werden geladen
					</Text>
				)}
			</Text>
		)
	}

	return (
		<Box
			className="AppBox"
			style={pageStyle}
		>
			<DevTools /> {/* <ReactQueryDevtools initialIsOpen={false} /> */}
			{/* <ChakraProvider theme={theme_mineralis}> */}
			<Fonts />
			<CSSReset />
			<Container
				className="AppContainer"
				width="100vw"
				minWidth="100vw"
				height="100%"
				px={0}
				mx={0}
			>
				<I18n
					locale={locale}
					messages={messages}
				>
					<ErrorBoundary fallback={<Page404 />}>
						<>
							<QueryClientProvider client={queryClient}>
								<WebSerialInstanceProvider>
									<PortProvider>
										<DeviceInfoProvider>
											<ConnectionProvider>
												<ParameterStore>
													{/* <FolderStore> */}
													<FolderStore>
														<DocFolderStore>
															<ConditionStore>
																<DocParameterStore>
																	{/* <FixedvalueStore> */}
																	<PcbStore>
																		<IsValidProvider>
																			<AuthProvider>
																				<Routes>
																					<Route
																						path="/login"
																						element={
																							<Suspense
																								fallback={<DotSpinner />}
																							>
																								<PageLogIn />
																							</Suspense>
																						}
																					/>
																					<Route
																						path="/"
																						element={<Layout />}
																					>
																						<Route
																							path="/signup"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<PageSignUp />
																								</Suspense>
																							}
																						/>
																						{/* <Route
																								path="/signupTenantUser"
																								element={
																									<Suspense
																										fallback={<DotSpinner />}
																									>
																										<PageSignUp />
																									</Suspense>
																								}
																							/> */}
																						{/* <Route
																								path="/login"
																								element={
																									<Suspense
																										fallback={<DotSpinner />}
																									>
																										<PageLogIn />
																									</Suspense>
																								}
																							/> */}

																						{/* //# Custom action URL PW-reset */}
																						<Route
																							path="/reset-password"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<PageResetPasswordHandler />
																								</Suspense>
																							}
																						/>
																						{/* //# In-App PW-reset */}
																						<Route
																							path="/forgot-password"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<PageForgotPassword />
																								</Suspense>
																							}
																						/>

																						{/* <Route
																								path="/update-password"
																								element={
																									<Suspense
																										fallback={<DotSpinner />}
																									>
																										<PrivateRoute
																											element={
																												<PageUpdatePassword />
																											}
																										/>
																									</Suspense>
																								}
																							/> */}

																						<Route
																							path="/user-profile"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<PrivateRoute
																										element={
																											<PageUserProfile />
																										}
																									/>
																								</Suspense>
																							}
																						/>

																						<Route
																							path="/user-management"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<PrivateRoute
																										element={
																											<PageUserManagement />
																										}
																										requiredRole={[
																											"evisMaster",
																											"evisAdmin",
																											"tenantAdmin"
																										]}
																									/>
																								</Suspense>
																							}
																						/>

																						<Route
																							// index
																							path="/"
																							index
																							element={
																								<Suspense
																									fallback={
																										<Flex
																											mt={"12rem"}
																											ml={"4rem"}
																										>
																											<DotSpinner />
																											<Text
																												ml={6}
																												mt={2}
																												fontWeight={"500"}
																												display={"flex"}
																												justify={"center"}
																												alignItems={"center"}
																												height={"30px"}
																											>
																												{localStorage.getItem(
																													"locale"
																												) === "en" ? (
																													<Text
																														animation={`${pulse} 1.5s infinite ease-in-out`}
																													>
																														Loading data
																													</Text>
																												) : localStorage.getItem(
																														"locale"
																												  ) === "fr" ? (
																													<Text
																														animation={`${pulse} 1.5s infinite ease-in-out`}
																													>
																														Les données se
																														chargent
																													</Text>
																												) : (
																													<Text
																														animation={`${pulse} 1.5s infinite ease-in-out`}
																													>
																														Daten werden geladen
																													</Text>
																												)}
																											</Text>
																										</Flex>
																									}
																								>
																									<PrivateRoute
																										element={<PageDashboard />}
																									/>
																									{/* <PageDashboard /> */}
																								</Suspense>
																							}
																						/>

																						<Route
																							// index
																							path="/parametereditor"
																							element={
																								<Suspense
																									fallback={
																										<Flex
																											mt={"12rem"}
																											ml={"4rem"}
																										>
																											<DotSpinner />
																											<Text
																												ml={6}
																												mt={2}
																												fontWeight={"500"}
																												display={"flex"}
																												justify={"center"}
																												alignItems={"center"}
																												height={"30px"}
																											>
																												{/* //^ Editordaten werden geladen... */}
																												{/* //^ Loading... */}
																												{localStorage.getItem(
																													"locale"
																												) === "en"
																													? "Loading data"
																													: localStorage.getItem(
																																"locale"
																														  ) === "fr"
																														? "Les données se chargent"
																														: "Daten werden geladen"}
																											</Text>
																										</Flex>
																									}
																								>
																									<PrivateRoute
																										element={
																											<PageParameterEditor
																												myModule={myModule}
																											/>
																										}
																									/>
																									{/* <PageParameterEditor
																											myModule={myModule}
																										/> */}
																								</Suspense>
																							}
																						/>
																						<Route
																							path="/itemandpriceeditor"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<PrivateRoute
																										element={
																											<PageItemAndPriceList />
																										}
																									/>
																									{/* <PageItemAndPriceList /> */}
																								</Suspense>
																							}
																						/>
																						<Route
																							path="/keymanagement"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<PrivateRoute
																										element={
																											<PageKeyManagement />
																										}
																									/>
																									{/* <PageKeyManagement /> */}
																								</Suspense>
																							}
																						/>
																						<Route
																							path="*"
																							element={
																								<Suspense
																									fallback={<DotSpinner />}
																								>
																									<Page404 />
																								</Suspense>
																							}
																						/>
																					</Route>
																				</Routes>
																			</AuthProvider>
																		</IsValidProvider>
																	</PcbStore>
																	{/* </FixedvalueStore> */}
																</DocParameterStore>
															</ConditionStore>
														</DocFolderStore>
													</FolderStore>
												</ParameterStore>
											</ConnectionProvider>
										</DeviceInfoProvider>
									</PortProvider>
								</WebSerialInstanceProvider>
								<ReactQueryDevtools initialIsOpen={false} />
							</QueryClientProvider>
							<ScrollToTop />
							<ToastContainer
								position="bottom-right"
								autoClose={2500}
								hideProgressBar
								// newestOnTop={false}
								newestOnTop={true}
								closeOnClick
								pauseOnFocusLoss={true}
								draggable
								pauseOnHover
								removeOnClick
								theme="colored"
								limit={10}
								//# COSTUMIZE TOASTIFY
								bodyClassName={() => "toastify-body"}
							/>
						</>
					</ErrorBoundary>
				</I18n>
			</Container>
			{/* </ChakraProvider> */}
		</Box>
	)
}

export default App
