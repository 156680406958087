import axios from "axios"
import { toast } from "react-toastify"

const checkOrSetDeviceOfTenantToDB = async (
	serialNumber,
	userClaims,
	deviceInfo
) => {
	const locale = localStorage.getItem("locale") || "de"

	const hardwarePrintNr = deviceInfo.hardwarePrintNr
	const hardwarePrintVersion = deviceInfo.hardwarePrintVersion
	const hardwareAssemblyVariant = deviceInfo.hardwareAssemblyVariant
	const hardwareAssemblyVersion = deviceInfo.hardwareAssemblyVersion

	const hardwarePrintNumberAndVersion =
		hardwarePrintNr + "." + hardwarePrintVersion
	const assemblyVariantAndVersion =
		hardwareAssemblyVariant + "." + hardwareAssemblyVersion

	const firmwareVersion =
		deviceInfo.firmwareVersionMajor +
		"." +
		deviceInfo.firmwareVersionMinor +
		"." +
		deviceInfo.firmwareVersionBeta

	try {
		//^ check if/which disti allocation is set
		const url =
			process.env.NODE_ENV === "production"
				? "/checkDeviceOfTenant"
				: "http://localhost:3000/checkDeviceOfTenant"

		const payload = { serialNumber, tenantId: userClaims.tenantId }
		const response = await axios.post(url, payload)
		const { deviceOfTenant } = response.data

		const isGuestUser =
			(await userClaims?.isGuest) ||
			(await userClaims?.provider_id || await userClaims?.role === "guest") === "anonymous"

		const isEvisMasterTenant = (await userClaims?.tenantId) === "0000"

		if (isGuestUser) {
			//^ case: user is guest
			if (deviceOfTenant === undefined || deviceOfTenant.length === 0) {
				await axios.post("/updateDeviceInfo", {
					serialNumber,
					firmwareNumber: deviceInfo.firmwareNumber,
					firmwareVersion: firmwareVersion,
					maxFirmwareVersion: deviceInfo.firmwareVersionMajor + ".99.0",
					minFirmwareVersion: deviceInfo.firmwareVersionMajor + ".0.0",
					hardwarePrintNumberAndVersion: hardwarePrintNumberAndVersion,
					assemblyVariantAndVersion: assemblyVariantAndVersion
				});
				return { status: "connect" };
			} else {
				switch (locale) {
					case "en":
						toast.error(
							`No access to device with serial number ${serialNumber}`
						)
						break
					case "de":
						toast.error(
							`Kein Zugriff auf Gerät mit Seriennummer ${serialNumber}`
						)
						break
					case "fr":
						toast.error(
							`Aucun accès au dispositif avec le numéro de série ${serialNumber}`
						)
						break
					default:
						toast.error(
							`Kein Zugriff auf Gerät mit Seriennummer ${serialNumber}`
						)
						break
				}

				return { status: "disconnect" }
			}
		} else if (isEvisMasterTenant) {
			// //^ case: user is evis master
			await axios.post("/updateDeviceInfo", {
				serialNumber,
				firmwareNumber: deviceInfo.firmwareNumber,
				firmwareVersion: firmwareVersion,
				maxFirmwareVersion: deviceInfo.firmwareVersionMajor + ".99.0",
				minFirmwareVersion: deviceInfo.firmwareVersionMajor + ".0.0",
				hardwarePrintNumberAndVersion: hardwarePrintNumberAndVersion,
				assemblyVariantAndVersion: assemblyVariantAndVersion
			});
			return {
				status: "connect",
				deviceOfTenant: deviceOfTenant || []
			}
		} else {
			//^ case: user is not guest or evis master - CLAIMS DEVICE, WRITES TO DB
			if (deviceOfTenant === undefined || deviceOfTenant.length === 0) {
				//^ case: no disti allocation set yet
				const response = await axios.post("/setDeviceOfTenant", {
					serialNumber,
					tenantId: userClaims.tenantId,
					firmwareNumber: deviceInfo.firmwareNumber,
					firmwareVersion: firmwareVersion,
					maxFirmwareVersion: deviceInfo.firmwareVersionMajor + ".99.0",
					minFirmwareVersion: deviceInfo.firmwareVersionMajor + ".0.0",
					hardwarePrintNumberAndVersion: hardwarePrintNumberAndVersion,
					assemblyVariantAndVersion: assemblyVariantAndVersion
				})
				const maxFirmwareVersion = response.data.maxFirmwareVersion //?TODO: check if this is needed as return value
				return {
					status: "connect",
					deviceOfTenant: deviceOfTenant || []
				}
			} else if (deviceOfTenant.includes(userClaims.tenantId)) {
				//^ case: disti allocation set and user has access
				const response = await axios.post("/updateDeviceOfTenant", {
					serialNumber,
					tenantId: userClaims.tenantId,
					firmwareNumber: deviceInfo.firmwareNumber,
					firmwareVersion: firmwareVersion,
					maxFirmwareVersion: deviceInfo.firmwareVersionMajor + ".99.0",
					minFirmwareVersion: deviceInfo.firmwareVersionMajor + ".0.0",
					hardwarePrintNumberAndVersion: hardwarePrintNumberAndVersion,
					assemblyVariantAndVersion: assemblyVariantAndVersion
				})
				const maxFirmwareVersion = response.data.maxFirmwareVersion //?TODO: check if this is needed as return value
				const minFirmwareVersion = response.data.minFirmwareVersion //?TODO: check if this is needed as return value
				return {
					status: "connect",
					deviceOfTenant: deviceOfTenant || []
				}
			} else {
				//^case: disti allocation set and user has no access
			
				switch (locale) {
					case "en":
						toast.error(
							`No access to device with serial number ${serialNumber}`
						)
						break
					case "de":
						toast.error(
							`Kein Zugriff auf Gerät mit Seriennummer ${serialNumber}`
						)
						break
					case "fr":
						toast.error(
							`Aucun accès au dispositif avec le numéro de série ${serialNumber}`
						)
						break
					default:
						toast.error(
							`Kein Zugriff auf Gerät mit Seriennummer ${serialNumber}`
						)
						break
				}
				return { status: "disconnect" }
			}
		}
	} catch (error) {
		// console.log(error)
		// toast.error("Fehler bei der Überprüfung der Disti-Zuweisung")
		return { status: "error" }
	}
}

export default checkOrSetDeviceOfTenantToDB
