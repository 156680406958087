import React from "react"
import ReusableBadgeHeading from "../GUI/ReusableBadgeHeading.jsx"
import AddTenant from "./UserManagement/AddTenant.jsx"
import AddTenantUser from "./UserManagement/AddTenantUser.jsx"
import UpdatePassword from "./UserProfile/UpdatePassword.jsx"
import SetLanguage from "./UserProfile/SetLanguage.jsx"
import SetTheme from "./UserProfile/SetTheme.jsx"

import { useTranslate } from "react-polyglot"
import ResetPasswordRemote from "./UserManagement/ResetPasswordRemote.jsx"
import useLocalizationStore from "../../api/zustandStores/localizationStore/localizationStore.js"
import TenantAffiliation from "./UserProfile/TenantAffiliation.jsx"
import useCurrentUserStore from "../../api/zustandStores/userStore/currentUserStore.js"

const AccountSettingsListItem = (props) => {
	const { locale, setLocale } = useLocalizationStore()
	const { currentUserData } = useCurrentUserStore()
	const { settingsType } = props
	const t = useTranslate()

	const getSettingsItem = () => {
		switch (settingsType) {
			case "addTenant":
				return <AddTenant />
			case "addTenantUser":
				return <AddTenantUser />
			case "updatePassword":
				return <UpdatePassword />
			case "resetPasswordRemote":
				return <ResetPasswordRemote />
			case "setLanguage":
				return <SetLanguage />
			case "setTheme":
				return <SetTheme />
			case "organisation":
				return <TenantAffiliation currentUserData={currentUserData} />
			default:
				return null
		}
	}

	const getBadgeTitle = () => {
		switch (settingsType) {
			case "addTenant":
				return t("AccountSettingsListItem.addTenantBadgeTitle", {
					_: "Distributor"
				})
			case "addTenantUser":
				return t("AccountSettingsListItem.addTenantUserBadgeTitle", {
					_: "Benutzer"
				})
			case "updatePassword":
				return t("AccountSettingsListItem.updatePasswordBadgeTitle", {
					_: "Passwort ändern"
				})
			case "resetPasswordRemote":
				return t("AccountSettingsListItem.resetPasswordRemoteBadgeTitle", {
					_: "Passwort für Remote-User zurücksetzen"
				})
			case "setLanguage":
				return t("AccountSettingsListItem.setLanguageBadgeTitle", {
					_: "Sprache wählen"
				})
			case "setTheme":
				return t("AccountSettingsListItem.setThemeBadgeTitle", {
					_: "Theme wählen"
				})
			case "organisation":
				return t("AccountSettingsListItem.organisationBadgeTitle", {
					_: "Distributor Zugehörigkeit"
				})
			default:
				return t("AccountSettingsListItem.titleUndefined", {
					_: "Title nicht definiert"
				})
		}
	}

	return (
		<ReusableBadgeHeading
			badgeTitle={getBadgeTitle()}
			badgeItem={getSettingsItem()}
		/>
	)
}

export default AccountSettingsListItem
