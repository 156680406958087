import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent
} from "@chakra-ui/react"
import ResetPasswordHandler from "../components/Auth/UserManagement/ResetPassWordHandler.jsx"

const PageResetPasswordHandler = () => {
	return (
		<Modal
			isOpen={true}
			closeOnOverlayClick={false}
			isCentered
			// onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent
				style={{
					paddingTop: "3rem",
					paddingBottom: "3rem",
					paddingLeft: "3rem",
					paddingRight: "3rem",
					borderRadius: "0"
				}}
			>
				<ResetPasswordHandler />
			</ModalContent>
		</Modal>
	)
}

export default PageResetPasswordHandler
