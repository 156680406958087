import React, { memo } from "react"

import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Flex,
	Box,
	Link,
	Text,
	useDisclosure,
	Divider
} from "@chakra-ui/react"

import { useTranslate } from "react-polyglot"
import LogoEvisContactDrawer from "../GUI/LogoEvisContactDrawer"
let ContactDrawer = React.lazy(() => import("./ContactDrawer"))

ContactDrawer = () => {
	const t = useTranslate()
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Flex as="samp">
				<Text mr={2}>|</Text>
				<Flex
					// colorScheme="blue"
					onClick={onOpen}
				>
					<Link
						whiteSpace="nowrap"
						mr={2}
					>
						<Text as="ins">
							{t("ContactDrawer.contact", {
								_: "Kontakt"
							})}
						</Text>
					</Link>
					{/* <Link
						whiteSpace="nowrap"
						mr={2}
					>
						<Text as="ins">
							{t("ContactDrawer.imprint", {
								_: "Impressum"
							})}
						</Text>
					</Link>
					<Link
						whiteSpace="nowrap"
						mr={2}
					>
						<Text as="ins">
							{t("ContactDrawer.termsAndConditions", {
								_: "AGB"
							})}
						</Text>
					</Link> */}
				</Flex>
			</Flex>

			<Drawer
				placement={"bottom"}
				onClose={onClose}
				isOpen={isOpen}
				size={"lg"}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader py={"2"}>
						<LogoEvisContactDrawer />
					</DrawerHeader>
					<Divider />
					<DrawerBody>
						<Flex
							flexDirection="row"
							py={4}
							wrap="wrap"
						>
							{/* //# Address information */}
							<Box
								mr={16}
								mb={{ base: 4, md: 0 }}
							>
								<Text>EVIS AG</Text>
								<Link
									href="https://maps.app.goo.gl/iZ1HMjT7Bjp8ypJ98"
									isExternal
								>
									<Text>Hölzliwisenstrasse 5</Text>
									<Text>8604 Volketswil/ZH</Text>
									<Text>Schweiz</Text>
								</Link>
							</Box>
							{/* //# Contact information */}
							<Box
								mr={16}
								mb={{ base: 4, md: 0 }}
							>
								<Text>
									<Link href="tel: +41 44 908 11 11">
										Tel.: +41 44 908 11 11
									</Link>
								</Text>
								<Text>
									<Link href="fax: +41 44 908 11 12">
										Fax: +41 44 908 11 12
									</Link>
								</Text>
								<Text>
									<Link href="mailto:info@evis.ch">info@evis.ch</Link>
								</Text>
								<Text>
									<Link
										href="https://www.evis.ch/"
										isExternal
									>
										evis.ch
									</Link>
								</Text>
							</Box>
							{/* //# Links */}
							<Box
								mr={16}
								mb={{ base: 4, md: 0 }}
							>
								<Link
									href="https://www.evis.ch/datenschutz/"
									isExternal
								>
									<Text>
										{t("ContactDrawer.privacyPolicy", {
											_: "Datenschutz"
										})}
									</Text>
								</Link>
								<Link
									href="https://www.evis.ch/impressum/"
									isExternal
								>
									<Text>
										{t("ContactDrawer.imprint", {
											_: "Impressum"
										})}
									</Text>
								</Link>
								<Link
									href="https://www.evis.ch/agb/"
									isExternal
								>
									<Text>
										{t("ContactDrawer.termsAndConditions", {
											_: "AGB"
										})}
									</Text>
								</Link>
							</Box>
						</Flex>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

export default memo(ContactDrawer)
