import React, { useEffect, useState } from "react"
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Box,
	Flex,
	Text
} from "@chakra-ui/react"
import { useTranslate } from "react-polyglot"
import TenantListActions from "./TenantListActions.jsx"
import getTenants from "../../firestoreHelpers/getTenants.js"
import useCurrentUserStore from "../../../../api/zustandStores/userStore/currentUserStore.js"
import { useAtom } from "jotai"
import rerenderTriggerTenantListAtom from "../../../../api/jotaiAtoms/rerenderTriggerTenantListAtom.js"

const TenantList = (props) => {
	const [rerenderTriggerTenantList] = useAtom(rerenderTriggerTenantListAtom)

	const t = useTranslate()
	const { currentUserData } = useCurrentUserStore()
	const [tenants, setTenants] = useState([])

	//^ Trigger re-render of TenantList - both useEffects are needed
	useEffect(() => {
		const fetchUsers = async () => {
			if (currentUserData?.tenantId) {
				const usersList = await getTenants()
				setTenants(usersList)
			}
		}
		fetchUsers()
	}, [currentUserData?.tenantId, setTenants, rerenderTriggerTenantList])

	//^ Sort tenants so that tenantAdmin and evisAdmin appear at the top
	const sortedTenants = tenants.sort((a, b) => {
		const roleOrder = { evisMaster: 1, evisAdmin: 2, tenantAdmin: 3 }
		return (roleOrder[a.role] || 2) - (roleOrder[b.role] || 2)
	})

	return tenants.length > 0 ? (
		<TableContainer
			ml={-6}
			maxW={"max-content"}
			overflowX={{ base: "auto", lg: "hidden" }} // Allow horizontal scrolling on smaller screens
		>
			<Table
				size="md"
				variant="unstyled"
				display={{ base: "block", sm: "block", md: "table" }}
				maxW={"100%"}
				overflowX={"auto"}
				overflowY={"hidden"}
				whiteSpace={"nowrap"}
				mb={4}
			>
				<Thead display={{ base: "none", sm: "none", md: "table-header-group" }}>
					{/* //^ Hide header on small screens */}
					<Tr borderBottom={"1px solid lightgrey"}>
						<Th
							style={{
								fontSize: "1rem",
								paddingBottom: "0.3rem"
							}}
						>
							{t("TenantList.tenantName", {
								_: "Distributor Name"
							})}
						</Th>
						<Th
							style={{
								fontSize: "1rem",
								paddingBottom: "0.3rem"
							}}
						>
							{t("TenantList.tenantId", {
								_: "Distributor Id"
							})}
						</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody display={{ base: "block", sm: "block", md: "table-row-group" }}>
					{sortedTenants.map((tenant) => (
						<React.Fragment key={tenant.tenantId}>
							<Tr
								key={tenant.tenantId}
								display={{ base: "block", sm: "block", md: "table-row" }}
								style={{
									color:
										tenant.tenantName === "EVIS" ||
										tenant.tenantName === "EVIS MASTER"
											? "#1d846f"
											: "default",
									fontSize: "1.1rem",
									borderBottom: "1px solid lightgrey"
								}}
							>
								<Td
									style={{
										lineHeight: "1.7rem",
										paddingTop: "0.2rem",
										paddingBottom: "0.2rem"
									}}
									display={{ base: "flex", sm: "flex", md: "table-cell" }}
									flexDirection={{ base: "column", sm: "column", md: "row" }}
									minW={{ base: "100%", md: "272px" }}
								>
									<Box
										display={{ base: "flex", sm: "flex", md: "table-cell" }}
										flexDirection={{ base: "column", sm: "column", md: "row" }}
										_before={{
											content: `"${t("TenantList.tenantName", {
												_: "Distributor Name"
											})}"`,
											fontWeight: "bold",
											// width: "600px",
											display: { base: "block", sm: "block", md: "none" }
										}}
									>
										{tenant.tenantName}
									</Box>
								</Td>
								<Td
									style={{
										lineHeight: "1.7rem",
										paddingTop: "0.2rem",
										paddingBottom: "0.2rem"
									}}
									display={{ base: "flex", sm: "flex", md: "table-cell" }}
									flexDirection={{ base: "column", sm: "column", md: "row" }}
									_before={{
										content: `"${t("TenantList.tenantId", {
											_: "Distributor Id"
										})}"`,
										fontWeight: "bold",
										width: "100px",
										display: { base: "block", sm: "block", md: "none" }
									}}
								>
									{tenant.tenantId}
								</Td>
								<Td
									style={{
										lineHeight: "1.7rem",
										paddingTop: "0.2rem",
										paddingBottom: "0.2rem"
									}}
									display={{ base: "flex", sm: "flex", md: "table-cell" }}
									flexDirection={{ base: "column", sm: "column", md: "row" }}
									_before={{
										content: '""',
										fontWeight: "bold",
										width: "100px",
										display: { base: "block", sm: "block", md: "none" }
									}}
								>
									{/*//info: only allow to render in local development */}
									{process.env.NODE_ENV === "development" && (
										<TenantListActions
											confirmType="deleteTenant"
											user={tenant}
											tenantId={currentUserData?.tenantId}
											email={tenant.email}
											tenant={tenant} //info: named tenant here, user in TenantUserList
										/>
									)}
								</Td>
							</Tr>
						</React.Fragment>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	) : (
		<Flex
			justifyContent="center"
			alignItems="center"
			height="100%"
		>
			<Text>{t("TenantList.noTenants", { _: "No tenants available" })}</Text>
		</Flex>
	)
}

export default TenantList
