import rerenderTriggerTenantUserListAtom from "../../../api/jotaiAtoms/rerenderTriggerTenantUserListAtom.js"
import { getDefaultStore } from "jotai"

const handleRemoveTenantUser = async (user) => {
	const store = getDefaultStore()

	try {
		const response = await fetch("api/deleteTenantUserFromAuthAndDB", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ user: user })
		})
		const result = await response.json()
		//^ report to TenantUserList to update TenantUserList
		store.set(rerenderTriggerTenantUserListAtom, (prev) => !prev)
	} catch (error) {
		console.error("Error removing user:", error)
	}
}

export default handleRemoveTenantUser
