import axios from "axios"

const checkIfTenantIdAlreadyExists = async (tenantId) => {
	try {
		const response = await axios.post("/api/checkIfTenantIdAlreadyExists", {
			tenantId
		})
		return response.data
	} catch (error) {
		console.error("Error checking if tenant ID already exists:", error)
		throw error
	}
}

export default checkIfTenantIdAlreadyExists
