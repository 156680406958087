import axios from "axios"

const getDeviceData = async (serialNumber, userClaims, deviceInfo) => {

	try {
		const url =
			process.env.NODE_ENV === "production"
				? "/getDeviceData"
				: "http://localhost:3000/getDeviceData"

		const payload = {
			serialNumber,
			tenantId: userClaims.tenantId,
			firmwareNumber: deviceInfo.firmwareNumber,
			firmwareVersion:
				deviceInfo.firmwareVersionMajor +
				"." +
				deviceInfo.firmwareVersionMinor +
				"." +
				deviceInfo.firmwareVersionBeta,
			hardwarePrintNumberAndVersion: deviceInfo.hardwarePrintNr + "." + deviceInfo.hardwarePrintVersion,
			assemblyVariantAndVersion: deviceInfo.hardwareAssemblyVariant + "." + deviceInfo.hardwareAssemblyVersion,
			maxFirmwareVersion: deviceInfo.firmwareVersionMajor + ".99.0",
			minFirmwareVersion: deviceInfo.firmwareVersionMajor + ".0.0"
		}
		const response = await axios.post(url, payload)
		const { deviceData } = response.data
		// console.log("deviceData", deviceData)
		return deviceData
	} catch (error) {
		console.log("Error fetching device data:", error)
		// Return a special object instead of an error status
		return { notFound: true, serialNumber }
	}
}

export default getDeviceData
