//# Connect Event Listener

//# EVENT ON CONNECT
export const handleSerialConnect = async (
	event,
	setIsConnected,
	setIsPluggedIn,
	openSerialPort,
	toast,
	t
) => {
	try {
		setIsPluggedIn?.(true)
		toast.info(
			t("Toasts.toastReaderPluggedIn", {
				_: "Gerät angeschlossen"
			})
		)
	} catch (error) {
		console.error("Serial connection error:", error)
		setIsConnected?.(false)
		toast.error(
			t("Toasts.toastReaderConnectionError", {
				_: "Verbindungsfehler"
			})
		)
	}
}

//# EVENT ON DISCONNECT
export const handleSerialDisconnect = (
	port,
	setIsConnected,
	setIsPluggedIn,
	openSerialPort,
	toast,
	t
) => {
	try {
		setIsPluggedIn?.(false)
		setIsConnected?.(false)
		toast.info(
			t("Toasts.toastReaderUnplugged", {
				_: "Gerät getrennt"
			})
		)
	} catch (error) {
		console.error("Serial disconnection error:", error)
	}
}

//# Disconnect Device
export const disconnectDevice = async (
	port,
	setIsConnected,
	openSerialPort
) => {
	try {
		if (port?.readable || port?.writable) {
			await port.close()
		}
		setIsConnected(false)
	} catch (error) {
		console.error("Error disconnecting device:", error)
	}
}
