import React from "react"
import { Badge, Grid, GridItem, Flex } from "@chakra-ui/react"
import theme_mineralis from "../../theme.js"

const ReusableBadgeHeading = ({ badgeTitle, badgeItem }) => {
	return (
		<>
			<Badge
				fontFamily={theme_mineralis.fonts.heading}
				w="auto"
				minWidth={{ base: "60%", md: "465px" }}
				alignItems="start"
				pl={-2}
				pr={2}
				fontSize="1.1em"
				textAlign="start"
				mt={{
					base: "3.5rem",
					md: "2.5rem",
					lg: "2.5rem"
				}}
				mb={{
					base: "1rem",
					md: "0.7rem",
					lg: "0.7rem"
				}}
				cursor="default"
			>
				{badgeTitle}
			</Badge>
			<Grid
				display={"block"}
				templateColumns="repeat(5, 1fr)"
				maxW={{ base: "100%", md: "95%" }}
				px={{ base: 0, md: 4 }}
				gap={6}
				alignItems="end"
			>
				<Flex
					flexWrap={{ base: "wrap", md: "nowrap" }}
					alignItems="end"
				>
					<GridItem colSpan={1}>{badgeItem}</GridItem>
				</Flex>
			</Grid>
		</>
	)
}

export default ReusableBadgeHeading
