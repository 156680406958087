import React from "react"

import { Box, Center } from "@chakra-ui/react"
import DotSpinner from "../GUI/DotSpinner"
import "./Overlay.css" // Import CSS file for styling

const Overlay = ({ bgColor, color = "white" }) => {
	return (
		<div className="overlay">
			<Box>
				<Center>
					<DotSpinner
						color="white"
						size="80px"
					/>
				</Center>
			</Box>
		</div>
	)
}

export default Overlay
