import React from "react"
import {
	useColorMode,
	Box,
	Button,
	ButtonGroup,
	Tooltip,
	Kbd
} from "@chakra-ui/react"
import { useTranslate } from "react-polyglot"
import DecoratorTriangleButtonResizable from "../../GUI/DecoratorTriangleButtonResizable.jsx"

export default function SetTheme() {
	const t = useTranslate()
	const { colorMode, toggleColorMode, setColorMode } = useColorMode()

	return (
		<>
			{/* //# THEME SWITCH */}
			<Box mt={1}>
				<Tooltip
					bg="tooltip-bg"
					aria-label="tooltip to use shortcut to toggle theme"
					hasArrow
					placement="bottom-end"
					openDelay={1200}
					closeDelay={200}
					label={
						<div py={"1rem"}>
							<span>ctrl</span> + <span>M</span>
						</div>
					}
				>
					<ButtonGroup
						fontSize={"lg"}
						size="lg"
						isAttached
					>
						<Button
							value="light"
							onClick={() => colorMode !== "light" && toggleColorMode()}
							bg={
								colorMode === "light"
									? "btn-primary-active-bg"
									: "btn-primary-bg"
							}
							color={
								colorMode === "light"
									? "btn-primary-text-active"
									: "btn-primary-text"
							}
							_hover={{
								bg:
									colorMode === "light"
										? "btn-primary-hover-bg"
										: "btn-primary-hover-bg"
							}}
							height={"2rem"}
						>
							{/*//^ THEME SWITCH TEXTS*/}
							{t("SetTheme.themeLight", {
								_: "Light Theme"
							})}
						</Button>
						<Button
							onClick={() => colorMode !== "dark" && toggleColorMode()}
							bg={
								colorMode === "dark"
									? "btn-primary-active-bg"
									: "btn-primary-bg"
							}
							color={
								colorMode === "dark"
									? "btn-primary-text-active"
									: "btn-primary-text"
							}
							_hover={{
								bg:
									colorMode === "dark"
										? "btn-primary-hover-bg"
										: "btn-primary-hover-bg"
							}}
							height={"2rem"}
						>
							{/*//^ THEME SWITCH TEXTS*/}
							{t("SetTheme.themeDark", {
								_: "Dark Theme"
							})}
						</Button>
						<DecoratorTriangleButtonResizable
							backgroundColor="btn-primary-active-bg"
							borderColor="btn-primary-border"
							decoratorTriangleHeight={"2rem"}
							decoratorTriangleWidth={"2.2rem"}
						/>
					</ButtonGroup>
				</Tooltip>
			</Box>
		</>
	)
}
