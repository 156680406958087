import axiosAuth from "../../../api/axiosInstanceConfig/axiosAuth"

const signUpTenantAndAdmin = async (
	tenantId,
	tenantName,
	tenantAdminEmail,
	displayName,
	role,
	password,
	passwordConfirm
) => {
	try {
		const response = await axiosAuth.post("/api/signUpTenantAndAdmin", {
			tenantId,
			tenantName,
			tenantAdminEmail,
			displayName,
			password,
			role
		})
		return response.data
	} catch (error) {
		console.error("Error signing up tenant and admin user:", error)
		throw error
	}
}

export default signUpTenantAndAdmin
