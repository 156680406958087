import React from "react"
import { auth, signInAnonymously } from "../firebase.js"
import { Button, Center } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { useTranslate } from "react-polyglot"
import { signInWithCustomToken } from "firebase/auth"

const SignInAnonymouslyButton = () => {
	const navigate = useNavigate()
	const t = useTranslate()

	const handleSignIn = async () => {
		try {
			const guestId = localStorage.getItem("guestId")
			if (guestId) {
				// Reclaim existing guest account
				const response = await fetch("/api/reclaimGuestAccount", {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ guestId })
				})

				if (!response.ok) {
					throw new Error("Failed to reclaim guest account")
				}

				const { customToken } = await response.json()
				await signInWithCustomToken(auth, customToken)
			} else {
				// Create new anonymous account
				const userCredential = await signInAnonymously(auth)
				const currentUser = userCredential.user
				localStorage.setItem("guestId", currentUser.uid)

				// Set guest claim for new anonymous users
				const response = await fetch("/api/reclaimGuestAccount", {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ guestId: currentUser.uid })
				})

				if (!response.ok) {
					throw new Error("Failed to set guest claim")
				}
			}

			// Check if the user is signed in after the sign-in process
			const user = auth.currentUser
			if (user) {
				await user.getIdToken(true) // Force token refresh to get updated claims
				setTimeout(() => navigate("/", { replace: true }), 300)
			} else {
				console.error("User is not signed in after sign-in attempt.")
			}

		} catch (error) {
			console.error("Error signing in as guest:", error)
			// Handle the error case here (e.g., log or show a message)
			if (error.message === "Failed to reclaim guest account") {
				// Attempt to create a new anonymous account again
				console.log("Attempting to create a new anonymous account")
				try {
					const userCredential = await signInAnonymously(auth)
					const currentUser = userCredential.user
					localStorage.setItem("guestId", currentUser.uid)

					// Set guest claim for new anonymous users
					const response = await fetch("/api/reclaimGuestAccount", {
						method: "POST",
						headers: {
							"Content-Type": "application/json"
						},
						body: JSON.stringify({ guestId: currentUser.uid })
					})

					if (!response.ok) {
						throw new Error("Failed to set guest claim on retry")
					}

					// Check if the user is signed in after the sign-in process
					const user = auth.currentUser
					if (user) {
						// console.log("User signed in on retry:", user.uid) // Log the signed-in user
						await user.getIdToken(true) // Force token refresh to get updated claims
						// navigate("/", { replace: true })
						setTimeout(() => navigate("/", { replace: true }), 300)

					} else {
						console.error("User is not signed in after retry.")
					}

				} catch (retryError) {
					console.error("Retry failed:", retryError)
				}
			}
		}
	}

	return (
		<Center mt={2}>
			<Button
				onClick={handleSignIn}
				maxW={"100%"}
				size={"md"}
				p={4}
				border={"1px solid"}
				borderColor={"btn-primary-hover-bg"}
			>
				{t("SignInAnonymouslyButton.signInAnonymously", {
					_: "Als Gast anmelden"
				})}
			</Button>
		</Center>
	)
}

export default SignInAnonymouslyButton
