import React, { Suspense, useState, useEffect } from "react"
import DotSpinner from "../components/GUI/DotSpinner.jsx"
import UserProfileView from "../components/Views/UserProfileView/UserProfileView.jsx"
import { useLocation } from "react-router-dom"
import { Fade } from "@chakra-ui/react"

const PageUserProfile = () => {
	const [isVisible, setIsVisible] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setIsVisible(false)
		const timer = setTimeout(() => {
			setIsVisible(true)
		}, 10)

		return () => clearTimeout(timer)
	}, [location.pathname])

	return (
		<>
			<Suspense fallback={<DotSpinner />}>
				<Fade
					// direction="bottom"
					transition={
						location.pathname !== "/" && location.pathname !== "/login"
							? { enter: { delay: 0.3, duration: 0.5 } }
							: null
					}
					in={isVisible}
					key={location.pathname}
				>
					<UserProfileView />
				</Fade>
			</Suspense>
		</>
	)
}

export default PageUserProfile
