import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  FormControl,
  VStack,
  Flex,
  Table,
  Tr,
  Td,
  Th,
  Tbody,
  Thead,
  Text,
  Divider
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon, MinusIcon, EditIcon } from '@chakra-ui/icons';
import { useTranslate } from "react-polyglot";
import AddProjectForm from './AddProjectForm';
import { useAuth } from '../../../../contexts/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import DotSpinner from '../../../GUI/DotSpinner';
import ConfirmActionModal from '../../../GUI/ConfirmActionModal';

const ProjectCreator = ({   isOpen, 
  onClose, 
  serialNumber, 
  displayName,
  tenantId, 
  projects = [], 
  isLoading,
  onProjectsUpdate
}) => {

  const t = useTranslate();
  const [selectedProject, setSelectedProject] = useState('');
  const [projectToEdit, setProjectToEdit] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectNumber, setProjectNumber] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [isProjectNumberTouched, setIsProjectNumberTouched] = useState(false);
  const { userClaims } = useAuth();
  const [confirmDeleteProject, setConfirmDeleteProject] = useState(null);

  const assignDeviceToProject = async (projectNumber, tenantId) => {

    console.log("projectNumber", projectNumber)
    console.log("projects", projects)
    console.log("tenantId", tenantId)

    const existingProject = projects.find(p => p.id === selectedProject);
        console.log("existingProject", existingProject)
    await axios.post("/assignDeviceToExistingProject", {
      // tenantId: userClaims.tenantId,
      tenantId: tenantId,
      projectNumber: projectNumber.toString(),
      serialNumber: serialNumber.toString()
    });

    toast.success(t("ProjectCreator.successAssign", { 
      _: `Gerät wurde Projekt ${projectNumber} zugewiesen` 
    }));

    //^ close modal
    resetForm();
    onClose(); //TODO: maybe keep open if not modal but drawer ?
  }

  const removeDeviceFromProject = async (projectNumber, tenantId) => {
    console.log("projectNumber", projectNumber)

    await axios.post("/removeDeviceFromProject", {
      // tenantId: userClaims.tenantId,
      tenantId: tenantId,
      projectNumber: projectNumber.toString(),
      serialNumber: serialNumber.toString()
    });

    toast.success(t("ProjectCreator.successRemove", { 
      _: `Gerät wurde aus Projekt ${projectNumber} entfernt` 
    }));

    //^ close modal
    resetForm();
    onClose(); //TODO: maybe keep open if not modal but drawer ?  
  }

  const resetForm = () => {
    setSelectedProject('');
    setProjectName('');
    setProjectNumber('');
    setSelectedColor('');
    setIsProjectNumberTouched(false);
    setProjectToEdit('');

  };
  
  const deleteSelectedProject = async (projectNumber) => {

    await axios.post("/deleteProject", {
      tenantId: tenantId,
      projectNumber: projectNumber.toString()
    });

    toast.success(t("ProjectCreator.successDelete", { 
      _: `Projekt ${projectNumber} wurde gelöscht` 
    }));
    //^ close modal
    onClose(); //TODO: maybe keep open if not modal but drawer ?  
  }

  const handleDeleteProject = () => {
    if (confirmDeleteProject) {
        deleteSelectedProject(confirmDeleteProject);
        setConfirmDeleteProject(null);
    }
  };


  return (
          <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"} >
          <ModalOverlay />
          <ModalContent backgroundColor="bg-default">
            <ModalHeader >{t("ProjectCreator.title", { _: "Gerät an Projekt zuweisen" })}</ModalHeader>
            <ModalCloseButton />
            <ModalBody backgroundColor="bg-default" mb={3}>
            <Flex alignItems={"start"} mb={2} mt={-5}>
                <Text textDecoration={"underline"} textAlign={"left"} fontSize={"sm"}>{t("ProjectCreator.selectedDevice", { _: "Ausgewähltes Gerät" })} </Text>
                <Text textAlign={"left"} fontSize={"sm"}>:</Text>
                <Text ml={2} textAlign={"left"} fontSize={"sm"}>{serialNumber}  {displayName ? `- ${displayName}` : ""}</Text>
                </Flex>
              <VStack spacing={4}>
                 <FormControl>
                 <>
                    <Table sortable  minWidth={"100%"} size="md" variant='simple' style={{ display: 'block', maxHeight: '400px', overflowY: 'auto' }} >
                    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                      <Thead display={"flex"}  position={"sticky"} top={0} zIndex={1} bg="bg-default">
                        <Tr width={{base: "100%", md: "100%", lg: "100%"}}>
                          {/* //! SPACING NEEDS TO BE ADJUSTED */}
                         <Flex> 
                          <Th  paddingX={0}  width={"800px"} >{t("ProjectCreator.existingProjects", { _: "Vorhandene Projekte" })}</Th>
                          <Th  paddingX={0}  width={"330px"} >{t("ProjectCreator.deviceActions", { _: "Gerät" })}</Th>
                          <Th  ml={-8} paddingX={0}  width={"230px"} >{t("ProjectCreator.projectActions", { _: "Projekt" })}</Th>
                         </Flex>
                        </Tr>
                      </Thead>
                      {isLoading ? (
                        <DotSpinner />
                      ) : (
                        <>
                        <Tbody justifyContent={"space-between"} width={"100%"} >
                          {projects.sort((a, b) => a.projectNumber - b.projectNumber).map((project) => (
                            
                            <Tr
                              key={project.projectNumber}
                              sortable
                              verticalGap={0}
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <Td paddingY={1} width={"320px"} >
                              <Flex  >
                                {/* <Text>{`${project.projectNumber} - ${project.projectName}`} { `(${project.devices.length})`}</Text> */}
                                <Text>{`${project.projectNumber} - ${project.projectName}`}</Text>
                               </Flex>
                               </Td>
                               <Td paddingY={1} >
                               <Flex gap={2}  pr={0}>
                                {/* //# Add device to Project Button */}
                                <Tooltip label={t("ProjectCreator.addDeviceTooltip", { _: "Gerät zu Projekt hinzufügen" })} hasArrow={true} bg="tooltip-bg" aria-label="add device to project" placement="top-start" openDelay={300} closeDelay={200} >
                               <Button 
                                  size="xs" 
                                  mr={0} 
                                  borderRadius="5px" 
                                  border="none" 
                                  onClick={() => assignDeviceToProject(project.projectNumber, tenantId)}
                                  display={project.devices.includes(serialNumber?.toString()) ? "none" : "flex"}
                                  // disabled={project.devices.includes(serialNumber?.toString())}
                                >
                                  <AddIcon />
                                </Button>   
                                </Tooltip>
                                 {/* //# Remove device from Project */}
                                <Tooltip label={t("ProjectCreator.removeDeviceTooltip", { _: "Gerät aus Projekt entfernen" })} hasArrow={true} bg="tooltip-bg" aria-label="remove device from project" placement="top-start" openDelay={300} closeDelay={200} >
                                <Button 
                                  size="xs" 
                                  mr={0} 
                                  borderRadius="5px" 
                                  border="none" 
                                  // disabled={!project.devices.includes(serialNumber.toString())}
                                  display={!project.devices.includes(serialNumber?.toString()) ? "none" : "flex"}
                                  onClick={() => removeDeviceFromProject(project.projectNumber, tenantId)}
                                >
                                  <MinusIcon />
                                </Button>
                                </Tooltip>
                                </Flex>
                                </Td>
                                {/* //# Edit Project and all device associations with it */}
                               <Td paddingY={1}  >
                                <Flex gap={2}>
                                <Tooltip 
                                label={t("ProjectCreator.editProjectTooltip", { _: "Projekt bearbeiten" }) } 
                                hasArrow={true}
                                bg="tooltip-bg"
                                aria-label="edit project"
                                placement="top-start"
                                openDelay={300}
                                closeDelay={200}>
                                <Button 
                                  size="xs" 
                                  mr={0} 
                                  borderRadius="5px" 
                                  border="none" 
                                  onClick={() => {setSelectedProject("edit"); setProjectToEdit(project.projectNumber)}}
                                >
                                  <EditIcon />
                                </Button>
                                </Tooltip>
                                {/* //# Delete Project and all device associations with it */}
                              
      
                          {/* //# Confirm Action Modal */}
                          <ConfirmActionModal
                            isOpen={!!confirmDeleteProject} 
                            onClose={() => setConfirmDeleteProject(null)} 
                            handleClick={handleDeleteProject} 
                            confirmDeleteProject={confirmDeleteProject}
                            setConfirmDeleteProject={setConfirmDeleteProject}
                            confirmType="deleteProject" 
                            projectNumber={project.projectNumber}
                            message={t("ProjectCreator.confirmDeleteMessage", { _: "Alle zugehörigen Geräte werden aus diesem Projekt entfernt." })} 
                          />
                                </Flex>
                                </Td>
                            </Tr>
                          ))}
                          </Tbody>
                        </>
                      )}
                    </Table>
                  </>
                  <Divider mt={4} mb={4}/>
                  {/* //# Create New Project Button */}
                  <Button 
                          onClick={() => setSelectedProject('new')}
                          >
                            + {t("ProjectCreator.createNewProject", { _: "Neues Projekt erstellen" })}
                          </Button>
                </FormControl>
                {/* //# Add Project Form */}
                {(selectedProject === 'new' || selectedProject === 'edit') && (
                 <AddProjectForm 
                 formType={selectedProject}
                //  projectNumber={projectNumber}
                 projectToEdit={projectToEdit}
                 projectNumber={projectToEdit}
                 setProjectNumber={setProjectNumber}
                 projectName={projectName}
                 setProjectName={setProjectName}
                 selectedProject={selectedProject}
                 selectedColor={selectedColor}
                 setSelectedColor={setSelectedColor}
                 onProjectsUpdate={onProjectsUpdate}
                 onClose={onClose}
                 tenantId={tenantId}
                 serialNumber={serialNumber}
                 projects={projects}
                 setSelectedProject={setSelectedProject}
                setProjectToEdit={setProjectToEdit}
                resetForm={resetForm}
               

                 />
                )}
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
    
  );
};

export default ProjectCreator;