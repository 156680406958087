import React, { useEffect } from 'react';
import { Button, Tooltip, Box, Text } from '@chakra-ui/react';
import { PiHexagon } from "react-icons/pi";
import { FaPlus } from "react-icons/fa";
import { useTranslate } from "react-polyglot";


const ProjectBadgeIcon = ({ projectName, projectNumber, badgeColor = "fg-disabled", textColor = "fg-disabled", size = "40px", ...props }) => {
  const t = useTranslate();
 

  // useEffect(() => {
  //   console.log("ProjectBadgeIcon Props:", {
  //     props,
  //     associatedProjects: projects,
  //     projectName,
  //     projectNumber,
  //     badgeColor,
  //     textColor,
  //     serialNumber,
  //     projectsLength: projects?.length
  //   });
  // }, [projectName, projectNumber, badgeColor, textColor, serialNumber, projects]);

// console.log("props:", props)

  // console.log("projectName:", projectName)
  // console.log("projectNumber:", projectNumber)
  // console.log("badgeColor:", badgeColor)
  // console.log("projects:", projects)

  
  const initials = projectName
    ?.split(' ')
    ?.map(word => word[0])
    ?.join('')
    ?.slice(0, 2)
    ?.toUpperCase() || '';


  return (

    <Tooltip 
    // label={projectName} 
    label={projectName === undefined ? t("ProjectBadgeIcon.addToProject", { _: "Gerät an Projekt zuweisen" }) : `${projectNumber} - ${projectName}`} 
    hasArrow={true}
    bg="tooltip-bg"
    aria-label="tooltip showing current configuration format"
    placement="right"
    openDelay={300}
    closeDelay={200}>
       <Box
        onClick={props.onClick}
        position="relative"
        cursor="pointer"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        transition="transform 0.2s"
        _hover={{ transform: 'scale(1.1)' }}
        marginLeft={props.marginLeft}
        paddingLeft={props.paddingLeft}
      >
        <Box color={badgeColor}>
          <PiHexagon 
            size={size}
          />
        </Box>
        <Text
          position="absolute"
          color={textColor}
          fontSize="14px"
          fontWeight="500"
        >
          {projectName === undefined ? <FaPlus /> : initials}
          {/* {initials} */}
        </Text>
      </Box>
  </Tooltip>

   
  );
};



export default ProjectBadgeIcon;