import axiosAuth from "../../../api/axiosInstanceConfig/axiosAuth"

const getTenants = async () => {
	try {
		const response = await axiosAuth.get(`/api/getTenants`)
		return response.data
	} catch (error) {
		console.error("Error fetching users in tenant:", error)
		throw error
	}
}

export default getTenants
