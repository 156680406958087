import axios from "axios"

async function getCurrentUserData(email) {
	if (!email) {
		throw new Error("Email is required")
	}
	try {
		const response = await axios.get("/api/getCurrentUserData", {
			params: { email }
		})
		return response.data
	} catch (error) {
		console.error("Error getting user data: ", error)
		throw error
	}
}

export default getCurrentUserData
