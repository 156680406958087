import { toast } from "react-toastify"
import { sendGetFileCmd } from "./TlvAccessCmd.js"
import { sendStartPutCmd } from "./TlvAccessCmd.js"
import { sendPutFileSliceCmd } from "./TlvAccessCmd.js"
import { sendSystemDeviceInfoCmd } from "./TlvAccessCmd.js"
import { compare } from "compare-versions"

export async function getFile(instance, port, filename, onProgress) {
	// console.log("getFile called")
	//first time we get status which indicate weather a file exists or is permitted 0 is ok
	let answer = await sendGetFileCmd(instance, port, filename)
	// console.log(answer)
	let localeFromLocalStorage = localStorage.getItem("locale") || "de"
	if (answer === undefined || answer.status !== 0) {
		switch (answer.status) {
			case 6:
				toast.error(
					localeFromLocalStorage === "en"
						? "File not found."
						: localeFromLocalStorage === "fr"
							? "Aucun fichier trouvé"
							: "Keine Datei gefunden",
					{
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						limit: 1
					}
				)
				//^ clear waiting que
				toast.clearWaitingQueue()
				// throw new Error("Status answer: File not found", answer)
				// dont resume if file not found
				break

			default:
				throw new Error("Status Error", answer)
		}
	}

	const sha256 = new Uint8Array(answer.sha256)
	const sha256String = JSON.stringify(sha256)

	//remember fileSize
	const fileSize = answer.fileSize
	// console.log(fileSize)
	let retry = 0
	// export a variable to use the value in other functions
	let lastProce = 0

	const fileData = new Uint8Array(fileSize)
	//as long there is data we request with sendGetFileCmd
	for (let fileIndex = 0; fileIndex < fileSize; ) {
		answer = await sendGetFileCmd(instance, port, filename)
		if (answer.status === 3) {
			// Error sending packet-> we try again
			if (retry > 2) {
				throw new Error("Sending packet failed" + answer)
			}
			answer.status = 0
			retry++
		} else {
			retry = 0
			fileIndex = fileIndex + answer.fileSliceSize
		}
		if (lastProce < fileIndex / fileSize - 0.01) {
			lastProce = fileIndex / fileSize
			// console.log("progress:" + Math.round(lastProce * 100) + "%")
		}
		// console.log(lastProce * 100)
		//^ report progress, onProgress callback delivers the progress value to the parent component
		onProgress(Math.ceil(lastProce * 100))

		// console.log(answer)
		fileData.set(answer.data, answer.fileOffset)
	}

	const hashBuffer = await crypto.subtle.digest("SHA-256", fileData)
	const hashArray = new Uint8Array(hashBuffer)
	const hashArrayString = JSON.stringify(hashArray)
	// console.log(hashArrayString)

	// make code block executable for sha256 check only if deviceInfo return a firmwarenumber over 0.4.0
	await sendSystemDeviceInfoCmd(instance, port).then((answer) => {
		if (answer === undefined) {
			console.log(
				"Device is not available OR connectedFirmware is below 0.3.4, no sha-checksum"
			)
		} else if (
			compare(
				answer.firmwareVersionMajor +
					"." +
					answer.firmwareVersionMinor +
					"." +
					answer.firmwareVersionBeta,
				"0.4.0",
				">="
			)
		) {
			if (hashArrayString !== sha256String) {
				toast.error(
					localeFromLocalStorage == "en"
						? "File not found."
						: localeFromLocalStorage == "fr"
							? "Aucun fichier trouvÃ©"
							: "Keine Datei gefunden"
				)
				// throw new Error("Status answer: File not found", answer)
			} else {
				// toast.success("SHA checksum correct")
			}
		}
	})
	// console.log(fileData)
	return fileData
}

export async function putFile(
	instance,
	port,
	filename,
	replace,
	file,
	onProgress
) {
	let retry = 0
	const sliceSize = 400

	let sha
	let hashArray

	//^ < FW 0.4 we are sending sha instead of hashArray
	sha = new Uint8Array([
		0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		0, 0, 0, 0, 0, 0, 0
	])
	const hashBuffer = await crypto.subtle.digest("SHA-256", file)
	hashArray = Array.from(new Uint8Array(hashBuffer)) //! ACTIVATE FOR SHA CHECKSUM - DISABLED FOR NOW TO MAKE < FW 0.3.5 WORK (we are sending sha instead of hashArray now)

	let conditionalEncryption

	await sendSystemDeviceInfoCmd(instance, port).then(async (answer) => {
		// console.log(answer)
		if (answer === undefined) {
			//^ Firmware is below 0.3.4, no sha-checksum available

			conditionalEncryption = sha
		} else if (
			compare(
				answer.firmwareVersionMajor +
					"." +
					answer.firmwareVersionMinor +
					"." +
					answer.firmwareVersionBeta,
				"0.4.0",
				"<"
			)
		) {
			//^ if firmwareVersionMinor < 4 then send sha instead of hashArray
			conditionalEncryption = sha
		} else {
			conditionalEncryption = hashArray
		}
	})

	let answer = await sendStartPutCmd(
		instance,
		port,
		filename,
		replace,
		file.length,
		conditionalEncryption
	)
	// console.log(answer)

	if (answer === undefined || answer.status !== 0) {
		throw new Error("invalid status answer" + answer.status)
	}

	//# remember fileSize
	const fileSize = file.length
	let filesliceSize = 0
	let lastProce = 0

	let dataArr = new Uint8Array(sliceSize)
	for (let fileIndex = 0; fileIndex < fileSize; ) {
		if (fileSize - fileIndex > sliceSize) {
			filesliceSize = sliceSize
		} else {
			filesliceSize = fileSize - fileIndex
		}
		dataArr = file.slice(fileIndex, fileIndex + filesliceSize)
		answer = await sendPutFileSliceCmd(
			instance,
			port,
			fileIndex,
			filesliceSize,
			dataArr
		)
		if (answer.status === 3) {
			//# Error sending packet-> we try again
			if (retry > 2) {
				throw new Error("Sending packet failed" + answer)
			}
			answer.status = 0
			retry++
		} else {
			retry = 0
			fileIndex = fileIndex + filesliceSize
		}
		if (lastProce < fileIndex / fileSize - 0.01) {
			lastProce = fileIndex / fileSize
			// console.log("progress:" + Math.ceil(lastProce * 100) + "%")
		}
		//^ report progress, onProgress callback delivers the progress value to the parent component
		onProgress(Math.ceil(lastProce * 100))

		let localeFromLocalStorage = localStorage.getItem("locale") || "de"

		if (answer === undefined) {
			// console.log(localeFromLocalStorage)

			toast.error(
				localeFromLocalStorage === "en"
					? "File not found"
					: localeFromLocalStorage === "fr"
						? "Aucun fichier trouvÃ©"
						: "Keine Datei gefunden"
			)
			throw new Error("invalid status answer", answer)
		}
	}
	// console.log("SENT SUCCESSFULLY")
}

//TODO: UNUSED
function saveAs(blob, filename) {
	if (window.navigator.msSaveOrOpenBlob) {
		// IE11 and Edge
		window.navigator.msSaveOrOpenBlob(blob, filename)
	} else {
		// other browsers
		const url = URL.createObjectURL(blob)
		const a = document.createElement("a")
		a.href = url
		a.download = filename
		document.body.appendChild(a)
		a.click()
		setTimeout(() => {
			document.body.removeChild(a)
			window.URL.revokeObjectURL(url)
		}, 0)
	}
}
