import React from "react"
import UserManagement from "../../Auth/UserManagement/UserManagement.jsx"

const UserManagementView = () => {
	return (
		<>
			<UserManagement />
		</>
	)
}

export default UserManagementView
