// src/pages/SignInPage.js

import React from "react"
import LogIn from "../components/Auth/Login/LogIn.jsx"
import "firebaseui/dist/firebaseui.css"
import { Box, Card, Center, Flex } from "@chakra-ui/react"
import SmallHeaderImg from "../components/Auth/Login/SmallHeaderImg.jsx"
import RightsDisclaimer from "./RightsDisclaimer.jsx"
import LogoMineralisLogin from "../components/Auth/Login/LogoMineralisLogin.jsx"

const PageLogIn = () => {
	return (
		<Flex
			direction="column"
			minHeight="100vh"
			justify="space-between"
			overflow="hidden"
		>
			<Flex
				direction="column"
				flex="1"
				justify="center"
				align="center"
				mt={0}
			>
				<SmallHeaderImg />
				<LogoMineralisLogin
					color={["white", "white", "primary.500", "primary.500"]}
					cursor="pointer"
					mt={{ base: "5vh", md: "10vh", lg: "0" }}
				/>
				<Card
					mt={{ base: "4", lg: "6" }}
					maxW={"max-content"}
					className={"sunkenCard"}
					mx={"auto"}
					px={12}
					py={8}
				>
					<Center>
						<LogIn />
					</Center>
				</Card>
			</Flex>
			<Box mt={4}>
				<RightsDisclaimer />
			</Box>
		</Flex>
	)
}

export default PageLogIn
