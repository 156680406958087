import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  setGlobalFilter,
  getGroupedRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Flex,
  Select,
  Input,
  Text,
  IconButton,
  Alert,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  HStack,
  ButtonGroup,
  InputGroup,
  InputLeftAddon,
  Tooltip
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from "@chakra-ui/icons";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { IoMdSearch } from "react-icons/io";
import { LuUsers } from "react-icons/lu";
import DecoratorTriangleButtons from "../../GUI/DecoratorTriangleButtons";
import DecoratorTriangleButtonResizableFlipped from "../../GUI/DecoratorTriangleButtonResizableFlipped";
import DecoratorTriangleInputSelectResizable from "../../GUI/DecoratorTriangleInputSelectResizable";
import DotSpinner from "../../GUI/DotSpinner";
import { useQueryClient } from "@tanstack/react-query";
import {
  LiaSortAmountDownAltSolid,
  LiaSortAmountUpAltSolid
} from "react-icons/lia";
import { useTranslate } from "react-polyglot";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../contexts/AuthContext";
import EditableCell from "./EditableCell";
import ProjectCreator from "./ProjectBadge/ProjectCreator";
import ProjectBadgeIcon from "./ProjectBadge/ProjectBadgeIcon";
import { useDevices, refetch } from "../../../api/fetchHooks/useDevices"
// import { useProjects } from '../../../api/fetchHooks/useProjects';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './scrollableTableCell.css';
import { useProjects } from '../../../api/fetchHooks/useProjects';
import { useConnectionStore } from '../../../api/zustandStores/WebSerialStores/isConnectedStore';



export default function DevicesTable() {
  const t = useTranslate();
  const queryClient = useQueryClient();
  const { userClaims } = useAuth();
  const [tenantId, setTenantId] = useState(userClaims?.tenantId || "");
  const [isUserClaimsLoaded, setIsUserClaimsLoaded] = useState(false);
  const [tenants, setTenants] = useState([]);

  const { data: projectsData } = useProjects(tenantId);

  const isConnected = useConnectionStore((state) => state.isConnected);

  useEffect(() => {
    if (isConnected) {
      queryClient.invalidateQueries(['devices', tenantId]);
      queryClient.invalidateQueries(['projects', tenantId]);
    }
  }, [isConnected, tenantId, queryClient]);

  useEffect(() => {
    if (userClaims) {
      setTenantId(userClaims.tenantId || "");
      setIsUserClaimsLoaded(true);
    }
  }, [userClaims]);

  const isEvisMasterTenant =
    userClaims?.role === "evisAdmin" || userClaims?.tenantId === "0000"; //info: on first load tenantId is not available yet , but role is already available

    const { data: devices, refetch,  devicesLoading: devicesLoading, isError } = useDevices(tenantId);

    

      // Update local loading state based on query loading states
  useEffect(() => {
    setIsLoading(devicesLoading || !isUserClaimsLoaded);
  }, [devicesLoading, isUserClaimsLoaded]);


  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await axios.get("/api/getTenants");
        setTenants(response.data);
      } catch (error) {
        console.error("Error fetching tenants:", error);
        toast.error("Failed to fetch tenants");
      }
    };
    fetchTenants();
  }, []);

  const handleTenantChange = (e) => {
    setTenantId(e.target.value);
  };

  //# DEVICES TABLE CONFIG - PAGINATION, GROUPING, SORTING, SEARCH
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  const [grouping, setGrouping] = useState([]);

  // State for global search
  const [globalFilter, setGlobalFilter] = useState("");

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  //# Get serial number of the clicked row
  // Update the state to include the clicked row's serialNumber
const [selectedSerialNumber, setSelectedSerialNumber] = useState(null);
const [selectedDisplayName, setSelectedDisplayName] = useState(null);
// Modify the openModal function to accept and store the serialNumber
const openModal = (project, serialNumber, displayName) => {
  setSelectedProject(project);
  setSelectedSerialNumber(serialNumber);
  setSelectedDisplayName(displayName);
  setIsModalOpen(true);
};

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
    queryClient.invalidateQueries(['projects', tenantId]);
  };

  // Add projects query
  // const { data: projectsData, devicesLoading: projectsLoading } = useProjects(tenantId);
    // Add this to debug
    // useEffect(() => {
    //   console.log("Projects Data:", projectsData);
    // }, [projectsData]);

    const [isLoading, setIsLoading] = useState(true);
  // Update local loading state based on query loading states
  useEffect(() => {
    setIsLoading(devicesLoading );
  }, [devicesLoading]);

  // Define columns inside the component to access openModal
  const createColumns = (t) => [
    {
      accessorKey: "serialNumber",
      header: () =>
        t("DevicesTable.deviceTableColumnTitleSerialnumber", {
          _: "Seriennummer"
        }),
      cell: (info) => info.getValue() || "N/A",
      size: 190,
      minSize: 190
    },
    {
      accessorKey: "deviceDisplayName",
      header: () =>
        t("DevicesTable.deviceTableColumnTitleDeviceName", {
          _: "Gerätename"
        }),
        cell: ({ getValue, row, column }) => (
          <EditableCell 
            getValue={getValue}
            row={row}
            column={column}
            refetch={refetch}
          />
      ),
      size: 220,
      minSize: 180,
      maxSize: 220,
      meta: {
        align: "left"
      },
      sortingFn: "displayNameSort", // Use `sortingFn` instead of `sortType`
    },
   {
      accessorKey: "projects",
      header: () => t("DevicesTable.deviceTableColumnTitleProject", { _: "Projekt" }),
      enableGrouping: true,
      getGroupingValue: (row) => {
        const project = row.projects?.[0];
        
        if (!project) return "No Project";
        
        // Ensure clean project number string
        const projectNumber = project.projectNumber?.toString().trim();
        const projectName = project.projectName || '';
        
        return projectNumber ? `${projectNumber} - ${projectName}` : "Invalid Project";
      },
      cell: (info) => {
        const currentProjects = info.getValue();
        const project = currentProjects?.[0];
        
        return (
          <Flex direction="row">
            {project ? (
              <ProjectBadgeIcon 
                onClick={() => openModal(project, info.row.original.serialNumber, info.row.original.deviceDisplayName)}
                projectName={project.projectName}
                projectNumber={project.projectNumber}
                badgeColor={project.badgeColor}
                marginLeft={"10px"}
              />
            ) : (
              <ProjectBadgeIcon 
                onClick={() => openModal(null, info.row.original.serialNumber, info.row.original.deviceDisplayName)}
                marginLeft={"10px"}
              />
            )}
          </Flex>
        );
      },
      size: 40,
      minSize: 30,
      maxSize: 85,
      overflow: "hidden",
      padding: "0px",
      meta: {
        align: "left"
      },
      sortingFn: "projectsSort",
      
    },
    {
      accessorFn: (row) => {
        const version = row.firmwareVersion || "N/A"
        const number = row.firmwareNumber || "N/A"
        return `${version} (${number})`
      },
      id: "firmware",
      header: () =>
        t("DevicesTable.deviceTableColumnTitleFirmware", {
          _: "Firmware"
        }),
      cell: (info) => info.getValue(),
      size: 160,
      minSize: 160,
      maxSize: 180,
      enableGrouping: true
    },
    {
      accessorFn: (row) => row.hardwarePrintNumberAndVersion || "N/A",
      id: "hardware",
      header: () =>
        t("DevicesTable.deviceTableColumnTitlePrint", {
          _: "Hardware"
        }),
      cell: (info) => info.getValue(),
      size: 120,
      minSize: 120,
      maxSize: 150,
      enableGrouping: true
    },
    {
      accessorFn: (row) => ({
        assemblyVariantAndVersion: row.assemblyVariantAndVersion
      }),
      id: "variant",
      header: () =>
        t("DevicesTable.deviceTableColumnTitleVariant", {
          _: "Variante"
        }),
      cell: (info) => {
        const { assemblyVariantAndVersion } = info.getValue();
        return `${assemblyVariantAndVersion || "N/A"}`;
      },
      size: 120,
      minSize: 120,
      maxSize: 150,
    },
    {
      accessorKey: "latestConfigDate",
      header: () =>
        t("DevicesTable.deviceTableColumnTitleLatestConfigDate", {
          _: "Zuletzt konfiguriert"
        }),
      cell: (info) =>
        info.getValue() ? new Date(info.getValue()).toLocaleString() : "N/A",
      size: 240,
      minSize: 140,
      maxSize: 240,
      sortingFn: "datetimeSort", // custom sort type for date object
      enableGlobalFilter: false
    }
  ];

//!  WORKING WITH 1 PROJECT PER DEVICE
  // const memoizedDevices = useMemo(() => {
  //   if (!devices || !projectsData) return []; // Return empty if either is not available

  //   return devices.map(device => {
  //     // Find all projects associated with the device
  //     const associatedProjects = projectsData
  //       .filter(project => project.devices.includes(device.serialNumber.toString().trim()))
  //       .map(project => ({
  //         projectName: project.projectName,
  //         projectNumber: project.projectNumber,
  //         badgeColor: project.badgeColor || "fg-disabled" // Add any other properties you need
  //       }));

  //     // Return a new object that includes device data and the array of associated projects
  //     return {
  //       ...device,
  //       projects: associatedProjects, // Store the array of associated projects
  //     };
  //   });
  // }, [devices, projectsData]);

  //! TEST WITH MULTIPLE PROJECTS PER DEVICE
  const memoizedDevices = useMemo(() => {
    if (!devices || !projectsData) return [];

    return devices.map(device => {
      // Find the first project associated with the device
      const associatedProject = projectsData
        .find(project => project.devices.includes(device.serialNumber.toString()));

      // Return a new object that includes device data and the single project
      return {
        ...device,
        projects: associatedProject ? [{
          projectName: associatedProject.projectName,
          projectNumber: associatedProject.projectNumber,
          badgeColor: associatedProject.badgeColor || "fg-disabled",
          devices: associatedProject.devices //? Add devices array to the project object
        }] : [] // Empty array if no project found
      };
    });
  }, [devices, projectsData]);

  // console.log("memoizedDevices:", memoizedDevices.slice(0, 2)); // Show first two items


  const columns = useMemo(() => createColumns(t), [t]);

  // const memoizedDevices = useMemo(() => devices || [], [devices]);
  const skipPageResetRef = useRef(false);

  

    // Define custom sorting functions
    const sortingFns = useMemo(
      () => ({
        displayNameSort: (rowA, rowB, columnId) => {
          const a = rowA.getValue(columnId);
          const b = rowB.getValue(columnId);
  
          // Helper function to check if a value is empty
          const isEmpty = (value) => value === null || value === undefined || value === "";
  
          const aIsEmpty = isEmpty(a);
          const bIsEmpty = isEmpty(b);
  
          // Handle empty values
          if (aIsEmpty && !bIsEmpty) return 1;   // a is empty, b is not -> a after b
          if (!aIsEmpty && bIsEmpty) return -1;  // b is empty, a is not -> b after a
          if (aIsEmpty && bIsEmpty) return 0;    // both empty -> maintain relative position
  
          // Helper function to extract number from string (if exists)
          const extractNumber = (str) => {
            const match = str.match(/\d+/);
            return match ? parseInt(match[0]) : null;
          };
  
          // Split strings into base text and number
          const aBase = a.replace(/\d+/g, '').trim();
          const bBase = b.replace(/\d+/g, '').trim();
          const aNum = extractNumber(a);
          const bNum = extractNumber(b);
  
          // If base texts are the same, sort by number
          if (aBase === bBase) {
            if (aNum === null && bNum === null) return 0;
            if (aNum === null) return -1;
            if (bNum === null) return 1;
            return aNum - bNum;
          }
  
          // Otherwise sort by base text
          return aBase.localeCompare(bBase, undefined, { sensitivity: 'base' });
        },
  
        projectsSort: (rowA, rowB, columnId) => {
          const aProjects = rowA.getValue(columnId);
          const bProjects = rowB.getValue(columnId);
  
          const isEmpty = (projects) => !projects || projects.length === 0;
  
          const aIsEmpty = isEmpty(aProjects);
          const bIsEmpty = isEmpty(bProjects);
  
          // Handle empty project lists: sort them after non-empty
          if (aIsEmpty && !bIsEmpty) return 1;   // a has no projects, b has projects
          if (!aIsEmpty && bIsEmpty) return -1;  // b has no projects, a has projects
          if (aIsEmpty && bIsEmpty) return 0;    // both have no projects
  
          // Assuming each device is associated with at least one project
          // We'll sort based on the first project's projectNumber
          const aProjectNumber = aProjects[0]?.projectNumber || "";
          const bProjectNumber = bProjects[0]?.projectNumber || "";
  
          // Parse projectNumber to integers for numeric comparison
          const aNum = parseInt(aProjectNumber, 10);
          const bNum = parseInt(bProjectNumber, 10);
  
          const aIsNumeric = !isNaN(aNum);
          const bIsNumeric = !isNaN(bNum);
  
          // Handle non-numeric projectNumbers by pushing them after numeric ones
          if (aIsNumeric && !bIsNumeric) return -1; // a numeric, b non-numeric -> a before b
          if (!aIsNumeric && bIsNumeric) return 1;  // b numeric, a non-numeric -> b before a
          if (!aIsNumeric && !bIsNumeric) {
            // If both are non-numeric, fallback to localeCompare
            return aProjectNumber.localeCompare(bProjectNumber, undefined, { sensitivity: 'base' });
          }
  
          // Both projectNumbers are numeric
          return aNum - bNum;
        },
  
        datetimeSort: (rowA, rowB, columnId) => {
          const aDate = rowA.getValue(columnId);
          const bDate = rowB.getValue(columnId);
  
          // Helper function to check if a date is empty or marked as 'N/A'
          const isEmpty = (date) => {
            if (date === null || date === undefined || date === "") {
              return true;
            }
            if (typeof date === 'string' && date.trim().toLowerCase() === 'n/a') {
              return true;
            }
            return false;
          };
  
          const aIsEmpty = isEmpty(aDate);
          const bIsEmpty = isEmpty(bDate);
  
          // Handle empty dates: sort them before non-empty dates
          if (aIsEmpty && !bIsEmpty) return -1;   // a is empty, b is not -> a before b
          if (!aIsEmpty && bIsEmpty) return 1;    // b is empty, a is not -> b before a
          if (aIsEmpty && bIsEmpty) return 0;     // both are empty -> maintain relative position
  
          const aParsed = new Date(aDate);
          const bParsed = new Date(bDate);
  
          // Handle invalid date parsing
          const aValid = !isNaN(aParsed);
          const bValid = !isNaN(bParsed);
  
          if (!aValid && bValid) return 1;     // a is invalid, b is valid -> a after b
          if (aValid && !bValid) return -1;    // b is invalid, a is valid -> b after a
          if (!aValid && !bValid) return 0;    // both are invalid -> maintain relative position
  
          if (aParsed < bParsed) return -1;
          if (aParsed > bParsed) return 1;
          return 0;
        }
      
      }),
      []
    );

  const table = useReactTable({
    columns,
    data: memoizedDevices,
    getRowId: (originalRow) => originalRow.uuid,
    initialState: {
      columnOrder: [
        "serialNumber",
        "deviceDisplayName",
        "projects",
        "firmware",
        "hardware",
        "variant",
        "latestConfigDate"
      ],
      columnVisibility: {
        serialNumber: true,
        deviceDisplayName: true,
        projects: false, //!TODO: add projects back in
        firmware: true,
        hardware: true,
        variant: true,
        latestConfigDate: true
      },

      expanded: true,
      sorting: [
        {
          id: "serialNumber",
          asc: true
        }
      ],
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize
      },
      defaultColumn: {
        minSize: 50,
        maxSize: 500
      },
      enableColumnResizing: true,
      columnResizeMode: "onChange",
      // grouping: [],
      expanded: {},
      globalFilter: "" // Initialize globalFilter
    },
    state: {
      pagination,
      grouping,
      globalFilter, // Add globalFilter to table state
      autoResetPageIndex: !skipPageResetRef.current
    },
    onPaginationChange: setPagination,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter, // Handle global filter changes
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // globalFilterFn: globalFilterFunction, // Use the custom filter function
    globalFilterFn: "includesString", //!! Use this tanstack filter function
    sortingFns, // Pass custom sorting functions here
    meta: {
      updateData: (serialNumber, columnId, value) => {
        // console.log(
        //  `Updated ${columnId} for device ${serialNumber} to ${value}`
        // )
      }
    },
    autoResetPageIndex: false,
    autoResetExpanded: false,
    autoResetSorting: false,
    autoResetSelectedRows: false,
    autoResetFilters: false,
  });

  useEffect(() => {
    if (skipPageResetRef.current) {
      skipPageResetRef.current = false;
    }
  }, [memoizedDevices]);

  const handleGroupingChange = (selectedOptions) => {
    setGrouping(selectedOptions);
  };

  // Update the handler for global filter change
  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(String(e.target.value));
  };

  const invalidateProjects = () => {
    queryClient.invalidateQueries(['projects', tenantId]);
  };

  if (!isUserClaimsLoaded ) {
    return (
      <Flex
        mt={"15px"}
        gap={6}
        justifyContent="center"
        alignItems="center"
      >
        <DotSpinner />
        <Text>Loading</Text>
      </Flex>
    );
  }
  // if (!isEvisMasterTenant) {
  //  return <DotSpinner />
  // }

  if (devicesLoading) {
    return (
      <Flex
        mt={"15px"}
        gap={6}
        justifyContent="center"
        alignItems="center"
      >
        <DotSpinner />
        <Text>Loading</Text>
      </Flex>
    );
  }

  if (isError) {
    return (
      <Alert status="error">
        {t("DevicesTable.deviceTableError", {
          _: "Fehler beim Laden der Geräte"
        })}
      </Alert>
    );
  }

   // Check if both devices and projects are loading
   if (devicesLoading ) {
    return (
      <Flex mt={"15px"} gap={6} justifyContent="center" alignItems="center">
        <DotSpinner />
        <Text>Loading...</Text>
      </Flex>
    );
  }

    // If loading, show spinner
    if (isLoading) {
      return (
        <Flex mt={"15px"} gap={6} justifyContent="center" alignItems="center">
          <DotSpinner />
          <Text>Loading...</Text>
        </Flex>
      );
    }

  return (
    <Box
      mt={4}
      pb={24}
      ml={{ base: -4, md: -6 }}
      width="auto"
      maxW="max-content"
    >
      {/* //# BUTTONS TABLE */}

      <Menu closeOnSelect={false}>
        <ButtonGroup
          // variant="outline"
          spacing="8"
          ml={2}
        >
          {/* Tenant Selection */}
          {isEvisMasterTenant && (
            <Flex
              mb={4}
              alignItems="center"
              ml={6}
            >
              <InputGroup>
                <Tooltip
                  label={t("DevicesTable.deviceTableTenantSelectionTooltip", {
                    _: "Distributor auswählen"
                  })}
                  hasArrow={true}
                  bg="tooltip-bg"
                  aria-label="tooltip showing current configuration format"
                  placement="top-start"
                  openDelay={300}
                  closeDelay={200}
                >
                  <InputLeftAddon
                    background={"field-addon-bg"}
                    height="24px"
                    border={"none"}
                  >
                    <LuUsers
                      size={16}
                      height="24px"
                      fontWeight={"bold"}
                    />
                  </InputLeftAddon>
                </Tooltip>
                <Select
                  value={tenantId}
                  onChange={handleTenantChange}
                  mr={4}
                  width="max-content"
                  height="24px"
                  border={"none"}
                  cursor="pointer"
                >
                  {tenants.map((tenant) => (
                    <option
                      key={tenant.tenantId}
                      value={tenant.tenantId}
                    >
                      {tenant.tenantId} - {tenant.tenantName}
                    </option>
                  ))}
                </Select>
                <DecoratorTriangleButtonResizableFlipped
                  backgroundColor="bg-default"
                  borderColor="bg-default"
                  decoratorTriangleHeight={"26px"}
                  decoratorTriangleWidth={"30px"}
                  decoratorTriangleMl={"-45px"}
                />
              </InputGroup>
            </Flex>
          )}

          {/* //# Grouping */}
          <Flex
            mb={4}
            alignItems="center"
          >
            <Tooltip
              label={t("DevicesTable.deviceTableGroupingTooltip", {
                _: "Gruppieren"
              })}
              hasArrow={true}
              bg="tooltip-bg"
              aria-label="tooltip showing current configuration format"
              placement="top-start"
              openDelay={300}
              closeDelay={200}
            >
              <Box
                background={"field-addon-bg"}
                height="24px"
                mt={"1px"}
                px={3.5}
              >
                <HiOutlineRectangleGroup
                  size={22}
                  mr={2}
                  color={"white"}
                  opacity={0.85}
                />
              </Box>
            </Tooltip>
            <MenuButton
              as={Button}
              height="24px"
              border={"none"}
            >
              {grouping.length > 0 ? (
                t("DevicesTable.deviceTableColumnTitleGroupedBy", {
                  _: "Gruppiert nach"
                }) +
                " " +
                grouping.join(" & ")
              ) : (
                <Flex alignItems="center">
                  <HStack gap={2}>
                    <Text>
                      {t("DevicesTable.deviceTableGrouping", {
                        _: "Gruppieren"
                      })}
                    </Text>
                  </HStack>
                </Flex>
              )}
            </MenuButton>
            <DecoratorTriangleButtons
              backgroundColor="btn-primary-hover-bg"
              borderColor="btn-primary-border"
            />
          </Flex>

          {/* //# Search */}
          <Flex
            mb={4}
            alignItems="center"
          >
            <InputGroup>
              <Tooltip
                label={t("DevicesTable.deviceTableSearchTooltip", {
                  _: "Tabelle durchsuchen"
                })}
                hasArrow={true}
                bg="tooltip-bg"
                aria-label="tooltip showing current configuration format"
                placement="top-start"
                openDelay={300}
                closeDelay={200}
              >
                <InputLeftAddon
                  background={"field-addon-bg"}
                  height="24px"
                >
                  <IoMdSearch
                    size={18}
                    height="25px"
                  />
                </InputLeftAddon>
              </Tooltip>
              <Input
                placeholder={t("DevicesTable.deviceTableSearchPlaceholder", {
                  _: "Suche..."
                })}
                sx={{
                  "&::placeholder": {
                    color: "field-placeholder-text"
                  }
                }}
                value={globalFilter}
                onChange={handleGlobalFilterChange}
                maxW="300px"
                height="24px"
                border={"none"}
                width="176px"
              />
              <DecoratorTriangleButtons
                backgroundColor="btn-primary-hover-bg"
                borderColor="btn-primary-border"
              />
            </InputGroup>
          </Flex>
        </ButtonGroup>
        <MenuList minWidth="240px">
          <MenuOptionGroup
            type="checkbox"
            value={grouping}
            onChange={handleGroupingChange}
          >
            <MenuItemOption value="firmware">Firmware</MenuItemOption>
            <MenuItemOption value="hardware">Hardware</MenuItemOption>
            <MenuItemOption value="projects">{t("DevicesTable.deviceTableColumnTitleProject", { _: "Projekt" })}</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      <Table
        variant="simple"
        styles={{
          tr: {
            "&:nth-of-type(odd)": {
              backgroundColor: "rgba(0, 0, 0, 0.05)"
            }
          }
        }}
        size="md"
        style={{ tableLayout: "auto", width: "auto" }}
      >
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  subHeaders={header.subHeaders}
                  textAlign={
                    header.column.columnDef.meta?.align === "left"
                      ? "left"
                      : "right"
                  }
                  style={{
                    width: header.getSize(),
                    minWidth: header.column.columnDef.minSize,
                    maxWidth: header.column.columnDef.maxSize
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent={
                      header.column.columnDef.meta?.align === "left"
                        ? "flex-start"
                        : "flex-end"
                    }
                    pr={0}
                    cursor={header.column.getCanSort() ? "pointer" : "default"}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.column.columnDef.meta?.align !== "left" && (
                      <Box mr={2}>
                        {{
                          asc: <LiaSortAmountUpAltSolid size={18} />,
                          desc: <LiaSortAmountDownAltSolid size={18} />
                        }[header.column.getIsSorted()] ?? null}
                      </Box>
                    )}
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.columnDef.meta?.align === "left" && (
                      <Box ml={2}>
                        {{
                          asc: <LiaSortAmountUpAltSolid size={18} />,
                          desc: <LiaSortAmountDownAltSolid size={18} />
                        }[header.column.getIsSorted()] ?? null}
                      </Box>
                    )}
                  </Flex>
                  {header.column.getCanResize() && (
                    <Box
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className={`resizer ${
                        header.column.getIsResizing() ? "isResizing" : ""
                      }`}
                    />
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            if (row.getIsGrouped()) {
              // Render grouped row
              return (
                <Tr key={row.id} >
                  <Td colSpan={table.getAllLeafColumns().length}>
                    <Flex alignItems="start">
                      <IconButton
                        icon={row.getIsExpanded() ? <TriangleUpIcon /> : <TriangleDownIcon />}
                        onClick={row.getToggleExpandedHandler()}
                        aria-label="Toggle group"
                        size="sm"
                        mr={2}
                        pb={2.5}
                        bg="bg-default"
                        _hover={{
                          bg: "bg-default"
                        }}
                      />
                      {row.original.projects.length > 0 ? (
                        <Text>
                          {"project:"} {row.original.projects[0].projectNumber} ({row.subRows.length})
                        </Text>
                      ) : (
                        <Text>
                          {"not assigned to any project"}
                        </Text>
                      ) }
                    </Flex>
                  </Td>
                </Tr>
              );
            }

            // Render normal rows
            return (
              <>
              <Tr key={row.id} ml={12}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} 
                  id="scrollable-element"
                  textAlign="end"
                  py={1.5}
                  style={{
                    width: cell.column.getSize(),
                    minWidth: cell.column.columnDef.minSize,
                    maxWidth: cell.column.columnDef.maxSize,
                    overflowX: 'auto', // Enable horizontal scrolling
                    whiteSpace: 'nowrap', // Prevent text wrapping
                      
                  }}
                  // className="scrollable-element"
                 
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>

                  // <Td
                  //   key={cell.id}
                  //   textAlign="end"
                  //   py={1.5}
                  //   style={{
                  //     width: cell.column.getSize(),
                  //     minWidth: cell.column.columnDef.minSize
                  //   }}
                  // ></Td>
                  
                ))}
              </Tr>
              </>
            );
          })}
        </Tbody>
      </Table>
      {/* //# Pagination - Rows per page */}
      <Flex
        mt={8}
        height="26px"
        alignItems="center"
        justifyContent="space-between"
        fontSize="sm"
      >
        <Flex
          alignItems="center"
          ml={6}
        >
          <Text
            mr={3}
            whiteSpace="nowrap"
          >
            {t("DevicesTable.deviceTablePaginationRowsPerPage", {
              _: "Zeilen pro Seite"
            })}
          </Text>
          <Flex alignItems="center">
            <Select
              size="sm"
              height="26px"
              width="70px"
              value={pagination.pageSize}
              onChange={(e) => {
                setPagination((prev) => ({
                  ...prev,
                  pageSize: Number(e.target.value)
                }));
              }}
            >
              {[5, 10, 20, 50, 100].map((pageSize) => (
                <option
                  key={pageSize}
                  value={pageSize}
                >
                  {pageSize}
                </option>
              ))}
            </Select>
            <DecoratorTriangleInputSelectResizable
              backgroundColor={"bg-default"}
              decoratorTriangleHeight={"26px"}
              decoratorTriangleWidth={"30px"}
              decoratorTriangleMl={"-30px"}
            />
          </Flex>
        </Flex>
        {/* //# Pagination - Page index */}
        <Flex
          alignItems="center"
          mr={5}
        >
          <IconButton
            icon={<ArrowLeftIcon fontSize={"12px"} />}
            onClick={() => setPagination((prev) => ({ ...prev, pageIndex: 0 }))}
            isDisabled={!table.getCanPreviousPage()}
            mr={2}
            size="sm"
            height="26px"
            aria-label={"firstPage"}
            borderRadius={"3px"}
          />
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() =>
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex - 1
              }))
            }
            isDisabled={!table.getCanPreviousPage()}
            mr={2}
            size="sm"
            height="26px"
            aria-label={"previousPage"}
            borderRadius={"3px"}
          />
          <Flex
            alignItems="center"
            mx={2}
          >
            <Input
              type="number"
              min={1}
              max={table.getPageCount()}
              value={pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                setPagination((prev) => ({ ...prev, pageIndex: page }));
              }}
              size="sm"
              height="26px"
              width="42px"
              mr={2}
              borderRadius={"3px"}
            />
            <Text>
              {t(
                "DevicesTable.deviceTablePaginationIndexText",
                {
                  _: "von"
                },
                { count: table.getPageCount() }
              )}{" "}
              {table.getPageCount()}
            </Text>
          </Flex>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() =>
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1
              }))
            }
            isDisabled={!table.getCanNextPage()}
            ml={2}
            size="sm"
            height="26px"
            aria-label={"nextPage"}
            borderRadius={"3px"}
          />
          <IconButton
            icon={<ArrowRightIcon fontSize={"12px"} />}
            onClick={() =>
              setPagination((prev) => ({
                ...prev,
                pageIndex: table.getPageCount() - 1
              }))
            }
            isDisabled={!table.getCanNextPage()}
            ml={2}
            size="sm"
            height="26px"
            aria-label={"lastPage"}
            borderRadius={"3px"}
          />
        </Flex>
      </Flex>
      {/* Render the ProjectCreator */}
      <ProjectCreator
        isOpen={isModalOpen}
        onClose={closeModal}
        project={selectedProject}
        serialNumber={selectedSerialNumber}
        displayName={selectedDisplayName}
        tenantId={tenantId}
        projects={projectsData || []}
        // isLoading={projectsLoading}
        onProjectsUpdate={invalidateProjects}
      />
    </Box>
  );
}