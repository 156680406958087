import React, { useState, useEffect } from "react"
import { Input } from "@chakra-ui/react"
import axios from "axios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { useAuth } from "../../../contexts/AuthContext"

const EditableCell = ({ getValue, row, column }) => {
	const initialValue = getValue()
	const [value, setValue] = useState(initialValue)
	const { userClaims } = useAuth()
	const queryClient = useQueryClient()

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	const { mutate } = useMutation({
			mutationFn: (updatedData) => 
				axios.post("/updateDeviceField", updatedData),
			onSuccess: () => {
				queryClient.invalidateQueries(["devices", userClaims.tenantId])
				toast.success("Device updated successfully")
			},
			onError: (err) => {
				console.error("Error updating device field:", err)
				toast.error("Failed to update device")
				setValue(initialValue)
			}
		})

	const onBlur = () => {
		if (value !== initialValue) {
			mutate({
				serialNumber: row.original.serialNumber,
				field: column.id,
				value: value,
				tenantId: userClaims.tenantId,
			})
		}
	}

	return (
		<Input
			variant="flushed"
			placeholder="Gerätename"
			_placeholder={{ opacity: 0.65 }}
			focusBorderColor="#1d846f"
			borderColor="#1d846f"
			fontSize="sm"
			textAlign="start"
			fontWeight="bold"
			textTransform="uppercase"
			width="100%"
			value={value || ""}
			onChange={(e) => setValue(e.target.value)}
			onBlur={onBlur}
		/>
	)
}

export default EditableCell
