// client/src/components/Auth/SignUp.jsx
import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
	Button,
	Input,
	Box,
	FormControl,
	FormLabel,
	Alert,
	Flex
} from "@chakra-ui/react"
import { useAuth } from "../../../contexts/AuthContext.js"
import { useTranslate } from "react-polyglot"
import DecoratorTriangleButtonResizable from "../../GUI/DecoratorTriangleButtonResizable.jsx"
import { toast } from "react-toastify"

const ResetPasswordRemote = () => {
	const emailRef = useRef()
	const [email, setEmail] = useState("")

	const passwordRef = useRef()
	const passwordConfirmRef = useRef()

	const { resetPassword } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const t = useTranslate()

	const handleSubmit = (e) => {
		e.preventDefault()
		const email = emailRef.current.value

		if (email) {
			resetPassword(email)
				.then(() => {
					toast.success(t("Toasts.toastResetPasswordSuccess"), {
						_: "Passwort zurückgesetzt (falls email vorhanden)"
					})
					// Reset the email field after successful submission
					setEmail("")
				})
				.catch((error) => {
					if (error.message === "Firebase: Error (auth/invalid-email).") {
						setError(t("Toasts.toastInvalidEmail"), {
							_: "Ungültiges Emailformat"
						})
					}
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			setError("Please enter an email address")
		}
	}

	return (
		<Box
			alignContent={"center"}
			alignItems={"center"}
		>
			{error && (
				<Alert
					mb={4}
					status="error"
					color={"white"}
					backgroundColor={"#A63C32"}
					maxW={"max-content"}
					height={"2rem"}
				>
					{error}
				</Alert>
			)}

			<form onSubmit={handleSubmit}>
				<Flex
					flexDirection={{ base: "column", md: "row" }}
					flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
					gap={5}
					alignItems={{ base: "start", lg: "end" }}
					rowGap={{ base: 3, md: 3 }}
				>
					<FormControl
						id="emailResetPassword"
						name="emailResetPassword"
					>
						<FormLabel mb={0}>Email</FormLabel>
						<Input
							size={"sm"}
							variant={"secondary"}
							placeholder={t("InputPlaceholders.placeholderTenantUserEmail", {
								_: "MaxMustermann@email.ch"
							})}
							type="email"
							value={email}
							ref={emailRef}
							onChange={(e) => setEmail(e.target.value)}
							required
							width={"250px"}
						/>
					</FormControl>

					<Flex mt={{ base: 2, md: 2, lg: 0 }}>
						<Button
							type="submit"
							disabled={loading}
							height={"2rem"}
							width={"max-content"}
							px={4}
						>
							{t("AddTenant.updatePassword", {
								_: "Bestätigen"
							})}
						</Button>

						<DecoratorTriangleButtonResizable
							backgroundColor="btn-primary-hover-bg"
							borderColor="btn-primary-border"
							decoratorTriangleHeight={"2rem"}
							decoratorTriangleWidth={"2.2rem"}
						/>
					</Flex>
				</Flex>
			</form>
		</Box>
	)
}

export default ResetPasswordRemote
