import axios from "axios"

const addNewTenantToDeviceOfTenant = async (tenantId, serialNumber) => {
	try {
		const url = "/addNewTenantToDeviceOfTenant"
		const payload = {}
		payload.tenantId = tenantId
		payload.serialNumber = serialNumber

		const response = await axios.post(url, payload)
		return response.data
	} catch (error) {
		console.log(error)
	}
}

export default addNewTenantToDeviceOfTenant
