import { create } from "zustand"
import { persist } from "zustand/middleware"

const useCurrentUserStore = create(
	persist(
		(set) => ({
			currentUserData: null,
			setCurrentUserData: (data) => set({ currentUserData: data }),
			updateUserRole: (newRole) =>
				set((state) => ({
					currentUserData: {
						...state.currentUserData,
						role: newRole
					}
				}))
		}),
		{
			name: "current-user-store"
		}
	)
)

export default useCurrentUserStore
