import React from "react"
import { Flex } from "@chakra-ui/react"

import headerBG_small from "../../GUI/images/gui/header/bgHeader_small_trans.png"

const SmallHeaderImg = (props) => {
	return <NavBackground {...props}></NavBackground>
}

const NavBackground = ({ ...props }) => {
	return (
		<>
			<Flex
				{...props}
				className={"scrolled"}
				position="fixed"
				top={{ base: "0", md: "0", lg: "0" }}
				right={0}
				zIndex={999}
				backgroundImage={headerBG_small}
				backgroundSize={"cover"}
				backgroundRepeat={"no-repeat"}
				backgroundPosition={"end"}
				backgroundClip={"border-box"}
				backgroundBlendMode={"multiply"}
				w={{ base: "100%", md: "100%", lg: "100%" }}
				h={{ base: "115px", md: "125px", lg: "110px" }}
				transition={"all linear 0.1s"}
			></Flex>
		</>
	)
}

export default SmallHeaderImg
