import { useState } from "react";
import { FormControl, FormLabel, Input, Button, Alert, Box, HStack, VStack, Flex } from "@chakra-ui/react";
import { useTranslate } from "react-polyglot";
import { PiHexagon } from "react-icons/pi";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../../contexts/AuthContext";


export default function AddProjectForm({ formType, projectName, setProjectName, selectedProject, setSelectedProject, selectedColor, setSelectedColor, projectToEdit, setProjectToEdit, onProjectsUpdate, onClose, tenantId, serialNumber, projects, resetForm }) {
  
    const t = useTranslate();
    const { userClaims } = useAuth();
  const [projectNumber, setProjectNumber] = useState('');


  

  const handleProjectNumberChange = (e) => {
    if (formType === 'edit') {
      return;
    }
    // setProjectNumber(e.target.value.replace(/\D/g, ''));
    const value = e.target.value.replace(/\D/g, ''); // Remove non-digits
    if (value.length <= 4) {
      setProjectNumber(value);
    }
  };

  const [isProjectNumberTouched, setIsProjectNumberTouched] = useState(false);
  const isProjectNumberValid = projectNumber.length === 4;
  const showProjectNumberError = isProjectNumberTouched && !isProjectNumberValid;

  const colors = [
    ['#D83D3D', '#FF1493', '#FF6F61', '#FC8175', '#FBA9A1', '#FFA500', '#FFD700', '#B87333', '#8B5B29', '#32CD32', '#3CB371'],
    [ '#6B8E23', '#00CED1','#00BFFF', '#4B8BBE', '#6495ED', '#9966CC', '#A75B9A', '#8B008B', '#B0E0E6', '#C0C0C0', '#6E6E6E']
  ];
  

const handleSave = async () => {
    try {
      if (selectedProject === 'new') {
        //^ CASE CREATE NEW PROJECT
        const response = await axios.post("/updateProjects", {
          tenantId: tenantId,
          serialNumber: serialNumber.toString(),
          projectName,
          projectNumber,
          badgeColor: selectedColor
        });

        const newProject = {
          projectNumber: response.data.projectNumber,
          name: projectName,
          number: projectNumber,
          color: selectedColor,
          serialNumber: serialNumber
        };
        // setProjects([...projects, newProject]); //info: only for local mock data
        toast.success(t("ProjectCreator.successCreate", { _: "Projekt gespeichert" }));
      } else {
        //^ CASE ASSIGN DEVICE TO EXISTING PROJECT WITH + BUTTON
        // Just associate the device with existing project
        const existingProject = projects.find(p => p.id === selectedProject);
        
        await axios.post("/assignDeviceToExistingProject", {
          tenantId: userClaims.tenantId,
          projectNumber: projectToEdit.toString(),
          serialNumber: serialNumber.toString(),
          projectName: projectName,
        //   projectNumber: projectNumber,
          badgeColor: selectedColor
        });

        toast.success(t("ProjectCreator.successAssign", { 
          _: `Gerät wurde Projekt ${projectNumber} zugewiesen` 
        }));
      }

        // Invalidate projects query to trigger a refetch
        onProjectsUpdate();
      

      resetForm();
      onClose();
    } catch (error) {
      console.error("Error:", error);
      if (error.response?.status === 409) {
        toast.error(error.response.data.message);
      } else {
        const errorMessage = error.response?.data?.details || 
                            error.response?.data?.error || 
                            selectedProject === 'new' 
                              ? t("ProjectCreator.errorCreate", { _: "Fehler beim Erstellen des Projekts" })
                              : t("ProjectCreator.errorAssign", { _: "Fehler beim Zuweisen des Geräts" });
        toast.error(errorMessage);
      }
    }
  };


  return (
    <>
      {/* //# Project Number */}
      <FormControl isRequired isInvalid={showProjectNumberError}>
            <FormLabel>{t("ProjectCreator.projectNumber", { _: "Projektnummer" })}</FormLabel>
                      <Input
                        // value={projectNumber}
                        value={formType === 'edit' ? projectToEdit : projectNumber} 
                        isDisabled={formType === 'edit'}
                        onChange={handleProjectNumberChange}
                        onBlur={() => setIsProjectNumberTouched(true)}
                        autoFocus
                        placeholder={t("ProjectCreator.projectNumberPlaceholder", { _: "4-stellige Projektnummer eingeben" })}
                        sx={{
                          "&::placeholder": {
                            color: "field-placeholder-text"
                          }
                        }}
                        maxLength={4}
                        height={"2rem"}
                      />
                       {showProjectNumberError && (
                      <Alert px={3} status='error' bg="field-error-bg" color="white" mt={2} size="sm" paddingY={1} >
                        {t("ProjectCreator.projectNumberError", { _: "Projektnummer muss genau 4 Ziffern sein" })}
                      </Alert>
                      )}
                    </FormControl>

                    {/* //# Project Name */}
                    <FormControl isRequired>
                      <FormLabel>{t("ProjectCreator.projectName", { _: "Projektname" })}</FormLabel>
                      <Input
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        placeholder={t("ProjectCreator.projectNamePlaceholder", { _: "Projektname eingeben" })}
                        sx={{
                          "&::placeholder": {
                            color: "field-placeholder-text"
                          }
                        }}
                        height={"2rem"}
                      />
                    </FormControl>

                    {/* //# Badge Color */}
                    <FormControl isRequired>
                      <FormLabel>{t("ProjectCreator.badgeColor", { _: "Badgefarbe" })}</FormLabel>
                      <VStack spacing={2} align="stretch">
                        {colors.map((row, rowIndex) => (
                          <HStack key={rowIndex} spacing={2} height={"2.5rem"}>
                            {row.map((color) => (
                              <Box 
                              key={color}
                              // w={"min-content"}
                              // h={"min-content"}
                              clipPath={"polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)"}
                              _hover={{ transform: 'scale(1.1)' }}
                              
                              >
                              <PiHexagon
                                key={color}
                                size={color === selectedColor ? "2.8rem" : "2.5rem"}
                                cursor={"pointer"}
                                color={color}
                                style={{
                                  // filter: selectedColor === color ? 'brightness(1.2)' : 'none',
                                  // stroke: selectedsColor === color ? 'white' : color,
                                  strokeWidth: selectedColor === color ? '18px' : '6px'
                                }}
                                onClick={() => setSelectedColor(color)}
                                bg={selectedColor === color ? 'red' : 'none'}
                                aria-label={`Select color ${color}`}
                                zIndex={2}
                              />
                              </Box>
                            ))}
                          </HStack>
                        ))}
                      </VStack>
                      <Flex ml={0} mt={8}  justifyContent={"start"} >
                      
                      <Button variant="ghost" onClick={onClose}>
                      {t("ProjectCreator.cancelAction", { _: "Abbrechen" })}
                    </Button>
                    <Button
                      onClick={handleSave}
                      isDisabled={
                        selectedProject === 'new' || selectedProject === 'edit'
                          ? !projectName  || !selectedColor
                          : !selectedProject
                      }
                      
                    >
                        {selectedProject === 'new' ? t("ProjectCreator.createProject", { _: "Projekt erstellen" }) : t("ProjectCreator.editProject", { _: "Projekt editieren" })}
                    </Button>
                    </Flex>
              </FormControl>

           
            </>
                  
  );
}