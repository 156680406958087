import { useQuery } from "@tanstack/react-query"
import axios from "axios"

const fetchDevices = async (tenantId) => {
	const response = await axios.get("/fillDevicesTable", {
		params: { tenantId: tenantId.queryKey[1] }
	})
	return response.data
}

export const useDevices = (tenantId) => {
	return useQuery({
		queryKey: ["devices", tenantId],
		queryFn: fetchDevices,
		staleTime: 5 * 60 * 1000, // 5 minutes
		refetchOnWindowFocus: true
		// Prevent refetch on window focus
	})
}
