import React, { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"

import NavbarHeaderView from "../components/Views/NavViews/NavbarHeaderView"
import RightsDisclaimer from "./RightsDisclaimer"
import { Fade } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"

const Layout = () => {
	const [isVisible, setIsVisible] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setIsVisible(false)
		const timer = setTimeout(() => {
			setIsVisible(true)
		}, 10)

		return () => clearTimeout(timer)
	}, [location.pathname]) // Re-run effect when the path changes

	return (
		<>
			<NavbarHeaderView />

			<Outlet />
			<Fade
				// direction="bottom"
				transition={
					location.pathname !== "/" && location.pathname !== "/login"
						? { enter: { delay: 2, duration: 0.5 } }
						: null
				}
				in={isVisible}
				key={location.pathname}
			>
				<RightsDisclaimer />
			</Fade>
		</>
	)
}

export default Layout
