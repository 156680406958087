import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useProjects = (tenantId) => {
  return useQuery({
    queryKey: ['projects', tenantId],
    queryFn: async () => {
      if (!tenantId) return [];

      //^ Get projects for the tenant
      // const { data } = await axios.get(`/api/getProjects?tenantId=${tenantId}`);
      
      //^ Get projects for the tenant (alternative)
      const { data } = await axios.get(`/api/getProjects`, {
        params: { tenantId }
      });
      return data;
    },
    enabled: !!tenantId,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
}; 