import { Fade } from "@chakra-ui/react"
import { React, lazy, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
const ParameterEditorView = lazy(
	() => import("../components/Views/EditViews/ParameterEditorView")
)

// ! THIS MAKES NO SENSE BUT IT WORKS - MAKES THE LOADING SCREEN WORK PROPERLY SHOWING "Parameterdaten werden geladen" (from ParameterEditorView) INSTEAD OF "Loading" (from PageParameterEditor)
const PageParameterEditor = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [isVisible, setIsVisible] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setIsVisible(false)
		const timer = setTimeout(() => {
			setIsVisible(true)
		}, 500)

		return () => clearTimeout(timer)
	}, [location.pathname]) // Re-run effect when the path changes
	useEffect(() => {
		setIsLoading(false)
	}, [])

	return (
		<Fade
			transition={{ exit: { delay: 0.3 }, enter: { duration: 0.5 } }}
			in={isVisible}
			key={location.pathname}
		>
			<>{isLoading ? <div>Loading</div> : <ParameterEditorView />}</>
		</Fade>
	)
}

export default PageParameterEditor
