import React, { useContext, useState, useEffect } from "react"
import axios from "axios"
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	sendPasswordResetEmail,
	updatePassword as firebaseUpdatePassword,
	updateEmail as firebaseUpdateEmail,
	sendEmailVerification as firebaseSendEmailVerification,
	onAuthStateChanged
} from "firebase/auth"
import { auth } from "../components/Auth/firebase.js"
import { jwtDecode } from "jwt-decode"
// import { handleSerialDisconnect } from "../components/hooks/deviceActions"
const AuthContext = React.createContext()

export function useAuth() {
	return useContext(AuthContext)
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null)
	const [userClaims, setUserClaims] = useState(null)
	const [loadingAuth, setLoadingAuth] = useState(true)


	//! SIGN UP will be used for evisADMIN for TenantAdmin creation only (TenantUsers will be created by TenantAdmins
	//# ORIGINAL
	// function signup(email, password) {
	// 	const auth = getAuth()
	// 	return createUserWithEmailAndPassword(auth, email, password)
	// }

	//# WITH EMAIL Notification to set own password
	function signup(email, password) {
		const auth = getAuth()
		return createUserWithEmailAndPassword(auth, email, password)
	}

	//## JWT Login
		//## JWT Login
		async function login(email, password) {
			const auth = getAuth()
			try {
				const userCredential = await signInWithEmailAndPassword(
					auth,
					email,
					password
				)
				const idToken = await userCredential.user.getIdToken()
				const refreshToken = userCredential.user.refreshToken

				const response = await axios.post("/api/login", { idToken })
				
				// Store tokens
				localStorage.setItem("authToken", response.data.token)
				localStorage.setItem("refreshToken", refreshToken)
				
				return userCredential
			} catch (error) {
				console.error("Login error:", error)
				throw error
			}
		}

	//TODO: CHECK IF NEEDED OR IF IT CAUSES PROBLEMS (IS THERE EVER A AUTHTOKEN IN LOCALSTORAGE?)
	useEffect(() => {
		const token = localStorage.getItem("authToken")
		if (token) {
			const decodedToken = jwtDecode(token)
			setUserClaims(decodedToken)
		}
		// setLoadingAuth(false) //!! THIS WAS CAUSING PRIVATEROUTE TO NOT WAIT FOR USERCLAIMS
	}, [])

	//# Logout
	function logout() {
		const auth = getAuth()
		//^Clear the userClaims state
		setUserClaims(null)
		//^ Clear the authToken from localStorage
		localStorage.removeItem("authToken")
		//^ clear refreshToken from localStorage
		localStorage.removeItem("refreshToken")
		//^ clear current-user-store from localStorage
		localStorage.removeItem("current-user-store")
		//^ Clear the currentUser state
		setCurrentUser(null)
		///TODO: close device connection
	
		//^ Sign out the user
		return signOut(auth)
	}

	function resetPassword(email) {
		const auth = getAuth()
		return sendPasswordResetEmail(auth, email)
	}

	//! UPDATE EMAIL will be used for evisADMIN at some point
	// function updateEmail(currentUser, email) {
	// 	// const auth = getAuth()
	// 	console.log("updateEmail called")
	// 	return firebaseUpdateEmail(currentUser, email)
	// }

	// function sendEmailVerification(currentUser) {
	// 	return firebaseSendEmailVerification(currentUser)
	// }

	function updatePassword(currentUser, password) {
		return firebaseUpdatePassword(currentUser, password)
	}

	//^ listens to changes in the authentication state and updates the user and user claims accordingly
	useEffect(() => {
		const auth = getAuth()
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			setCurrentUser(user)
			if (user) {
				const idTokenResult = await user.getIdTokenResult(true)
				setUserClaims(idTokenResult.claims) //! THIS OVERRIDES THE USER CLAIMS STATE AND DOES CONTAIN TENANT ID
			} else {
				setUserClaims(null)
			}
			setLoadingAuth(false)
		})
		return unsubscribe
	}, [])

	//^ Reclaim guest account
	function signInAnonymously() {
		return auth.signInAnonymously().then((result) => {
			console.log("result in signInAnonymously", result)
			localStorage.setItem("guestId", result.user.uid)
			return result
		})
	}

	const value = {
		currentUser,
		userClaims,
		login,
		signup,
		logout,
		resetPassword,
		// updateEmail,
		updatePassword,
		signInAnonymously,
		// sendEmailVerification,
		loadingAuth
	}

	return (
		<AuthContext.Provider value={value}>
			{!loadingAuth && children}
		</AuthContext.Provider>
	)
}
