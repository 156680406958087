import React, { useEffect, useState } from "react"
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Alert,
	Box,
	Select,
	Flex
} from "@chakra-ui/react"
import { useTranslate } from "react-polyglot"
import UserListActions from "./UserListActions.jsx"
import getUsersInTenant from "../../firestoreHelpers/getUsersInTenant.js"
import useCurrentUserStore from "../../../../api/zustandStores/userStore/currentUserStore.js"
import { useAtom } from "jotai"
import rerenderTriggerTenantUserListAtom from "../../../../api/jotaiAtoms/rerenderTriggerTenantUserListAtom.js"
import DecoratorTriangleInputSelectResizable from "../../../GUI/DecoratorTriangleInputSelectResizable.jsx"
import axios from "axios"
import { toast } from "react-toastify"

const TenantUsersList = (props) => {
	const [rerenderTriggerTenantUserList, setRerenderTriggerTenantUserList] =
		useAtom(rerenderTriggerTenantUserListAtom)

	const t = useTranslate()
	const { currentUserData, updateUserRole } = useCurrentUserStore()
	const [users, setUsers] = useState([])
	const isEvisMasterTenant = currentUserData?.role === "evisMasterTenant"
	const isEvisAdmin = currentUserData?.role === "evisAdmin"

	useEffect(() => {
		const fetchUsers = async () => {
			const tenantId =
				props.tenantIdForTenantUserList || currentUserData?.tenantId

			if (tenantId) {
				const usersList = await getUsersInTenant(tenantId)
				setUsers(usersList)
			}
		}
		fetchUsers()
	}, [
		props.tenantIdForTenantUserList,
		currentUserData?.tenantId,
		setUsers,
		rerenderTriggerTenantUserList
	])

	const [showAlert, setShowAlert] = useState(false)

	const handleRoleChange = async (
		uid,
		email,
		newRole,
		tenantId,
		displayName
	) => {
		try {
			//^ update user role in firestore
			const response = await axios.post("/api/updateUserRole", {
				uid,
				email,
				tenantId: tenantId,
				newRole,
				displayName
			})
			// console.log("response", response)
			if (response.status === 200) {
				//^ If the changed user is the current user, update the role in the Zustand store
				if (uid === currentUserData?.uid) {
					updateUserRole(newRole)

					setUsers((prevUsers) =>
						prevUsers.map((user) =>
							user.uid === uid ? { ...user, role: newRole } : user
						)
					)
				}
				toast.success(
					t("Toasts.toastUpdateUserRoleSuccess", {
						_: "Benutzerrolle geändert"
					})
				)
				//^ trigger a rerender of the TenantUsersList component
				setRerenderTriggerTenantUserList(!rerenderTriggerTenantUserList)
			}
		} catch (error) {
			console.error("Error updating user role:", error)
			toast.error("Failed to update user role")
		}
	}

	const sortedUsers = users.sort((a, b) => {
		// const roleOrder = { tenantAdmin: 1, evisAdmin: 1, user: 2 }
		const roleOrder = {
			evisMasterTenant: 1,
			evisAdmin: 2,
			// evisSupport: 3,
			tenantAdmin: 4,
			user: 5
		}
		return (roleOrder[a.role] || 2) - (roleOrder[b.role] || 2)
	})
	// console.log("sortedUsers", sortedUsers)

	// Calculate admin counts
	const adminCounts = sortedUsers.reduce(
		(counts, user) => {
			if (user.role === "evisAdmin") counts.evisAdmin++
			if (user.role === "tenantAdmin") counts.tenantAdmin++
			return counts
		},
		{ evisAdmin: 0, tenantAdmin: 0 }
	)

	useEffect(() => {
		// console.log("adminCounts", adminCounts)
	}, [adminCounts, setUsers, users])

	const highlightedRoleStyle = {
		color: "#1d846f",
		fontSize: "1.1rem",
		borderBottom: "1px solid lightgrey"
	}

	const roleStyles = {
		tenantAdmin: highlightedRoleStyle,
		evisAdmin: highlightedRoleStyle,
		evisMasterTenant: highlightedRoleStyle,
		default: { fontSize: "1.1rem", borderBottom: "1px solid lightgrey" }
	}

	return users.length > 0 ? (
		<TableContainer
			ml={-6}
			maxW={"max-content"}
			overflowX={{ base: "auto", lg: "hidden" }}
		>
			<Table
				size="md"
				variant="unstyled"
				display={{ base: "block", sm: "block", md: "table" }}
				maxW={"100%"}
				overflowX={"auto"}
				overflowY={"hidden"}
				whiteSpace={"nowrap"}
				mb={4}
			>
				<Thead display={{ base: "none", sm: "none", md: "table-header-group" }}>
					<Tr borderBottom={"1px solid lightgrey"}>
						<Th
							style={{
								fontSize: "1rem",
								paddingBottom: "0.3rem"
							}}
						>
							{t("TenantUsersList.tenantUserName", {
								_: "Name"
							})}
						</Th>
						<Th
							style={{
								fontSize: "1rem",
								paddingBottom: "0.3rem"
							}}
						>
							Email
						</Th>
						<Th
							style={{
								fontSize: "1rem",
								paddingBottom: "0.3rem"
							}}
						>
							{t("TenantUsersList.tenantUserRole", {
								_: "Role"
							})}
						</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody display={{ base: "block", sm: "block", md: "table-row-group" }}>
					{sortedUsers.map((user) => (
						<Tr
							key={user.uid}
							display={{ base: "block", sm: "block", md: "table-row" }}
							style={roleStyles[user.role] || roleStyles.default}
						>
							<Td
								style={{
									lineHeight: "1.7rem",
									paddingTop: "0.2rem",
									paddingBottom: "0.2rem"
								}}
								display={{ base: "flex", sm: "flex", md: "table-cell" }}
								flexDirection={{ base: "column", sm: "column", md: "row" }}
								minW={{ base: "100%", md: "272px" }}
							>
								<Box
									display={{ base: "flex", sm: "flex", md: "table-cell" }}
									flexDirection={{ base: "column", sm: "column", md: "row" }}
									_before={{
										content: `"${t("TenantUsersList.tenantUserName", {
											_: "Name"
										})}"`,
										fontWeight: "bold",
										display: { base: "block", sm: "block", md: "none" }
									}}
								>
									{user.displayName}
								</Box>
							</Td>
							<Td
								style={{
									lineHeight: "1.7rem",
									paddingTop: "0.2rem",
									paddingBottom: "0.2rem",
									textTransform: "lowercase"
								}}
								display={{ base: "flex", sm: "flex", md: "table-cell" }}
								flexDirection={{ base: "column", sm: "column", md: "row" }}
								_before={{
									content: `"Email"`,
									fontWeight: "bold",
									width: "100px",
									display: { base: "block", sm: "block", md: "none" }
								}}
							>
								{user.email}
							</Td>
							{isEvisAdmin ? (
								<Td
									style={{
										lineHeight: "1.7rem",
										paddingTop: "0.2rem",
										paddingBottom: "0.2rem"
									}}
									display={{ base: "flex", sm: "flex", md: "table-cell" }}
									flexDirection={{ base: "column", sm: "column", md: "row" }}
									_before={{
										content: `"${t("TenantUsersList.tenantUserRole", {
											_: "Role"
										})}"`,
										fontWeight: "bold",
										width: "100px",
										display: { base: "block", sm: "block", md: "none" }
									}}
								>
									<Flex alignItems={"center"}>
										<Select
											size="sm"
											minW={"130px"}
											height={"26px"}
											value={user.role}
											onChange={(e) => {
												//^ update user role in firestore (and if current user, update in local storage and state)
												handleRoleChange(
													user.uid,
													user.email,
													e.target.value,
													user.tenantId,
													user.displayName
												)
											}}
										>
											{/* <option value="evisMaster">Evis Master</option> */}
											{user.tenantId !== "0000" &&
											user.tenantId !== "0003" ? null : (
												<option
													value="evisAdmin"
													disabled={
														user.role === "evisAdmin" &&
														adminCounts.evisAdmin <= 1
													}
												>
													Evis Admin
												</option>
											)}
											{/* <option value="evisSupport">Evis Support</option> */}
											{user.tenantId === "0000" ||
											user.tenantId === "0003" ? null : (
												<option
													value="tenantAdmin"
													disabled={
														(user.role === "evisAdmin" &&
															adminCounts.evisAdmin <= 1) ||
														(user.role === "tenantAdmin" &&
															adminCounts.tenantAdmin <= 1)
													}
												>
													Disti Admin
												</option>
											)}
											<option
												value="user"
												disabled={
													(user.role === "evisAdmin" &&
														adminCounts.evisAdmin <= 1) ||
													(user.role === "tenantAdmin" &&
														adminCounts.tenantAdmin <= 1)
												}
											>
												User
											</option>
										</Select>
										<DecoratorTriangleInputSelectResizable
											backgroundColor={"bg-default"}
											// borderColor={"btn-primary-border"}
											decoratorTriangleHeight={"32px"}
											decoratorTriangleWidth={"32px"}
											decoratorTriangleMl={"-32px"}
										/>
									</Flex>
								</Td>
							) : (
								<Td
									style={{
										lineHeight: "1.7rem",
										paddingTop: "0.2rem",
										paddingBottom: "0.2rem"
									}}
									display={{ base: "flex", sm: "flex", md: "table-cell" }}
									flexDirection={{ base: "column", sm: "column", md: "row" }}
									_before={{
										content: `"${t("TenantUsersList.tenantUserRole", {
											_: "Role"
										})}"`,
										fontWeight: "bold",
										width: "100px",
										display: { base: "block", sm: "block", md: "none" }
									}}
								>
									{(() => {
										switch (user.role) {
											case "tenantAdmin":
											case "evisAdmin":
												return "Admin"
											// case "evisSupport":
											// 	return "evis Support"
											default:
												return "user"
										}
									})()}
								</Td>
							)}
							<Td
								style={{
									lineHeight: "1.7rem",
									paddingTop: "0.2rem",
									paddingBottom: "0.2rem"
								}}
								display={{ base: "flex", sm: "flex", md: "table-cell" }}
								flexDirection={{ base: "column", sm: "column", md: "row" }}
							>
								<UserListActions
									confirmType="deleteTenantUser"
									tenantId={currentUserData?.tenantId}
									email={user.email}
									user={user}
									adminCounts={adminCounts}
								/>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	) : (
		<>
			{showAlert && (
				<Alert
					status="warning"
					maxW={"max-content"}
					mb={2}
					mt={1}
				>
					{t("TenantUsersList.noUsers", {
						_: "No users"
					})}
				</Alert>
			)}
		</>
	)
}

export default TenantUsersList
