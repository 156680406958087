// src/pages/SignInPage.js

import React from "react"
import FirebaseAuthUI from "../components/Auth/Login/FirebaseAuthUI.jsx"
import SignUp from "../components/Auth/UserManagement/AddTenant.jsx"
import "firebaseui/dist/firebaseui.css"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton
} from "@chakra-ui/react"
import ForgotPassword from "../components/Auth/Login/ForgotPassword.jsx"

const PageForgotPassword = () => {
	return (
		<Modal
			isOpen={true}
			closeOnOverlayClick={false}
			isCentered
			// onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent
				style={{
					paddingTop: "3rem",
					paddingBottom: "3rem",
					paddingLeft: "3rem",
					paddingRight: "3rem",
					borderRadius: "0"
				}}
			>
				<ForgotPassword />
				{/* <FirebaseAuthUI /> */}
			</ModalContent>
		</Modal>
	)
}

export default PageForgotPassword
