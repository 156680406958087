import React from "react"

import { Box } from "@chakra-ui/react"

const DecoratorTriangleButtonResizableFlipped = (props) => {
	return (
		<Box
			height={props.decoratorTriangleHeight}
			width={props.decoratorTriangleWidth}
			marginInlineStart={0}
			bg={props.backgroundColor}
			clipPath="polygon(100% 0, 100% 100%, 0 100%)"
			border="1px solid"
			borderColor={props.borderColor}
			ml={props.decoratorTriangleMl}
		/>
	)
}

export default DecoratorTriangleButtonResizableFlipped
