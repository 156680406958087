import { toast } from "react-toastify"
import myModule from "./FileTransfer/TlvModules/tlvAccessModuleFile.mjs"
import { sendSystemDeviceInfoCmd } from "./FileTransfer/TlvAccessProtocol/TlvAccessCmd.js"
import checkOrSetDeviceOfTenantToDB from "../api/fetchHooks/checkOrSetDeviceOfTenantToDB.js"

export const openSerialPort = async (
	setPort,
	setIsConnected,
	setDeviceInfo,
	t,
	userClaims,
	loadingAuth
) => {
	
	let port
	try {
		// Validate required parameters
		if (typeof setPort !== 'function') {
			console.error('setPort received:', setPort)
			throw new Error('setPort must be a valid function')
		}
		if (typeof setIsConnected !== 'function') {
			throw new Error('setIsConnected must be a valid function')
		}
		if (typeof setDeviceInfo !== 'function') {
			throw new Error('setDeviceInfo must be a valid function')
		}

		// Check if port is already open
		if (port?.readable || port?.writable) {
			await port.close()
		}

		
		port = await navigator.serial.requestPort({
			filters: [{ usbVendorId: 1115 }]
		})

		await port.open({
			baudRate: 115200,
			dataBits: 8,
			parity: "none",
			bufferSize: 2000,
			flowControl: "hardware"
		})

		setPort(port)

		const instance = await myModule()
		const deviceInfoAnswer = await sendSystemDeviceInfoCmd(instance, port)
		await setDeviceInfo(deviceInfoAnswer)

		const { status, deviceOfTenant } = await checkOrSetDeviceOfTenantToDB(
			deviceInfoAnswer.serialNumber,
			userClaims,
			deviceInfoAnswer
		)

		if (status === "connect") {
			toast.info(
				t("Toasts.toastReaderConnected", {
					_: "Gerät verbunden"
				}),
				{
					limit: 1
				}
			)
			setIsConnected(true)

		} else {
			await port.close()
			setIsConnected(false)
		}

		toast.clearWaitingQueue()
		return port
	} catch (error) {
		console.error('Serial port error:', error)
		setIsConnected?.(false)
		toast.error(t("Toasts.errorConnectingDevice", {
			_: "Fehler beim Verbinden des Geräts"
		}))
		throw error // Re-throw to handle in the component
	}
}
