// client/src/components/Auth/SignUp.jsx
import React, { useRef, useState } from "react"
import {
	Link as ChakraLink,
	Button,
	Input,
	Box,
	FormControl,
	FormLabel,
	Flex
} from "@chakra-ui/react"
import { useAuth } from "../../../contexts/AuthContext.js"
import { useTranslate } from "react-polyglot"
import DecoratorTriangleButtonResizable from "../../GUI/DecoratorTriangleButtonResizable.jsx"
import { toast } from "react-toastify"

const UpdatePassword = () => {
	const emailRef = useRef()
	const passwordRef = useRef()
	const passwordConfirmRef = useRef()

	const { updatePassword, updateEmail, currentUser } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const t = useTranslate()

	function handleSubmit(e) {
		e.preventDefault()
		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			toast.error(t("Toasts.toastPasswordsDoNotMatch"), {
				_: "Passwörter stimmen nicht überein"
			})
			return
			// return setError("Passwords do not match")
		}

		const promises = []
		setLoading(true)
		setError("")

		//! UPDATE EMAIL CURRENTLY NOT WORKING DUE TO FIREBASE CHANGE AND NEED FOR CONFIRMATION EMAIL
		// if (emailRef.current.value !== currentUser.email) {
		// 	promises.push(updateEmail(currentUser, emailRef.current.value))
		// }

		if (passwordRef.current.value) {
			promises.push(updatePassword(currentUser, passwordRef.current.value))
		}

		if (passwordRef.current.value.length < 6) {
			toast.error(t("Toasts.toastPasswordTooShort"), {
				_: "Passwort muss min. 6 Zeichen lang sein"
			})
		}

		Promise.all(promises)
			.then(() => {})
			.catch(() => {
				toast.error(t("Toasts.toastUpdatePasswordError"), {
					_: "Passwortänderungen erfordern eine kürzlich erfolgtn LogIn"
				})
				setError("Failed to update account")
			})
			.finally(() => {
				if (error === "") {
					toast.success(t("Toasts.toastUpdatePasswordSuccess"), {
						_: "Passwort geändert"
					})
					// navigate("/", { replace: true })
				}
				setLoading(false)
			})
	}

	return (
		<Box
			alignContent={"center"}
			alignItems={"center"}
		>
			<form onSubmit={handleSubmit}>
				<Flex
					flexDirection={{ base: "column", md: "row" }}
					flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
					gap={5}
					alignItems={{ base: "start", lg: "end" }}
					rowGap={{ base: 3, md: 3 }}
				>
					<FormControl
						id="emailUpdatePassword"
						name="emailUpdatePassword"
						display={"none"}
					>
						<FormLabel mb={0}>Email</FormLabel>
						<Input
							size={"sm"}
							variant={"secondary"}
							type="email"
							autoComplete="email"
							ref={emailRef}
							defaultValue={currentUser?.email}
							required
							isDisabled
							width={"250px"}
						/>
					</FormControl>

					<FormControl id="password">
						<FormLabel mb={0}>
							{t("AddTenant.password", {
								_: "Passwort"
							})}
						</FormLabel>
						<Input
							variant={"secondary"}
							size={"sm"}
							placeholder={t("InputPlaceholders.placeholderPassword", {
								_: "mind. 6 Zeichen"
							})}
							type="password"
							autoComplete="new-password"
							ref={passwordRef}
							required
							width={"250px"}
						/>
					</FormControl>

					<FormControl
						id="password-confirm"
						// display={"none"}
					>
						<FormLabel mb={0}>
							{t("AddTenant.confirmPassword", {
								_: "Passwort bestätigen"
							})}
						</FormLabel>
						<Input
							size={"sm"}
							variant={"secondary"}
							placeholder={t("InputPlaceholders.placeholderPasswordConfirm", {
								_: "Passwort wiederholen"
							})}
							type="password"
							autoComplete="new-password"
							ref={passwordConfirmRef}
							required
							width={"250px"}
						/>
					</FormControl>

					<Flex mt={{ base: 2, md: 2, lg: 0 }}>
						<Button
							type="submit"
							disabled={loading}
							height={"2rem"}
							width={"max-content"}
							px={4}
						>
							{t("AddTenant.updatePassword", {
								_: "Bestätigen"
							})}
						</Button>

						<DecoratorTriangleButtonResizable
							backgroundColor="btn-primary-hover-bg"
							borderColor="btn-primary-border"
							decoratorTriangleHeight={"2rem"}
							decoratorTriangleWidth={"2.2rem"}
						/>
					</Flex>
				</Flex>
			</form>
		</Box>
	)
}

export default UpdatePassword
