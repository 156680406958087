// client/src/components/Auth/firestoreHelpers/signUpTenantUser.js

import axiosAuth from "../../../api/axiosInstanceConfig/axiosAuth"

const signUpTenantUser = async (
	tenantId,
	email,
	password,
	displayName,
	role
) => {
	try {
		// console.log("tenantId", tenantId)
		const response = await axiosAuth.post("/api/signUpTenantUser", {
			tenantId,
			email,
			password,
			displayName,
			role
		})
		return response.data
	} catch (error) {
		console.error("Error signing up tenant user:", error)
		throw error
	}
}

export default signUpTenantUser
