import axios from "axios"

const axiosAuth = axios.create()
const MAX_RETRIES = 3
const RETRY_DELAY = 1000

const wait = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

axiosAuth.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem("authToken")
        let retries = 0

        const attemptRequest = async () => {
            try {
                if (token) {
                    const decodedToken = JSON.parse(atob(token.split(".")[1]))
                    const currentTime = Date.now() / 1000

                    if (decodedToken.exp < currentTime + 1800) {
                        const refreshToken = localStorage.getItem("refreshToken")
                        
                        if (!refreshToken) {
                            throw new Error("No refresh token available")
                        }

                        const response = await axios.post("/api/refreshToken", {
                            refreshToken
                        })

                        if (response.data.token) {
                            token = response.data.token
                            localStorage.setItem("authToken", token)
                        } else {
                            throw new Error("No token in refresh response")
                        }
                    }

                    config.headers["Authorization"] = `Bearer ${token}`
                }
                return config
            } catch (error) {
                if (retries < MAX_RETRIES && error.response?.status === 401) {
                    retries++
                    console.log(`Retry attempt ${retries} of ${MAX_RETRIES}`)
                    await wait(RETRY_DELAY * retries)
                    return attemptRequest()
                }
                
                console.error("All retry attempts failed:", error)
                localStorage.removeItem("authToken")
                localStorage.removeItem("refreshToken")
                window.location.href = "/login"
                return Promise.reject(error)
            }
        }

        return attemptRequest()
    },
    (error) => Promise.reject(error)
)

export default axiosAuth