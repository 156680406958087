// client/src/components/Auth/SignUp.jsx
import React, { useEffect, useRef, useState } from "react"
import {
	Link as ChakraLink,
	Button,
	Input,
	Box,
	FormControl,
	FormLabel,
	Flex
} from "@chakra-ui/react"
import signUpTenantAndAdmin from "../firestoreHelpers/signUpTenantAndAdmin"
import checkIfTenantIdAlreadyExists from "../firestoreHelpers/checkIfTenantIdAlreadyExists"
import DecoratorTriangleButtonResizable from "../../GUI/DecoratorTriangleButtonResizable.jsx"
import TenantList from "./TenantList/TenantList.jsx"
import { useTranslate } from "react-polyglot"
import { useAuth } from "../../../contexts/AuthContext.js"
import { toast } from "react-toastify"
import { useAtom } from "jotai"
import rerenderTriggerTenantListAtom from "../../../api/jotaiAtoms/rerenderTriggerTenantListAtom.js"
import rerenderTriggerTenantUserListAtom from "../../../api/jotaiAtoms/rerenderTriggerTenantUserListAtom.js"

const AddTenant = () => {
	const [rerenderTriggerTenantList, setRerenderTriggerTenantList] = useAtom(
		rerenderTriggerTenantListAtom
	)

	const [rerenderTriggerTenantUserList, setRerenderTriggerTenantUserList] =
		useAtom(rerenderTriggerTenantUserListAtom)

	useEffect(() => {}, [rerenderTriggerTenantList])

	const { signup, resetPassword } = useAuth()
	const t = useTranslate()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")

	const tenantIdRef = useRef()
	const tenantNameRef = useRef()
	const tenantAdminEmailRef = useRef()
	const tenantAdminNameRef = useRef()
	const passwordRef = useRef("000000")
	const passwordConfirmRef = useRef("000000")
	const [tenantId, setTenantId] = useState("")
	const [tenantAdminEmail, setTenantAdminEmail] = useState("")
	const [displayName, setDisplayName] = useState("")
	const [password, setPassword] = useState("000000")
	const [passwordConfirm, setPasswordConfirm] = useState("000000")

	const [tenantName, setTenantName] = useState("")
	//info: ROLE IS CONDITIONAL (if tenantName === EVIS then role = "evisAdmin")
	// const [role, setRole] = useState("tenantAdmin")
	useEffect(() => {
		// console.log("tenantId", tenantId)
	}, [tenantIdRef, tenantId])

	const determineInitialRole = () => {
		// return tenantName === "EVIS" ? "evisAdmin" : "tenantAdmin"
		//! tenantName SETS ROLE TO "evisAdmin" OR "evisMaster"
		return tenantName === "EVIS"
			? "evisAdmin"
			: tenantName === "EVIS MASTER"
				? "evisAdmin"
				: "tenantAdmin"
	}

	useEffect(() => {
		// console.log("determineInitialRole", determineInitialRole)
	}, [tenantId])

	const [role, setRole] = useState(determineInitialRole)

	//^ update role based on tenantName
	useEffect(() => {
		setRole(determineInitialRole)
	}, [tenantName])

	const handleCheckIfTenantIdAlreadyExists = async (tenantId) => {
		if ((await checkIfTenantIdAlreadyExists(tenantId)).exists === true) {
			toast.error(t("Toasts.toastTenantIdAlreadyExists"), {
				_: "Der Tenant-ID existiert bereits"
			})
			tenantIdRef.current.value = ""
			tenantIdRef.current.focus()
			return
		}
	}

	const handleSignUpTenant = async (e) => {
		e.preventDefault()
		try {
			await signUpTenantAndAdmin(
				tenantId,
				tenantName,
				tenantAdminEmail,
				displayName,
				role,
				password,
				passwordConfirm
			)
			//^ send a reset password email
			await resetPassword(tenantAdminEmail)
			toast.success(t("Toasts.toastAddTenantSuccess"), {
				_: "Distributoren"
			})
			//^ Trigger re-render of TenantUsersList
			setRerenderTriggerTenantList((prev) => {
				return !prev
			})
			//^ Trigger re-render of TenantUsersList
			setRerenderTriggerTenantUserList((prev) => {
				return !prev
			})
			//^ Reset form fields
			setTenantId("")
			setTenantName("")
			setTenantAdminEmail("")
			setDisplayName("")
			setPassword("000000")
			setPasswordConfirm("000000")
			//^ Reset input refs
			tenantIdRef.current.value = ""
			tenantNameRef.current.value = ""
			tenantAdminEmailRef.current.value = ""
			tenantAdminNameRef.current.value = ""
			passwordRef.current.value = "000000"
			passwordConfirmRef.current.value = "000000"
		} catch (error) {
			if (
				error.response.data.error ===
				"The email address is already in use by another account."
			) {
				toast.error(t("Toasts.toastAuthEmailAlreadyExists"), {
					_: "E-Mail Adresse bereits verwendet"
				})
			}
			console.error("Error signing up tenant and admin:", error)
		}
	}

	return (
		<>
			<Box
				alignContent={"center"}
				alignItems={"center"}
			>
				{/* //# List showing all existing users */}
				<TenantList maxW={{ base: "100%", md: "100%" }} />
				<form onSubmit={handleSignUpTenant}>
					<Flex
						maxW={{ base: "100%", md: "600px", lg: "900px", xl: "100%" }}
						flexDirection={{ base: "column", md: "row" }}
						flexWrap={{ base: "wrap", md: "wrap", lg: "wrap", xl: "nowrap" }}
						gap={5}
						alignItems={{ base: "start", lg: "end" }}
						rowGap={{ base: 3, md: 3 }}
					>
						<FormControl
							id="addTenantName"
							name="addTenantName"
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenant.tenantName", {
									_: "Distributor Name"
								})}
							</FormLabel>
							<Input
								onChange={(e) => setTenantName(e.target.value.toUpperCase())}
								placeholder={t("InputPlaceholders.placeholderTenantName", {
									_: "Musterfirma"
								})}
								variant={"secondary"}
								size={"sm"}
								type="text"
								textTransform={"uppercase"}
								autoComplete="off"
								ref={tenantNameRef}
								// required //TODO: ACTIVATE WHEN FUNCTIONAL
							/>
						</FormControl>

						<FormControl
							id="addTenantTenantId"
							name="addTenantTenantId"
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenant.tenantId", {
									_: "Distributor Id"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setTenantId(e.target.value)}
								placeholder={t("InputPlaceholders.placeholderTenantId", {
									_: "123456"
								})}
								onBlur={(e) =>
									handleCheckIfTenantIdAlreadyExists(e.target.value)
								}
								variant={"secondary"}
								type="number"
								autoComplete="off"
								ref={tenantIdRef}
								// required //TODO: ACTIVATE WHEN FUNCTIONAL
							/>
						</FormControl>

						<FormControl
							id="emailTenantAdmin"
							name="emailTenantAdmin"
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenant.email", { _: "Email Distributor Admin" })}
							</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setTenantAdminEmail(e.target.value)}
								placeholder={t(
									"InputPlaceholders.placeholderTenantAdminEmail",
									{
										_: "MaxMustermann@email.ch"
									}
								)}
								variant={"secondary"}
								type="email"
								ref={tenantAdminEmailRef}
								autoComplete="off"
								required
							/>
						</FormControl>

						<FormControl
							id="displayNameTenantAdmin"
							name="displayNameTenantAdmin"
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenant.tenantAdminName", {
									_: "Name"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setDisplayName(e.target.value)}
								placeholder={t(
									"InputPlaceholders.placeholderTenantAdminDisplayName",
									{
										_: "Max Mustermann"
									}
								)}
								variant={"secondary"}
								type="text"
								textTransform={"uppercase"}
								autoComplete="off"
								ref={tenantAdminNameRef}
								required
							/>
						</FormControl>

						<FormControl
							id="passwordTenantAdmin"
							name="passwordTenantAdmin"
							display={"none"}
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenant.password", {
									_: "Passwort"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setPassword(e.target.value)}
								variant={"secondary"}
								type="password"
								ref={passwordRef}
								required
								defaultValue={"000000"}
								autoComplete="off"
								isDisabled
							/>
						</FormControl>

						<FormControl
							id="passwordConfirmTenantAdmin"
							name="passwordConfirmTenantAdmin"
							display={"none"}
							width={"250px"}
						>
							<FormLabel mb={0}>
								{t("AddTenant.confirmPassword", {
									_: "Passwort bestätigen"
								})}
							</FormLabel>
							<Input
								size={"sm"}
								onChange={(e) => setPasswordConfirm(e.target.value)}
								variant={"secondary"}
								type="password"
								ref={passwordConfirmRef}
								required
								defaultValue={"000000"}
								autoComplete="off"
								isDisabled
							/>
						</FormControl>

						<Flex mt={{ base: 2, md: 2, lg: 2, xl: 0 }}>
							<Button
								type="submit"
								disabled={loading}
								height={"2rem"}
								width={"max-content"}
								px={4}
							>
								{t("AddTenant.addTenant", {
									_: "Hinzufügen"
								})}
							</Button>
							<DecoratorTriangleButtonResizable
								backgroundColor="btn-primary-hover-bg"
								borderColor="btn-primary-border"
								decoratorTriangleHeight={"2rem"}
								decoratorTriangleWidth={"2.2rem"}
							/>
						</Flex>
					</Flex>
				</form>
			</Box>
		</>
	)
}

export default AddTenant
