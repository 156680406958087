import React, { useEffect } from "react"
import { auth, emailAuthProvider, googleAuthProvider } from "../firebase.js"
import * as firebaseui from "firebaseui"
import { signInAnonymously as firebaseSignInAnonymously } from "firebase/auth"
import "firebaseui/dist/firebaseui.css"

const FirebaseAuthUI = () => {
	useEffect(() => {
		//# FirebaseUI config.
		const uiConfig = {
			signInFlow: "popup",
			signInOptions: [
				// emailAuthProvider.PROVIDER_ID,
				// googleAuthProvider.PROVIDER_ID,
				firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
			],
			callbacks: {
				signInSuccessWithAuthResult: () => {
					//^ Handle the sign-in result as needed
					return false // Don't automatically redirect.
				}
			}
		}

		//^ Initialize the FirebaseUI Widget using Firebase.
		const ui =
			firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)
		ui.start("#firebaseui-auth-container", uiConfig)

		return () => ui.reset() // Clean up when the component unmounts
	}, [])

	return <div id="firebaseui-auth-container"></div>
}

export const signInAnonymously = firebaseSignInAnonymously
export default FirebaseAuthUI
